import { ListUserGrantModel } from './listUserGrant.model';
import { TabProfileModel } from './tabProfile.model';

export class TabUserModel {
    idUser: number
    desPwd: string
    desEmail: string
    desName: string
    desSurname: string
    desRegister: string
    datCreation: Date
    flagExternal: boolean
    flagEnabled: boolean
    firstAccess: boolean
    listUserGrant: ListUserGrantModel[]
    listUserProfileObject: TabProfileModel[]
}