import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { TabUserModel } from 'src/app/models/tabUser.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ChangePasswordINModel } from 'src/app/models/changePasswordIN.model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { LoadFileINModel } from 'src/app/models/load-file-inmodel.model';
import { DmsService } from 'src/app/service/dms.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  user: TabUserModel = new TabUserModel();
  profileModalCloseResult = '';
  profileForm: FormGroup;
  changePWProfileForm: FormGroup;
  changePWModel: ChangePasswordINModel = new ChangePasswordINModel();

  spinner = false;
  msgs = false;
  message: string = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, private authService: AuthenticationService,
    private router: Router, private modalService: NgbModal,
    private dmsService: DmsService,
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('tabUser'))

    this.profileForm = new FormGroup({
      desEmail: new FormControl(null, [Validators.required, Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      desSurname: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(20),
      Validators.pattern('^[a-zA-Z ]*$')]),
      desName: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(20),
      Validators.pattern('^[a-zA-Z ]*$')]),
    })

    this.changePWProfileForm = new FormGroup({
      desEmail: new FormControl(null, [Validators.required, Validators.email,]),
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(20),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,20}$/)]),
      oldPassword: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(20)])
    })
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Download APK
   */

  downloadApkFile() {
    const date = new Date();
    const load: LoadFileINModel = new LoadFileINModel();
    load.filePath = '';

    this.dmsService.downloadApk(load).subscribe(data => {
        const contentType = 'application/octet-stream';
        const b64Data = data.resource;
        const byteCharacters = atob(b64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const a = document.createElement('a')
        a.href = URL.createObjectURL(new Blob([byteArray], { type: contentType }));
        a.download = 'apk_file_' + date + '.apk';
        // start download
        a.click();

      })
    }



  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('tabUser');

    if (!localStorage.getItem('token')) {
      this.router.navigate(['/auth/login']);
    }
  }

  openProfileModal(content) {
    this.modalService.open(content, {}).result.then((result) => {
      this.profileModalCloseResult = 'Modal closed' + result
      if (result === 'by: save button') {
      } else {
      }
    }).catch((res) => { });
  }

  openChangePwModal(content, selected) {
    this.changePWModel.desEmail = selected
    this.modalService.open(content, {}).result.then((result) => {
      this.profileModalCloseResult = 'Modal closed' + result
      if (result === 'by: edit button') {
        this.changePasswordUser(this.changePWModel)
        this.changePWProfileForm.reset()
      }
    }).catch((res) => { });
  }

  messageBox(operation, message) {
    if (operation === 'success') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation === 'error') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 2000
      })
    }
  }

  changePasswordUser(modelChange: ChangePasswordINModel) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    // console.log("MODEL CHANGE PW::", JSON.stringify(this.changePWModel))

    this.authService.changePassword(this.changePWModel).subscribe((res) => {
      this.spinner = false
      this.message = 'Modifica avvenuta correttamente'
      this.messageBox('success', this.message)
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }
}
