import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router"
import { ConfigService } from './../../../service/config.service';

import { ColumnMode } from '@swimlane/ngx-datatable';
import { TabClusterComuniModel } from '../../../models/tabClusterComuni.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DeleteClusterComuniINModel } from '../../../models/deleteClusterComuniIN.model';
import { InsertClusterComuniINModel } from '../../../models/insertClusterComuniIN.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ModifyClusterComuniINModel } from '../../../models/modifyClusterComuniIN.model';
import { DeleteClusterComuniModel } from '../../../models/deleteClusterComuni.model';
import Swal from 'sweetalert2';

export interface ClusterComuni {
  desClusterComuni: string
  idClusterComuni: number
}

@Component({
  selector: 'app-cluster-comuni',
  templateUrl: './cluster-comuni.component.html',
  styleUrls: ['./cluster-comuni.component.scss']
})
export class ClusterComuniComponent implements OnInit {

  clusterComuniModel: TabClusterComuniModel[] = [];
  clusterComuniModalCloseResult: string = '';
  clusterComuni: ClusterComuni = {
    desClusterComuni: undefined,
    idClusterComuni: undefined
  }

  clusterComuniEdit: TabClusterComuniModel = new TabClusterComuniModel();

  rows = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  spinner = false;
  msgs = false;
  message: string = null;

  checkEdit: boolean = true;

  clusterComuniForm: FormGroup;
  clusterComuniFormEdit: FormGroup;

  insertClusterComuniINModel = new InsertClusterComuniINModel();
  deleteClusterComuniINModel = new DeleteClusterComuniINModel();

  constructor(private router: Router, private configService: ConfigService, private modalService: NgbModal) {
    this.fetch(data => {
      this.rows = data;
    });
  }

  ngOnInit(): void {
    this.clusterComuniForm = new FormGroup({
      desClusterComuni: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
    })
    this.clusterComuniFormEdit = new FormGroup({
      desClusterComuni: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
    })
  }

  fetch(cb) {
    this.spinner = true
    this.msgs = false
    this.message = ''
    
    this.configService.loadListClusterComuni().subscribe((res) => {
      this.spinner = false
      this.clusterComuniModel = res.listTabClusterComuni;
      this.rows = res.listTabClusterComuni;
      console.log("res:: " + JSON.stringify(this.clusterComuniModel))
      this.loadingIndicator = false;
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  addClusterComuni() {
    this.spinner = true

    this.insertClusterComuniINModel.tabClusterComuni = this.clusterComuni

    this.configService.insertClusterComuni(this.insertClusterComuniINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Cluster Comune creato correttamente"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  deleteClusterComuni(modelDelete: TabClusterComuniModel, index: number) {
    this.spinner = true

    this.deleteClusterComuniINModel.idClusterComuni = modelDelete.idClusterComuni

    this.configService.deleteClusterComuni(this.deleteClusterComuniINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Cluster Comune eliminta con successo"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  editClusterComuni(indexRow, rowSelected) {
    this.spinner = true
    let modifyClusterComuniINModel: ModifyClusterComuniINModel = {
      tabClusterComuni: rowSelected
    };

    this.configService.modifyClusterComuni(modifyClusterComuniINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Modifica avvenuta correttamente"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.clusterComuniModel.filter(function (d) {
      return d.desClusterComuni.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    // this.table.offset = 0;
  }


  openAddClusterComuniModal(content) {
    this.modalService.open(content, {}).result.then((result) => {
      this.clusterComuniModalCloseResult = "Modal closed" + result
      if (result == "by: save button") {
        this.addClusterComuni()
        this.clusterComuniForm.reset();
      } else {
        this.clusterComuniForm.reset();
      }
    }).catch((res) => { });
  }

  openDeleteClusterComuniModal(content, rowSelected, indexRow) {
    this.modalService.open(content, {}).result.then((result) => {
      this.clusterComuniModalCloseResult = "Modal closed" + result
      if (result == "by: delete button") {
        this.deleteClusterComuni(rowSelected, indexRow);
      }
    }).catch((res) => { });
  }

  openEditClusterComuniModal(content, rowSelected, indexRow) {
    this.callBackground();
    this.clusterComuniEdit = rowSelected
    this.modalService.open(content, {}).result.then((result) => {
      this.clusterComuniModalCloseResult = "Modal closed" + result
      if (result == "by: edit button") {
       this.checkEdit = false
        this.editClusterComuni(indexRow, this.clusterComuniEdit)
      }
    }).catch((res) => { });
  }


    callBackground() { 
      this.checkEdit = true
    this.configService.loadListClusterComuni().subscribe((res) => {
     if (this.checkEdit) {
      this.clusterComuniModel = res.listTabClusterComuni;
      this.rows = res.listTabClusterComuni;
      }
    }, (err: HttpErrorResponse) => {
      this.messageBox('error', JSON.stringify(err))
    });
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
  }
}
