export class ViwProjectModel {
  codAclrr: string
  codLotto: number
  codiceArmadio: string
  codiceCentrale: string
  codiceIdProgetto: string
  codiceNetwork: string
  cognomeUtente: string
  cognomeUtenteLock: string
  dataCreazione: string
  dataFine: string
  dataInizio: string
  desAdc: string
  desArea: string
  desClusterComuni: string
  desLottoGara: string
  descrizione: string
  flagConvalidato: boolean
  flgDelete: boolean
  idAclrr: number
  idArea: number
  idCluster: number
  idClusterComuni: number
  idComune: string
  idLottoGara: number
  idProgetto: number
  idStatoProgetto: number
  idUser: number
  idUserLock: number
  istatComune: string
  mailUtente: string
  mailUtenteLock: string
  nomeComune: string
  nomeUtente: string
  nomeUtenteLock: string
  numFdv: string
  procom: string
  statoProgetto: string
  uuid: string
  valGeoPrj: string
  dataRevisione: string
  descAggiornameto: string
  descRevisone: string
}