import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangePasswordINModel } from 'src/app/models/changePasswordIN.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  spinner: boolean = false
  changePWProfileForm: FormGroup;
  changePWModel: ChangePasswordINModel = new ChangePasswordINModel();
  mapModalCloseResult: string = ''

  constructor(private router: ActivatedRoute,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private route:Router) {
  }

  ngOnInit(): void {
    this.changePWModel.desEmail = this.router.snapshot.paramMap.get('mail')
    this.changePWProfileForm = new FormGroup({
      desEmail: new FormControl(this.changePWModel.desEmail, [Validators.required, Validators.email,]),
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(20),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,20}$/)]),
      oldPassword: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(20)])
    })
  }

  modificaPassword() {
    this.spinner = true

    let changePasswordINModel = {
      desEmail: this.changePWModel.desEmail,
      newPassword: this.changePWModel.newPassword,
      oldPassword: this.changePWModel.oldPassword
    }
    this.authService.changePassword(changePasswordINModel).subscribe((res) => {
      this.spinner = false

      this.messageBox('success', "Modifica avvenuta correttamente")

      setTimeout(() => {
        this.route.navigate(['/auth/login'])
        this.spinner = false
      }, 1600)
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  openInfoPasswordModal(content) {
    this.modalService.open(content, {}).result.then((result) => {
      this.mapModalCloseResult = "Modal closed" + result
    }).catch((res) => { });
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: "Password inserita non valida",
        text: message,
        showConfirmButton: true,
      })
    }
  }
}