import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { UserService } from './../../../service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TabUserModel } from '../../../models/tabUser.model';
import { DeleteUserINModel } from '../../../models/deleteUserIN.model';
import { TabProfileModel } from '../../../models/tabProfile.model';
import { AuthenticationService } from '../../../service/authentication.service';
import { ViwUserProfileModel } from '../../../models/viwUserProfile.model';
import Swal from 'sweetalert2';
import { ChangePasswordUtenteIN } from '../../../models/change-password-utente-in.model';

export interface ListUserGrantModel {
  idUser: number
  desEmail: string
  codModule: string
  desModule: string
  userFunction: string[]
  uuid: string
}

export interface User {
  idUser: number
  desPwd: string
  desEmail: string
  desName: string
  desSurname: string
  desRegister: string
  datCreation: Date
  flagExternal: boolean
  flgEnabled: boolean
  listUserGrant: ListUserGrantModel[]
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: ViwUserProfileModel[] = [];
  static user: TabUserModel = new TabUserModel();
  userModalCloseResult: string = '';
  tabUser = {
    idUser: undefined,
    desPwd: undefined,
    desEmail: undefined,
    desName: undefined,
    desSurname: undefined,
    desRegister: undefined,
    datCreation: undefined,
    flagExternal: false,
    flagEnabled: true,
    firstAccess: false,
    listUserGrant: []
  }

  profili: TabProfileModel[] = [{ 'name': 'Amministratore', 'id': 1 }, { 'name': 'Project Manager', 'id': 2 }, { 'name': 'Progettista', 'id': 3 }, { 'name': 'Assistente', 'id': 4 }]

  rows = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  spinner = false;
  msgs = false;
  message: string = null;

  insertUserINModel = {
    listIdProfile: undefined,
    tabUser: {
      idUser: undefined,
      desPwd: undefined,
      desEmail: undefined,
      desName: undefined,
      desSurname: undefined,
      desRegister: undefined,
      datCreation: undefined,
      flagExternal: false,
      flagEnabled: true,
      firstAccess: false,
      listUserGrant: [],
      listUserProfileObject: []
    }
  }
  deleteUserINModel = new DeleteUserINModel()

  listUserGrant: ListUserGrantModel[] = []
  listIdProfile: TabProfileModel[] = []
  userForm: FormGroup;

  changePWForm: FormGroup;
  changePWModel: ChangePasswordUtenteIN = new ChangePasswordUtenteIN();

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService,
    private modalService: NgbModal, private authService: AuthenticationService) {

    this.fetch(data => {
      this.rows = data;
    });
  }

  ngOnInit(): void {
    this.userForm = new FormGroup({      
      desEmail: new FormControl(null, [Validators.required, Validators.email,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      desName: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(20),
      Validators.pattern("^[a-zA-Z ]*$")]),
      desSurname: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(20),
      Validators.pattern("^[a-zA-Z ]*$")]),
      flagExternal: new FormControl(null),
      listIdProfile: new FormControl(null, [Validators.required])
    })

    this.changePWForm = new FormGroup({
      desEmail: new FormControl(null)     
    })
  }

  fetch(cb) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.userService.loadViwUserProfile().subscribe((res) => {
      this.spinner = false
      this.users = res.listViwUserProfile;

      this.rows = this.users;
      this.loadingIndicator = false;
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });

    console.log(this.changePWModel)
  }

  addUser() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    console.log("LISTA PROFILI" + JSON.stringify(this.listIdProfile))
    var arrayIdTemp: number[] = []
    for (var idProfilo = 0; idProfilo < this.listIdProfile.length; idProfilo++) {
      console.log("" + this.listIdProfile[idProfilo].id)
      arrayIdTemp.push(this.listIdProfile[idProfilo].id);

    }
    this.insertUserINModel.listIdProfile = arrayIdTemp;
    this.insertUserINModel.tabUser.desEmail = this.tabUser.desEmail
    this.insertUserINModel.tabUser.desName = this.tabUser.desName
    this.insertUserINModel.tabUser.desPwd = ""
    this.insertUserINModel.tabUser.desRegister = this.tabUser.desRegister
    this.insertUserINModel.tabUser.desSurname = this.tabUser.desSurname
    this.insertUserINModel.tabUser.flagEnabled = this.tabUser.flagEnabled
    this.insertUserINModel.tabUser.firstAccess = this.tabUser.firstAccess

    if (this.tabUser.flagExternal == null) {
      this.tabUser.flagExternal = false;
    }
    this.insertUserINModel.tabUser.flagExternal = this.tabUser.flagExternal
    console.log("INSERT USER MODEL", this.insertUserINModel)

    this.userService.insertUser(this.insertUserINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Utente creato correttamente"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  deleteUser(modelDelete: DeleteUserINModel, index: number) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.deleteUserINModel.idUser = modelDelete.idUser

    this.userService.deleteUser(this.deleteUserINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Utente eliminato con successo"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.users.filter(function (d) {
      return d.desSurname.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
  }

  openAddUserModal(content) {
    this.modalService.open(content, {}).result.then((result) => {
      this.userModalCloseResult = "Modal closed" + result
      if (result == 'by: save button') {
        this.addUser();
        this.userForm.reset();
      } else {
        this.userForm.reset();
      }
    }).catch((res) => { });
  }

  openDeleteUserModal(content, indexRow, rowSelected) {
    this.modalService.open(content, {}).result.then((result) => {
      this.userModalCloseResult = "Modal closed" + result
      if (result == 'by: delete button') {
        this.deleteUser(rowSelected, indexRow);
      }
    }).catch((res) => { });
  }

  onSelectChange(event) {
    console.log("PROFILI", event)
  }

  eventCheck(event) {
    this.tabUser.flagExternal = event.target.checked
  }

  redirectUserDetail(user: TabUserModel) {
    UsersComponent.user = user
    localStorage.setItem("userSelected", JSON.stringify(user));
    this.router.navigate(['/users/user-detail'])
  }

  openChangePwUserModal(content, indexRow, rowSelected) {
    this.changePWModel.desEmail = rowSelected.desEmail
    this.modalService.open(content, {}).result.then((result) => {
      this.userModalCloseResult = "Modal closed" + result
      if (result == 'by: edit button') {
        this.changePasswordUser(rowSelected, indexRow);
        this.changePWForm.reset()
      }
    }).catch((res) => { });
  }

  changePasswordUser(modelChange: ChangePasswordUtenteIN, index: number) {
    this.spinner = true

    this.changePWModel.desEmail = modelChange.desEmail
    //this.changePWModel.newPassword = ""

    this.authService.changePasswordUtente(this.changePWModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.messageBox('success', "Modifica avvenuta correttamente")
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  lockUnlockUser(idUser, flag) {
    let lockUnlockUserIN = {
      idUser: idUser,
      flgLock: flag
    }
    this.userService.lockUnlockUser(lockUnlockUserIN).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      if (flag) this.message = "Utente sbloccato con successo"
      else if (!flag) this.message = "Utente bloccato con successo"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: "Errore",
        text: message,
        showConfirmButton: true,
      })
    }
  }
}
