import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router"
import { ConfigService } from './../../../service/config.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { TabLottiGaraModel } from 'src/app/models/tabLottiGara.model';
import { DeleteLottiGaraINModel } from 'src/app/models/deleteLottiGaraIN.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ViwAclrrDisponibiliListModel } from '../../../models/viwAclrrDisponibiliList.model';
import { ViwRegioniListModel } from '../../../models/viwRegioniList.model';
import { LoadProvincieINModel } from 'src/app/models/loadProvincieIN.model';
import { ViwProvinceList } from '../../../models/viwProvinceList.model';
import { LoadComuniINModel } from 'src/app/models/loadComuniIN.model';
import { ViwComuniListModel } from '../../../models/viwComuniList.model';
import { ViwLottiAclrrModel } from '../../../models/viwAclrrList.model';
import { ViwLottiComuniModel } from 'src/app/models/viwLottiComuni.model';
import { LoadCodLottoGaraINModel } from 'src/app/models/loadCodLottoGaraIN.model';
import { WizardComponent } from 'angular-archwizard';
import Swal from 'sweetalert2';

export interface LottiGara {
  codLotto: number
  desLottoGara: string
  idLottoGara: number
}

@Component({
  selector: 'app-lotti-gara',
  templateUrl: './lotti-gara.component.html',
  styleUrls: ['./lotti-gara.component.scss']
})
export class LottiGaraComponent implements OnInit {

  @ViewChild('wizard') public wizard: WizardComponent;

  lottiGaraModel: TabLottiGaraModel[] = [];
  lottiGaraModalCloseResult: string = '';
  lottiGara: LottiGara = {
    codLotto: undefined,
    desLottoGara: undefined,
    idLottoGara: undefined
  }
  lottiGaraEdit: TabLottiGaraModel = new TabLottiGaraModel();

  rows = [];
  rowsComuni = [];
  rowsComuniTMP = [];
  rowsAclrr = [];
  rowsAclrrTMP = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  spinner = false;
  msgs = false;
  msgsModal = false;
  message: string = null;

  lottiGaraForm: FormGroup;
  comuniForm: FormGroup;
  aclrrForm: FormGroup;
  lottiGaraFormEdit: FormGroup;
  comuniFormEdit: FormGroup;
  aclrrFormEdit: FormGroup;

  checkEdit: boolean = true;

  insertLottiGaraINModel = {
    listIdAclrr: undefined,
    listIdComuni: undefined,
    tabLottiGara: {
      codLotto: 0,
      desLottoGara: '',
      idLottoGara: undefined
    }
  }
  deleteLottiGaraINModel = new DeleteLottiGaraINModel();
  modifyLottiGaraINModel = {
    listIdAclrr: undefined,
    listIdComuni: undefined,
    tabLottiGara: {
      codLotto: 0,
      desLottoGara: '',
      idLottoGara: undefined
    }
  }

  viwAclrrDisponibiliList: ViwAclrrDisponibiliListModel[] = []
  viwRegioniList: ViwRegioniListModel[] = []
  loadProvinceINModel = new LoadProvincieINModel()
  viwProvinceList: ViwProvinceList[] = []
  loadComuniINModel = new LoadComuniINModel()
  viwComuniList: ViwComuniListModel[] = []
  viwAclrrListLotto: ViwLottiAclrrModel[] = []
  viwComuniListLotto: ViwLottiComuniModel[] = []

  selectIdAclrr: number
  selectIdRegione: string = ''
  selectIdProvincia: string = ''
  selectIdComune: string = ''

  regione: boolean = false
  provincia: boolean = false
  comune: boolean = false
  aclrr: boolean = false

  constructor(private router: Router, private configService: ConfigService, private modalService: NgbModal) {
    this.fetch(data => {
      this.rows = data;
    });
  }

  ngOnInit(): void {
    this.lottiGaraForm = new FormGroup({
      codLotto: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      desLottoGara: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    })
    this.comuniForm = new FormGroup({
      codiRegione: new FormControl(null),
      codiProvincia: new FormControl(null),
      codiIstat: new FormControl(null)
    })
    this.aclrrForm = new FormGroup({
      idAclrr: new FormControl(null),
    })
    this.lottiGaraFormEdit = new FormGroup({
      codLotto: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(10)]),
      desLottoGara: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)])
    })
    this.comuniFormEdit = new FormGroup({
      codiRegione: new FormControl(null),
      codiProvincia: new FormControl(null),
      codiIstat: new FormControl(null)
    })
    this.aclrrFormEdit = new FormGroup({
      idAclrr: new FormControl(null),
    })
    this.getAllData()
  }

  fetch(cb) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.configService.loadListLottiGara().subscribe((res) => {
      this.spinner = false
      this.lottiGaraModel = res.listTabLottiGara;
      this.rows = res.listTabLottiGara;
      console.log("res:: " + JSON.stringify(this.lottiGaraModel))
      this.loadingIndicator = false;

    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  getAllData() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.configService.loadViwAclrrDisponibili().subscribe((res) => {
      this.spinner = false
      this.viwAclrrDisponibiliList = res.viwAclrrDisponibiliList;

      console.log("ACLRR DISPONIBILI:: " + JSON.stringify(this.viwAclrrDisponibiliList))
      this.loadingIndicator = false;

    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });

    this.configService.loadViwRegioni().subscribe((res) => {
      this.spinner = false
      this.viwRegioniList = res.viwRegioniList;

      console.log("REGIONI:: " + JSON.stringify(this.viwRegioniList))
      this.loadingIndicator = false;

    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  onSelectRegion(action) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.regione = true;
    if (action == 'add') {
      this.comune = false;
      this.selectIdComune = null;
      this.selectIdProvincia = null;
      this.rowsComuni = [];
      this.rowsComuniTMP = [];
    } else if (action == 'edit') {
      this.selectIdComune = null;
      this.selectIdProvincia = null;
    }

    this.loadProvinceINModel.codiRegione = this.selectIdRegione
    this.configService.loadViwProvincie(this.loadProvinceINModel).subscribe((res) => {
      this.spinner = false
      this.viwProvinceList = res.viwProvinceList;

      console.log("PROVINCIE:: " + JSON.stringify(this.viwProvinceList))

    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  onSelectProvince(action) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.provincia = true
    if (action == 'add') {
      this.selectIdComune = null
      this.rowsComuni = []
      this.rowsComuniTMP = []
    } else if (action == 'edit') {
      this.selectIdComune = null
    }

    this.loadComuniINModel.codiProvincia = this.selectIdProvincia
    this.configService.loadViwComuni(this.loadComuniINModel).subscribe((res) => {
      this.spinner = false
      this.viwComuniList = res.viwComuniList;

      console.log("COMUNI:: " + JSON.stringify(this.viwComuniList))

    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  onSelectComune() {
    this.comune = true
    console.log("ROWS::", this.rowsComuni.length)
    if (this.rowsComuni.length != 0) {
      for (let i of this.rowsComuni) {
        if (i.codiIstat == this.selectIdComune)
          break
        else {
          for (let i of this.viwComuniList) {
            if (i.codiIstat == this.selectIdComune) {
              if (this.rowsComuni.indexOf(i) == -1) {
                this.rowsComuni.push(i)
                this.rowsComuniTMP = this.rowsComuni
                this.rowsComuni = [...this.rowsComuni]
                console.log("ROWS COMUNI:: " + JSON.stringify(this.rowsComuni))
              }
            }
          }
        }
      }
    } else {
      for (let i of this.viwComuniList) {
        if (i.codiIstat == this.selectIdComune) {
          if (this.rowsComuni.indexOf(i) == -1) {
            this.rowsComuni.push(i)
            this.rowsComuniTMP = this.rowsComuni
            this.rowsComuni = [...this.rowsComuni]
            console.log("ROWS COMUNI:: " + JSON.stringify(this.rowsComuni))
          }
        }
      }
    }

  }

  onSelectAclrr() {
    this.aclrr = true
    for (let i of this.viwAclrrDisponibiliList) {
      if (i.idAclrr == this.selectIdAclrr) {
        if (this.rowsAclrr.indexOf(i) == -1) {
          this.rowsAclrr.push(i)
          this.rowsAclrrTMP = this.rowsAclrr
          this.rowsAclrr = [...this.rowsAclrr]
          console.log("ROWS ACLRR:: " + JSON.stringify(this.rowsAclrr))
        }
      }
    }
  }

  verifyLottoExsist(event) {
    console.log(event)
    this.spinner = true
    this.msgsModal = false
    this.message = ''

    let loadCodLottoGaraIN: LoadCodLottoGaraINModel = {
      codLottoGara: this.lottiGara.codLotto
    }
    this.configService.loadLottoGaraByCode(loadCodLottoGaraIN).subscribe((res) => {
      this.spinner = false
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgsModal = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  addLottiGara() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.insertLottiGaraINModel.tabLottiGara.codLotto = this.lottiGara.codLotto
    this.insertLottiGaraINModel.tabLottiGara.desLottoGara = this.lottiGara.desLottoGara
    var arrayIdAclrr: number[] = []
    for (var i = 0; i < this.rowsAclrr.length; i++) {
      arrayIdAclrr.push(this.rowsAclrr[i].idAclrr);
    }
    this.insertLottiGaraINModel.listIdAclrr = arrayIdAclrr
    var arrayIdComuni: string[] = []
    for (var i = 0; i < this.rowsComuni.length; i++) {
      arrayIdComuni.push(this.rowsComuni[i].codiIstat);

    }
    this.insertLottiGaraINModel.listIdComuni = arrayIdComuni
    console.log("INSERT", this.insertLottiGaraINModel)

    this.configService.insertLottiGara(this.insertLottiGaraINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;

      this.fetch(data => {
        this.rows = data;
      });

      this.message = "Lotto Gara creato correttamente"
      this.messageBox('success', this.message)
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });

    this.selectIdComune = null
    this.selectIdAclrr = null
    this.selectIdProvincia = null
    this.selectIdRegione = null
    this.rowsAclrr = []
    this.rowsAclrrTMP = []
    this.rowsComuni = []
    this.rowsComuniTMP = []
  }

  deleteLottiGara(modelDelete: TabLottiGaraModel, index: number) {
    this.spinner = true

    this.deleteLottiGaraINModel.idLottiGara = modelDelete.idLottoGara;
    console.log("DELETE", this.deleteLottiGaraINModel)

    this.configService.deleteLottiGara(this.deleteLottiGaraINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;

      this.fetch(data => {
        this.rows = data;
      });

      this.message = "Lotto Gara eliminato con successo"
      this.messageBox('success', this.message)
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  editLottiGara(indexRow, rowSelected) {
    this.spinner = true

    this.modifyLottiGaraINModel.tabLottiGara = rowSelected
    var arrayIdAclrr: number[] = []
    for (var i = 0; i < this.rowsAclrr.length; i++) {
      arrayIdAclrr.push(this.rowsAclrr[i].idAclrr);
    }
    this.modifyLottiGaraINModel.listIdAclrr = arrayIdAclrr
    var arrayIdComuni: string[] = []
    for (var i = 0; i < this.rowsComuni.length; i++) {
      arrayIdComuni.push(this.rowsComuni[i].codiIstat);

    }
    this.modifyLottiGaraINModel.listIdComuni = arrayIdComuni
    console.log("MODIFY::", JSON.stringify(this.modifyLottiGaraINModel))

    this.configService.modifyLottiGara(this.modifyLottiGaraINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;

      this.fetch(data => {
        this.rows = data;
      });

      this.message = "Modifica avvenuta correttamente"
      this.messageBox('success', this.message)
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });

    this.selectIdComune = null
    this.selectIdAclrr = null
    this.selectIdProvincia = null
    this.selectIdRegione = null
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.lottiGaraModel.filter(function (d) {
      return d.desLottoGara.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    // this.table.offset = 0;
  }

  updateFilterComuni(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.rowsComuniTMP.filter(function (d) {
      return d.nomeComune.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rowsComuni = temp;
  }

  updateFilterAclrr(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.rowsAclrrTMP.filter(function (d) {
      return d.desAdc.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rowsAclrr = temp;
  }

  openAddLottiGaraModal(content) {
    this.rowsAclrr = [];
    this.rowsAclrrTMP = []
    this.rowsComuni = [];
    this.rowsComuniTMP = [];
    this.modalService.open(content, { size: 'xl' }).result.then((result) => {
      this.lottiGaraModalCloseResult = "Modal closed" + result
      if (result == "by: save button") {
        this.addLottiGara()
        this.lottiGaraForm.reset();
        this.selectIdComune = null
        this.selectIdAclrr = null
        this.selectIdProvincia = null
        this.selectIdRegione = null
        this.rowsAclrr = []
        this.rowsAclrrTMP = []
        this.rowsComuni = []
        this.rowsComuniTMP = []
        this.regione = false;
        this.provincia = false;
      } else {
        this.lottiGaraForm.reset();
        this.selectIdComune = null
        this.selectIdAclrr = null
        this.selectIdProvincia = null
        this.selectIdRegione = null
        this.rowsAclrr = []
        this.rowsAclrrTMP = []
        this.rowsComuni = []
        this.rowsComuniTMP = []
        this.regione = false;
        this.provincia = false;
      }
    }).catch((res) => { });
  }

  openDeleteLottiGaraModal(content, rowSelected, indexRow) {
    this.modalService.open(content, {}).result.then((result) => {
      this.lottiGaraModalCloseResult = "Modal closed" + result
      if (result == "by: delete button") {
        this.deleteLottiGara(rowSelected, indexRow);
      }
    }).catch((res) => { });
  }

  openEditLottiGaraModal(content, rowSelected, indexRow) {
    this.callBackground(rowSelected);

    this.lottiGaraEdit = rowSelected
    this.modalService.open(content, { size: 'xl' }).result.then((result) => {
      this.lottiGaraModalCloseResult = "Modal closed" + result
      if (result == "by: edit button") {
        this.checkEdit = false
        this.editLottiGara(indexRow, this.lottiGaraEdit)
        this.regione = false;
        this.provincia = false;
      } else {
        this.selectIdComune = null
        this.selectIdAclrr = null
        this.selectIdProvincia = null
        this.selectIdRegione = null
        this.regione = false;
        this.provincia = false;
      }
    }).catch((res) => { });
  }

  callBackground(rowSelected) {
    this.checkEdit = true
    this.configService.loadListLottiGara().subscribe((res) => {
      if (this.checkEdit) {
        this.lottiGaraModel = res.listTabLottiGara;
        this.rows = res.listTabLottiGara;
      }
    }, (err: HttpErrorResponse) => { 
      this.messageBox('error', JSON.stringify(err))
    });

    let loadAcrllLottoIN = {
      idLottoGara: rowSelected.idLottoGara
    }
    this.configService.loadAclrrByIdLottoGara(loadAcrllLottoIN).subscribe((res) => {
      if (this.checkEdit) {
        this.viwAclrrListLotto = res.viwAcrllList
        this.rowsAclrr = res.viwAcrllList;
        this.rowsAclrrTMP = res.viwAcrllList;
        this.aclrr = true
        console.log("ROWS ALRR::", JSON.stringify(this.rowsAclrr))
      }
    }, (err: HttpErrorResponse) => { 
      this.messageBox('error', JSON.stringify(err))
    });
    let loadComuniLottoIN = {
      idLottoGara: rowSelected.idLottoGara
    }
    this.configService.loadComuniByIdLottoGara(loadComuniLottoIN).subscribe((res) => {
      if (this.checkEdit) {
        this.viwComuniListLotto = res.viwComuniList
        this.rowsComuni = res.viwComuniList;
        this.rowsComuniTMP = res.viwComuniList;
        this.comune = true
      }
    }, (err: HttpErrorResponse) => { 
      this.messageBox('error', JSON.stringify(err))
    });

    this.getAllData();
  }

  public onSelectAllAclrr() {
    const selected = this.viwAclrrDisponibiliList.map(item => item.idAclrr);
    this.aclrrForm.get('idAclrr').patchValue(selected);
    this.rowsAclrr = this.viwAclrrDisponibiliList
    this.rowsAclrrTMP = this.viwAclrrDisponibiliList
    this.aclrr = true
  }

  public onClearAllAclrr() {
    this.aclrrForm.get('idAclrr').patchValue([]);
    this.rowsAclrr = []
    this.rowsAclrrTMP = []
    this.aclrr = false
  }

  public onSelectAllComuni() {
    const selected = this.viwComuniList.map(item => item.codiIstat);
    this.comuniForm.get('codiIstat').patchValue(selected);
    this.rowsComuni = this.viwComuniList
    this.rowsComuniTMP = this.viwComuniList
    this.comune = true
  }

  public onClearAllComuni() {
    this.comuniForm.get('codiIstat').patchValue([]);
    this.rowsComuni = []
    this.rowsComuniTMP = []
    this.comune = false
  }

  public onSelectAllAclrrEdit() {
    const selected = this.viwAclrrDisponibiliList.map(item => item.idAclrr);
    this.aclrrFormEdit.get('idAclrr').patchValue(selected);
    let union = [...new Set([...this.viwAclrrListLotto, ...this.viwAclrrDisponibiliList])];
    this.rowsAclrr = union
    this.rowsAclrrTMP = union
    this.aclrr = true
  }

  public onClearAllAclrrEdit() {
    this.aclrrFormEdit.get('idAclrr').patchValue([]);
    this.rowsAclrr = this.viwAclrrListLotto
    this.rowsAclrrTMP = this.viwAclrrListLotto
    this.aclrr = false
  }

  public onSelectAllComuniEdit() {
    const selected = this.viwComuniList.map(item => item.codiIstat);
    this.comuniFormEdit.get('codiIstat').patchValue(selected);
    let union = [...new Set([...this.viwComuniList, ...this.viwComuniListLotto])];
    this.rowsComuni = union
    this.rowsComuniTMP = union
    this.comune = true
  }

  public onClearAllComuniEdit() {
    this.comuniFormEdit.get('codiIstat').patchValue([]);
    this.rowsComuni = this.viwComuniListLotto
    this.rowsComuniTMP = this.viwComuniListLotto
    this.comune = false
  }

  removeRow(index, type) {
    if (type == 'comune') {
      this.rowsComuni.splice(index, 1);
      this.rowsComuniTMP = this.rowsComuni
      this.rowsComuni = [...this.rowsComuni]
      console.log("ROWS COMUNI:: " + JSON.stringify(this.rowsComuni))
    } else if (type == 'aclrr') {
      this.rowsAclrr.splice(index, 1);
      this.rowsAclrrTMP = this.rowsAclrr
      this.rowsAclrr = [...this.rowsAclrr]
      console.log("ROWS ACLRR:: " + JSON.stringify(this.rowsAclrr))
    }
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
  }
}