import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { Subtitle2Component } from './views/pages/subtitle2/subtitle2.component';
import { Subtitle3Component } from './views/pages/subtitle3/subtitle3.component';
import { Subtitle4Component } from './views/pages/subtitle4/subtitle4.component';
import { UserDetailComponent } from './views/pages/users/user-detail/user-detail.component';
import { UsersComponent } from './views/pages/users/users.component';
// Ngx-dropzone-wrapper
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbAccordionModule, NgbDropdownModule, NgbModule, NgbTooltipModule, NgbDatepickerModule, NgbNavModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { FeahterIconModule } from './core/feather-icon/feather-icon.module';
import { ArchwizardModule } from 'angular-archwizard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LottiGaraComponent } from './views/pages/lotti-gara/lotti-gara.component';
import { AreeComponent } from './views/pages/aree/aree.component';
import { AclrrComponent } from './views/pages/aclrr/aclrr.component';
import { ClusterComponent } from './views/pages/cluster/cluster.component';
import { ClusterComuniComponent } from './views/pages/cluster-comuni/cluster-comuni.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProjectComponent } from './views/pages/project/project.component';
import { ProjectDetailComponent } from './views/pages/project/project-detail/project-detail.component';
import { ProjectAddComponent } from './views/pages/project/project-add/project-add.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DropDownTreeModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MapComponent } from './views/pages/map/map.component';
import { DesignMapComponent } from './views/pages/design-map/design-map.component';
import { DialogModule } from '@ngneat/dialog';
import { NotificationsComponent } from './views/pages/notifications/notifications.component';
import { ReportComponent } from './views/pages/report/report.component';
import { ChangePasswordComponent } from './views/pages/change-password/change-password.component';
import { PasswordExpiredComponent } from './views/pages/password-expired/password-expired.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    Subtitle2Component,
    Subtitle3Component,
    Subtitle4Component,
    UserDetailComponent,
    UsersComponent,
    LottiGaraComponent,
    AreeComponent,
    AclrrComponent,
    ClusterComponent,
    ClusterComuniComponent,
    ProjectComponent,
    ProjectDetailComponent,
    ProjectAddComponent,
    MapComponent,
    DesignMapComponent,
    NotificationsComponent,
    ReportComponent,
    ChangePasswordComponent,
    PasswordExpiredComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DropDownListAllModule,
    CheckBoxAllModule,
    LayoutModule,
    ButtonModule,
    DropDownTreeModule,
    NgbModule,
    FeahterIconModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbTooltipModule,
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    DropzoneModule,
    HttpClientModule,
    NgxDatatableModule,
    NgSelectModule,
    NgbDatepickerModule,
    SweetAlert2Module.forRoot(),
    PerfectScrollbarModule,
    NgbNavModule,
    DialogModule.forRoot()
  ],
  providers: [DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    HttpClientModule,
    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
