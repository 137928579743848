import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

import { DeleteUserINModel } from '../models/deleteUserIN.model';

import { InsertUserINModel } from '../models/insertUserIN.model';
import { InsertUserOUTModel } from '../models/insertUserOUT.model';

import { LoadViwUserGrantINModel } from '../models/loadViwUserGrantIN.model';
import { LoadViwUserGrantOUTModel } from '../models/loadViwUserGrantOUT.model';
import { ModifyUserINModel } from '../models/modifyUserIN.model';
import { LoadListUsersModel } from '../models/loadListUsers.model';
import { LoadListUserINModel } from '../models/loadListUserIN.model';
import { LoadListViwUserGrantINModel } from '../models/loadListViwUserGrantIN.model';
import { LoadListModuleOUTModel } from '../models/loadListModuleOUT.model';
import { LoadListModuleINModel } from '../models/loadListModuleIN.model';
import { LoadListFunctionOUTModel } from '../models/loadListFunctionOUT.model';
import { LoadListFunctionINModel } from '../models/loadListFunctionIN.model';
import { ListViwUserProfileINModel } from '../models/listViwUserProfileIN.model';
import { ListViwUserProfileOUTModel } from '../models/listViwUserProfileOUT.model';
import { LoadViwUserProfileINModel } from '../models/loadViwUserProfileIN.model';
import { LoadViwUserProfileOUTModel } from '../models/loadViwUserProfileOUT.model';
import { LockUnlockUserModel } from '../models/lockUnlockUserIN.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService
  ) { }


  deleteUser(model: DeleteUserINModel): Observable<any> {
    return this.apiService.post('config/api/v1/deleteUser', model);
  }


  insertUser(model: InsertUserINModel): Observable<InsertUserOUTModel> {
    return this.apiService.post('config/api/v1/insertUser', model);
  }


  loadUserGrantById(model: LoadViwUserGrantINModel): Observable<LoadViwUserGrantOUTModel> {
    return this.apiService.post('config/api/v1/loadUserGrantById', model);
  }

  loadListUsers(): Observable<LoadListUsersModel> {
    let model: LoadListUserINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListUsers', body);
  }

  modifyUser(model: ModifyUserINModel): Observable<any> {
    return this.apiService.post('config/api/v1/modifyUser', model);
  }

  loadListViwUserGrant(): Observable<LoadViwUserGrantOUTModel> {
    let model: LoadListViwUserGrantINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListViwUserGrant', body);
  }

  loadListModule(): Observable<LoadListModuleOUTModel> {
    let model: LoadListModuleINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListModule', model);
  }

  loadListFunction(): Observable<LoadListFunctionOUTModel> {
    let model: LoadListFunctionINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListFunction', model);
  }

  loadViwUserProfile(): Observable<ListViwUserProfileOUTModel> {
    let model: ListViwUserProfileINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadViwUserProfile', model);
  }

  loadUserProfileById(model: LoadViwUserProfileINModel): Observable<LoadViwUserProfileOUTModel> {
    return this.apiService.post('config/api/v1/loadUserProfileById', model);
  }

  lockUnlockUser(model: LockUnlockUserModel): Observable<any> {
    return this.apiService.post('config/api/v1/lockUnlockUser', model);
  }
}