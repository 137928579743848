import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AuthenticationService } from '../../../service/authentication.service';
import Swal from 'sweetalert2';
import { SisInvioNotificheService } from '../../../service/sis-invio-notifiche.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteRelNotificheIN } from '../../../models/delete-rel-notifiche-in.model';
import { InsertNotificheIn } from '../../../models/insert-notifiche-in.model';
import { ViwUserProfileModel } from '../../../models/viwUserProfile.model';
import { LoadRelNotificheIN } from '../../../models/load-rel-notifiche-in.model';
import { TabUsersEnableIN } from '../../../models/tab-users-enable-in.model';
import { TabUser } from '../../../models/tab-user.model';
import { TabUserModel } from '../../../models/tabUser.model';
import { TabNotifiche } from '../../../models/tab-notifiche.model';
import { Notifica } from '../../../models/notifica.model';
import { UserNotifiche } from 'src/app/models/user-notifiche.model';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  //listMailAssoc = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  spinner = false;
  msgs = false;
  message: string = null;

  sendNotificationForm: FormGroup;
  notificationModalCloseResult: string = '';

  deleteNotificaModel = new DeleteRelNotificheIN()
  insertNotificaModel = new InsertNotificheIn()
  tabNotifiche = new TabNotifiche()

  users: ViwUserProfileModel[] = []
  listNotification: Notifica[] = []
  userNotifiche: UserNotifiche[] = []
  rows = []
  userList: TabUser[] = []


  constructor(private router: Router, private route: ActivatedRoute,
    private modalService: NgbModal, private authService: AuthenticationService,
    private notificheService: SisInvioNotificheService) {
  }

  ngOnInit(): void {
    this.sendNotificationForm = new FormGroup({
      listUserAssoc: new FormControl(null, [Validators.required]),
      oggetto: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(30)]),
      descrizione: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)])
    })

    this.loadNotifiche()
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
  }

  // onSelectUser(event) {
  //   console.log(event)
  //   console.log(this.insertNotificaModel.listIdUserA)
  //   // if (event != undefined) {
  //   //   this.spinner = true
  //   //   this.listIdUser = []
  //   //   //this.selectIdUser = null
  //   //   this.projectFormAss.controls.listIdUser.reset()

  //   //   let loadUserProjectIN = {
  //   //     idProfile: event.id,
  //   //     idProgetto: this.editProjectIN.idProgetto
  //   //   }

  //   //   this.projectService.loadUserForProject(loadUserProjectIN).subscribe((res) => {
  //   //     this.spinner = false
  //   //     this.listUserByPrj = res.listUserProject
  //   //     // this.loadUserProject(event.idUser)

  //   //   }, (err: HttpErrorResponse) => {
  //   //     this.spinner = false
  //   //     //this.msgs = true
  //   //     //this.message = JSON.stringify(err)
  //   //     this.messageBox('error', JSON.stringify(err))
  //   //   });
  //   // }
  // }


  openSendNotificationModal(content) {
    this.loadUsersEnable()
    this.modalService.open(content, {}).result.then((result) => {
      this.notificationModalCloseResult = "Modal closed" + result
      if (result == 'by: save button') {
        this.addNotifiche();
        this.resetSendNotificationForm();
      } else {
        this.resetSendNotificationForm();
      }
    }).catch((res) => { });

  }

  openListUserNotificationModal(content, indexRow, rowSelected) {
    this.userNotifiche = rowSelected.userNotifiche
    this.modalService.open(content, {}).result.then((result) => {
      this.notificationModalCloseResult = "Modal closed" + result

    }).catch((res) => { });

  }

  openDeleteNotificationModal(content, indexRow, rowSelected) {
    this.modalService.open(content, {}).result.then((result) => {
      this.notificationModalCloseResult = "Modal closed" + result
      if (result == 'by: delete button') {
        this.deleteNotifica(rowSelected.idNotifica);
      }
    }).catch((res) => { });
  }

  resetSendNotificationForm() {
    this.sendNotificationForm.reset()
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.listNotification.filter(function (d) {
      return d.notifica.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    // this.table.offset = 0;
  }

  addNotifiche() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    var idUser: number = 0;
    if (localStorage.getItem('tabUser') != null) {
      var tabUser: TabUserModel = JSON.parse(localStorage.getItem('tabUser'));
      idUser = tabUser.idUser;
    }

    this.tabNotifiche.idUser = idUser
    this.insertNotificaModel.tabNotifiche = this.tabNotifiche

    this.notificheService.insertNotifica(this.insertNotificaModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Notifiche inviate correttamente"
      this.messageBox('success', this.message)

      this.loadNotifiche()
    }, (err: HttpErrorResponse) => {
      console.log("error: " + JSON.stringify(err))
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  loadNotifiche() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    let loadNotificheModel = new LoadRelNotificheIN()

    this.notificheService.loadRelNotifiche(loadNotificheModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;

      this.listNotification = res.listUserNotifiche
      this.rows = this.listNotification
      // this.userNotifiche = res.listUserNotifiche.userNotifiche

    }, (err: HttpErrorResponse) => {
      console.log("error: " + JSON.stringify(err))
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  loadUsersEnable() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    let tabUsersEnableModel = new TabUsersEnableIN()

    this.notificheService.loadUsersEnable(tabUsersEnableModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;

      this.userList = res.userList

      for (let i = 0; i < this.userList.length; i++) {
        this.userList[i].username = this.userList[i].desSurname + " " + this.userList[i].desName
      }

    }, (err: HttpErrorResponse) => {
      console.log("error: " + JSON.stringify(err))
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  deleteNotifica(id: number) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.deleteNotificaModel.idTabNotifica = id
    this.notificheService.deleteRelNotifiche(this.deleteNotificaModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Notifica eliminata con successo"
      this.messageBox('success', this.message)

      this.loadNotifiche()
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }
}


