import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subtitle3',
  templateUrl: './subtitle3.component.html',
  styleUrls: ['./subtitle3.component.scss']
})
export class Subtitle3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.querySelector("#fileUploadInputExample") as HTMLElement;
    element.click()
  }

  handleFileInput(event: any) {
    if (event.target.files.length) {
      let element: HTMLElement = document.querySelector("#fileUploadInputExample + .input-group .file-upload-info") as HTMLElement;
      let fileName = event.target.files[0].name;
      element.setAttribute('value', fileName)
    }
  }

}
