import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

import { LoadListUserProjectOUTModel } from '../models/loadListUserProjectOUT.model';
import { LoadListUserProjectINModel } from '../models/loadListUserProjectIN.model';
import { LoadListProjectINModel } from '../models/loadListProjectIN.model';
import { LoadListProjectOUTModel } from '../models/loadListProjectOUT.model';
import { FilterListUserProjectINModel } from '../models/filterListUserProjectIN.model';
import { FilterListUserProjectOUTModel } from '../models/filterListUserProjectOUT.model';
import { FilterListProjectINModel } from '../models/filterListProjectIN.model';
import { FilterListProjectOUTModel } from '../models/filterListProjectOUT.model';
import { DeleteProjectINModel } from '../models/deleteProjectIN.model';
import { AssociateUserPrjIN } from '../models/associate-user-prj-in.model';
import { AssociateUserPrjOUT } from '../models/associate-user-prj-out.model';
import { LoadUserProjectIN } from '../models/load-user-project-in.model';
import { LoadUserProjectOUT } from '../models/load-user-project-out.model';
import { LoadUserProjIN } from '../models/load-user-proj-in.model';
import { LoadUserForProjectOUT } from '../models/load-user-for-project-out.model';
import { RelProgettoStatiIN } from '../models/rel-progetto-stati-in.model';
import { RelProgettoStatiOUT } from '../models/rel-progetto-stati-out.model';
import { ViwRelProgettoStatiIN } from '../models/viw-rel-progetto-stati-in.model';
import { ViwRelProgettoStatiOUT } from '../models/viw-rel-progetto-stati-out.model';
import { ViwStatiOUT } from '../models/viw-stati-out.model';
import { ModifyProgettoINModel } from '../models/modifyProgettoIN.model';
import { ModifyProgettoOUTModel } from '../models/modify-progetto-outmodel.model';
import { ExportExcelIN } from '../models/export-excel-in.model';
import { ExportExcelOUT } from '../models/export-excel-out.model';
import { StampaModelloRameIN } from '../models/stampa-modello-rame-in.model';
import { StampaModelloOtticaIN } from '../models/stampa-modello-ottica-in.mdel';
import { StampaModelloOtticaOUT } from '../models/stampa-modello-ottica-out.model';
import { StampaModelloRameOUT } from '../models/stampa-modello-rame-out.model';
import { StampaModelloPreventivoIN } from '../models/stampa-modello-preventivo-in.model';
import { StampaModelloPreventivoOUT } from '../models/stampa-modello-preventivo-out.model';
import { LoadPrjHomePageOUT } from '../models/load-prj-home-page-out.model';
import { ExportCatalogoINModel } from '../models/exportCatalogoIN.model';
import { GetStatusCatalogoINModel } from '../models/getStatusCatalogo.model';
import { LoadProjectByIdINModel } from '../models/loadProjectById.model';
import { GetStatusMisureINModel } from '../models/getStatusMisure.model';
import { CleanForConvalidaINModel } from '../models/cleanForConvalida.model';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private apiService: ApiService
  ) { }


  loadListUserProject(model: LoadListUserProjectINModel): Observable<LoadListUserProjectOUTModel> {
    return this.apiService.post('project/api/v1/loadListUserProject', model);
  }

  associateUserProject(model: AssociateUserPrjIN): Observable<AssociateUserPrjOUT> {
    return this.apiService.post('project/api/v1/associateUserProject', model);
  }

  loadUserForProject(model: LoadUserProjectIN): Observable<LoadUserForProjectOUT> {
    return this.apiService.post('project/api/v1/loadUserForProject', model);
  }

  loadUserProject(model: LoadUserProjIN): Observable<LoadUserProjectOUT> {
    return this.apiService.post('project/api/v1/loadUserProject', model);
  }

  filterListUserProject(model: FilterListUserProjectINModel): Observable<FilterListUserProjectOUTModel> {
    return this.apiService.post('project/api/v1/filterListUserProject', model);
  }

  loadListProject(model: LoadListProjectINModel): Observable<LoadListProjectOUTModel> {
    return this.apiService.post('project/api/v1/loadListProject', model);
  }

  filterListProject(model: FilterListProjectINModel): Observable<FilterListProjectOUTModel> {
    return this.apiService.post('project/api/v1/filterListProject', model);
  }

  deleteProject(model: DeleteProjectINModel): Observable<any> {
    return this.apiService.post('project/api/v1/deleteProgetto', model);
  }

  insertProgetto(model: any): Observable<any> {
    return this.apiService.post('project/api/v1/insertProgetto', model);
  }

  insertRelProgettoStati(model: RelProgettoStatiIN): Observable<RelProgettoStatiOUT> {
    return this.apiService.post('project/api/v1/insertRelProgettoStati', model);
  }

  loadViwProgettoStati(model: ViwRelProgettoStatiIN): Observable<ViwRelProgettoStatiOUT> {
    return this.apiService.post('project/api/v1/loadViwProgettoStati', model);
  }

  modifyProgetto(model: ModifyProgettoINModel): Observable<ModifyProgettoOUTModel> {
    return this.apiService.post('project/api/v1/modifyProgetto', model);
  }

  loadViwStati(): Observable<ViwStatiOUT> {
    return this.apiService.post('project/api/v1/loadViwStati');
  }

  loadProjectHomePage(): Observable<LoadPrjHomePageOUT> {
    return this.apiService.post('project/api/v1/loadProjectHomePage');
  }

  exportPrjExcel(model: ExportExcelIN): Observable<ExportExcelOUT> {
    return this.apiService.post('project/api/v1/exportPrjExcel', model);
  }

  // OUTPUT DI PROGETTAZIONE

  stampaModelloAIFO(model: StampaModelloOtticaIN): Observable<StampaModelloOtticaOUT> {
    return this.apiService.post('project/api/v1/stampaModelloAIFO', model);
  }

  stampaModelloRame(model: StampaModelloRameIN): Observable<StampaModelloRameOUT> {
    return this.apiService.post('project/api/v1/stampaModelloRame', model);
  }

  stampaModelloPreventivo(model: StampaModelloPreventivoIN): Observable<StampaModelloPreventivoOUT> {
    return this.apiService.post('project/api/v1/stampaModelloPreventivo', model);
  }

  exportCatalogo(model: ExportCatalogoINModel): Observable<any> {
    return this.apiService.post('project/api/layer/v1/exportCatalogo', model);
  }

  getStatusCatalogo(model:GetStatusCatalogoINModel): Observable<any> {
    return this.apiService.post('project/api/layer/v1/getStatusCatalogo', model);
  }

  loadProjectById(model:LoadProjectByIdINModel): Observable<any> {
    return this.apiService.post('project/api/v1/loadProjectById', model);
  }
  
  cleanForConvalida(model:CleanForConvalidaINModel): Observable<any> {
    return this.apiService.post('project/api/layer/v1/cleanForConvalida', model);
  }
}