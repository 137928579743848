import Swal from 'sweetalert2';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, Type, ViewChild, ɵɵtextInterpolate7 } from '@angular/core';
import { MapService } from 'src/app/service/map.service';
import { Collection, Feature, Map } from 'ol'
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GeoJSON } from 'ol/format'
import { ViwLayerModel } from 'src/app/models/viwLayer.model';
import { ViwProjectModel } from 'src/app/models/viwProject.model';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViwValoriAttributiModel } from 'src/app/models/viwValoriAttributi.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InsertLayerINModel } from 'src/app/models/insertLayerIN.model';
import { ViwProgettoSchedaLayAttModel } from 'src/app/models/viwProgettoSchedaLayAtt.model';
import { LoadAttributiLayerINModel } from 'src/app/models/loadAttributiLayerIN.model';
import { RelSchedaProgettoLayerModel } from 'src/app/models/relSchedaProgettoLayer.model';
import { ModifyFeatureSchedaPrjLayINModel } from 'src/app/models/modifyFeatureSchedaPrjLayIN.model';
import { DialogService } from '@ngneat/dialog';
import { MapStyleService } from 'src/app/service/mapStyle.service';
import { Draw, Modify, Select, Snap } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import Geometry from 'ol/geom/Geometry';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { GeoServerService } from 'src/app/service/geo-server.service';
import { LoadLayersIN } from 'src/app/models/load-layers-in.model';
import { RelTabAclrrGeoServer } from "src/app/models/rel-tab-aclrr-geo-server.model";
import { InsertGeometryParams, Geometry as GeometryGeoJson, GeometryParams, SelectGeometryParams, GeoJson, ModifyGeometryParams, RelModifyGeometryParams, Feature as FeatureGeoJson, Links, SplitParams, ConnectParams, EditLineParams, LineLinks, EditPointParams, ShortestPath, EditCavo, Cavo, LoadCavi } from 'src/app/models/webgis.model';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { features } from 'process';
import { environment } from 'src/environments/environment';
import TileLayer from 'ol/layer/Tile';
import { Coordinate } from 'ol/coordinate';
import { RelLayerNotaModel } from 'src/app/models/relLayerNotaModel.model';
import { ViwProgettoSchedaLayModel } from 'src/app/models/viwProgettoSchedaLay.model';
import { DmsService } from 'src/app/service/dms.service';
import { LoadFileINModel } from 'src/app/models/load-file-inmodel.model';
import { ViwRelLayerFolderModel } from 'src/app/models/viwRelLayerFolder.model';
import { RelProjectFolder } from 'src/app/models/rel-project-folder.model';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { insertClusterComuniModel } from 'src/app/models/insertClusterComuni.model';
import { EventRoot } from '@fullcalendar/core';
import { nullSafeIsEquivalent, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import LineString from 'ol/geom/LineString';
import { coordinateRelationship } from 'ol/extent';
import Point from 'ol/geom/Point';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { ErrorConvalidaOut } from 'src/app/models/errorConvalidaOut.model';
import { ProjectService } from 'src/app/service/project.service';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';

@Component({
  selector: 'app-design-map',
  templateUrl: './design-map.component.html',
  styleUrls: ['./design-map.component.scss']
})
export class DesignMapComponent implements OnInit, OnDestroy {
  @Input() project: ViwProjectModel
  @Input() valGeoLayer: any
  userLogin = JSON.parse(localStorage.getItem('tabUser'))
  configListLayer: ViwLayerModel[] = []
  configGeoServerListLayer: RelTabAclrrGeoServer[] = []

  spinner: boolean = false
  open_map: boolean = false
  open_map_text: string = 'col-xl-9'
  open_card_cavo: boolean = false
  open_modal_cavo: boolean = false
  modifyChooseCavo: boolean = false
  selectChooseCavo: boolean = false
  open_card_muffola: boolean = false
  open_card_ricchezza: boolean = false

  areaArmadioGeoJson: any
  mapModalCloseResult: string = ''
  modalTitleLayer: string = ''
  idLayerForStep: string = ''
  modalTextLineLayer: any

  selectedDate: NgbDateStruct
  dateModel: {
    year: number,
    month: number,
    day: number
  }

  listViwAttributi: ViwProgettoSchedaLayAttModel[] = []
  attributeLabelStyle: ViwProgettoSchedaLayAttModel[] = []
  listRelLayerNota: RelLayerNotaModel[] = []
  notaPoi = new ViwProgettoSchedaLayModel()
  listValoriAttributi: ViwValoriAttributiModel[] = []
  insertLayerINModel = new InsertLayerINModel()
  loadAttributiLayerIN = new LoadAttributiLayerINModel()
  listAttributiDettaglio: number[]
  relSchedaProgettoLayer = new RelSchedaProgettoLayerModel()
  modifyFeatureSchedaPrjLayIN = new ModifyFeatureSchedaPrjLayINModel()
  listFolderFile: ViwRelLayerFolderModel[] = []
  listPath: number[] = []
  pathWeight: number
  cavo = new Cavo()
  listCavi: Cavo[] = []
  listErrorConvalida: ErrorConvalidaOut[] = []
  cavoOperation: string;

  projectForm: FormGroup

  @ViewChild("addFeatureAttribute") private contentRef: TemplateRef<Object>
  @ViewChild("infoFeatureOperation") private contentInfo: TemplateRef<Object>
  @ViewChild("errorProjectValidation") private contentErr: TemplateRef<Object>

  @Output() updateFeatureLay = new EventEmitter<string>();

  // Mappa
  map: Map;
  areaArmadioLayer: VectorLayer<any>;
  layerTecnici: VectorLayer<any>[] = new Array();
  layerGeoServer: TileLayer<any>[] = new Array();
  layerServizi: VectorLayer<any>[] = new Array();
  layerAttivo: VectorLayer<any>;
  draw: Draw;
  modify: Modify;
  modifyCavo: Select;
  selectfeat: Select;
  selectfeatCavo: Select;
  selectfeatContenitore: Select;
  snap: Snap;

  array: any[] = []
  arrayObj: any[] = []
  count = 0

  // Layer lineare infrastrutture
  infrastruttureLayerName: string = "INFRASTRUTTURE";

  // Layer lineare associabile ad infrastrutture
  caviLayerName: string = "CAVO_OTTICO";
  muffolaLayerName: string = "MUFFOLA_GIUNZIONE"
  ricchezzalaLayerName: string = "RICCHEZZA_CAVO"
  roeName: string = "ROE_PTE"
  pozzettiName: string = "POZZETTI"

  // Layers che possono spezzare Infrastruttura
  splitLayerNames: string[] = ["ARMADIO OTTICO", "ROE_PTE", "POZZETTI"]

  // Layers Selezionabili come Source o target di un cavo
  puntiOtticiLayerNames: string[] = ["ARMADIO OTTICO", "MUFFOLA_GIUNZIONE", "ROE_PTE"]

  // Layers selezionabili come Punti intermedi di routing
  puntiIntermediLayerNames: string[] = ["ARMADIO OTTICO", "MUFFOLA_GIUNZIONE", "ROE_PTE", "POZZETTI", "CONNESSIONE"]

  // Layers associabili a contenitori
  puntiFigliLayerNames: string[] = ["MUFFOLA_GIUNZIONE", "RICCHEZZA_CAVO"]

  // Layers contenitori
  puntiContenitoriLayerNames: string[] = ["POZZETTI"]

  // Feature search
  featureSearch: string[] = []

  splitLayerIds: number[] = []
  virtualLayerIds: number[] = []
  connectionLayerIds: number[] = []
  serviceLayerIds: number[] = []

  idLayerInfrastrutture: number;
  idLayerCavi: number;
  idLayerPozzetti: number;
  idLayerRoe: number;
  idLayerMuffola: number;
  idLayerRicchezza: number;
  ngSelectValue: number = -1;
  childFeatureToConnect: Feature<SimpleGeometry>;
  graphicValidationSubscription: Subscription;
  convalidaSubscription: Subscription;

  constructor(private mapService: MapService,
    private projectService: ProjectService,
    private httpClient: HttpClient, private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef,
    private dialog: DialogService,
    private mapStyleService: MapStyleService,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar, private dmsService: DmsService,
    private geoServerService: GeoServerService) {
  }

  ngOnInit() {

    this.spinner = true
    this.map = this.mapService.initMap('design-map')

    this.mapService.removeDoubleClickInteracion();

    setTimeout(() => {
      this.map.updateSize()

      let loadAreaArmadioIN = {
        idProgetto: this.project.idProgetto
      }
      this.mapService.loadAreaArmadio(loadAreaArmadioIN).subscribe((data) => this.onLoadAreaArmadio(data))
      this.mapService.loadLayers().subscribe((data) => this.onLayersConfigLoad(data.listViwLayer))

      let loadLayersIN: LoadLayersIN = {
        idCentrale: this.project.idAclrr
      };
      this.geoServerService.loadLayers(loadLayersIN).subscribe((data) => this.onLayersGeoServerConfigLoad(data.listRelTabAclrrGeoServer))
      this.spinner = false
    }, 300)

    this.projectForm = new FormGroup({
      macroValore: new FormControl(null),
      attributo: new FormControl(null),
      subMacroValore: new FormControl(null),
      labelsStayle: new FormControl(null)
    })

    // Subscription
    this.graphicValidationSubscription = this.mapService.graphicValidationEvent.subscribe((data: any) => { this.pathGraphicValidation(data) })
    this.convalidaSubscription = this.mapService.convalidaEvent.subscribe((data: any) => { this.projectValidation(data) })
  }

  ngOnDestroy(): void {
    this.graphicValidationSubscription.unsubscribe()
    this.convalidaSubscription.unsubscribe()
  }

  onLoadAreaArmadio(area: GeoJson) {
    this.areaArmadioGeoJson = area
    localStorage.setItem('valGeoJson', this.areaArmadioGeoJson)
    this.areaArmadioLayer = this.mapService.addVectorLayerToMap(this.areaArmadioGeoJson, 'areaArmadio', this.mapStyleService.getStyleById('areaArmadio'))
    this.mapService.zoomToLayer(this.areaArmadioLayer)
    this.spinner = false
  }

  onLayersConfigLoad(data: ViwLayerModel[]) {
    this.configListLayer = data
    // carica layer tecnici
    // TODO da verificare se utilizzare chiamata unica per tutti i layer 
    this.configListLayer.map(layer => {
      // TODO gestire casi error
      if (layer.flagVirtual) {
        this.virtualLayerIds.push(layer.idLayer)
      }
      if (layer.flagDisallineamenti) {
        this.serviceLayerIds.push(layer.idLayer)
      }
      if (layer.flagConnessione) {
        this.connectionLayerIds.push(layer.idLayer)
      }
      if (this.splitLayerNames.indexOf(layer.desLayer) > -1) {
        this.splitLayerIds.push(layer.idLayer)
      }


      if (layer.desLayer == this.infrastruttureLayerName) this.idLayerInfrastrutture = layer.idLayer
      if (layer.desLayer == this.caviLayerName) this.idLayerCavi = layer.idLayer
      if (layer.desLayer == this.muffolaLayerName) this.idLayerMuffola = layer.idLayer
      if (layer.desLayer == this.ricchezzalaLayerName) this.idLayerRicchezza = layer.idLayer
      if (layer.desLayer == this.roeName) this.idLayerRoe = layer.idLayer
      if (layer.desLayer == this.pozzettiName) this.idLayerPozzetti = layer.idLayer

      let params = this.getGeomParams(undefined, "LOAD", layer.idLayer)
      this.mapService.getLayerById(params).subscribe((data) => { this.onLoadLayerTecnico(data, layer) })
    }
    )
  }

  onLayersGeoServerConfigLoad(data: RelTabAclrrGeoServer[]) {
    this.configGeoServerListLayer = data
    console.log("GEOSERVER::", this.configGeoServerListLayer)
    // carica layer tecnici
    // TODO da verificare se utilizzare chiamata unica per tutti i layer 
    this.configGeoServerListLayer.map(layer => {
      // TODO gestire casi error
      // TODO da richiamare a seguitodel recupero lista layers tile
      let lay = this.mapService.addTileLayerToMap(layer.dataStorage, environment.geoserver, layer.nomeLayer)
      this.mapService.setOpacityToLayer(lay, 0.5)
      this.layerGeoServer[layer.dataStorage] = lay
      // TODO da sostituire con visible da config 
      lay.setVisible(false) // layer.setVisible(layerConfig.visible)
    }
    )
  }

  onLoadLayerTecnico(geoJsonLayer, layerConfig: ViwLayerModel) {
    let layer = this.mapService.addVectorLayerToMap(geoJsonLayer, layerConfig.desLayer, this.mapStyleService.getLayersStyle(layerConfig, this.idLayerInfrastrutture))

    // TODO da sostituire con visible da config 
    layer.setVisible(true) // layer.setVisible(layerConfig.visible)

    // TODO da sostituire con valore da config 
    layer.setZIndex(this.configListLayer.indexOf(layerConfig))
    this.setDefaultPropertyToLayer(layer, layerConfig)

    if (layerConfig.flagVirtual || layerConfig.flagDisallineamenti || layerConfig.flagConnessione) {
      this.layerServizi[layerConfig.idLayer] = layer
    } else {
      this.layerTecnici[layerConfig.idLayer] = layer
    }

  }

  expand(open) {
    if (open == 'open_map') {
      this.open_map = true;
      this.open_map_text = 'col-xl-12'
      this.spinner = true
    }

    setTimeout(() => {
      this.map.updateSize()
      this.spinner = false
    }, 300)
  }

  collapse(open) {
    if (open == 'open_map') {
      this.open_map = false;
      this.open_map_text = 'col-xl-8'
    }

    setTimeout(() => {
      this.map.updateSize()
      this.spinner = false
    }, 300)
  }

  parseDataAttribute() {
    for (let i of this.listViwAttributi) {
      if (i.tipoAttributo == 'D') {
        var data = i.valoreAttributo
        var day, month
        if (data != null) {
          if (data.day < 10) {
            day = '0' + data.day
          } else {
            day = '' + data.day
          }
          if (data.month < 10) {
            month = '0' + data.month
          } else {
            month = '' + data.month
          }
          var date: string = data.year + '-' + month + '-' + day
          if (data.year != undefined)
            i.valoreAttributo = date
        }
      }
    }
  }

  parseValueNumberAttribute() {
    for (let i of this.listViwAttributi) {
      if (i.tipoAttributo == 'N' && i.valoreAttributo === "") {
        i.valoreAttributo = null
      }
    }
  }

  getModifyAttributeParams() {
    this.parseDataAttribute()
    this.parseValueNumberAttribute()
    const list = [...this.listViwAttributi, ...this.attributeLabelStyle]
    const modifyAttributeLayerIN = {
      listSchedaLayAtt: list
    }
    return modifyAttributeLayerIN
  }

  validateDataNumberInsert() {
    const reg = new RegExp("^[a-zA-Z ]*$")
    let validate = false
    for (let i of this.listViwAttributi) {
      if (i.tipoAttributo == 'N') {
        if (reg.test(i.valoreAttributo) && i.valoreAttributo != null) {
          validate = true
        }else if(i.valoreAttributo == null){
          validate = false
        }
      }
    }
    return validate
  }

  // TODO se possibile, gestire apertura con eventi
  openEditAttributeFeatureModal(content, feature?, cavo?) {
    this.dialog
      .open(content, { size: 'sm', enableClose: false, draggable: true })
      .afterClosed$.subscribe((confirmed) => {
        if (confirmed == 'by: save button') {
          if (this.validateDataNumberInsert()) {
            this.messageBox("error", "Valori inseriti non validi")
            return
          }
          else {
            this.spinner = true
            if (feature) {
              const modifyAttributeLayerIN = this.getModifyAttributeParams();

              this.mapService.modifyFeatureAttribute(modifyAttributeLayerIN).subscribe(
                res => { this.onModifyFeatureAttribute(feature, res) },
                err => this.onErrorResponse(err)
              );
            } else {
              const modifyAttributeLayerIN = this.getModifyAttributeParams();

              this.mapService.modifyFeatureAttribute(modifyAttributeLayerIN).subscribe(
                res => this.onModifyFeatureAttribute(null, res),
                err => this.onErrorResponse(err)
              );
            }
          }
        } else if (confirmed == 'by: delete button') {
          this.spinner = true

          if (feature) {
            let deleteLayerIN = {
              idProgetto: this.project.idProgetto,
              idSchedaProgetto: feature.get('uid'),
              idLayer: feature.get('idLayer')
            }

            this.mapService.deleteFeature(deleteLayerIN).subscribe(
              res => this.onDeleteFeature(res),
              err => this.onErrorResponse(err)
            );
          } else {
            let deleteLayerIN = {
              idProgetto: this.project.idProgetto,
              idSchedaProgetto: cavo.uid,
              idLayer: this.idLayerCavi
            }

            this.mapService.deleteFeature(deleteLayerIN).subscribe(
              res => this.onDeleteFeature(res),
              err => this.onErrorResponse(err)
            );
          }
        } else if (confirmed == 'by: edit button') {
          if (feature) {
            feature.set('toEdit', true)
            let configLayer = this.configListLayer.find(config => config.idLayer == feature.get('idLayer'))
            let collection = new Collection<Feature<SimpleGeometry>>()
            collection.push(feature)
            this.editFeatures(configLayer, null, collection)
          }
        } else {
          this.reloadEditLayers(true, true, true)
          if (feature) {
            if (feature.get('idLayer') != 9) {
              this.messageBox('warning', 'Sei uscito senza salvare - modifiche perse')
            }
          } else {
            this.messageBox('warning', 'Sei uscito senza salvare - modifiche perse')
          }
        }
        this.resetCardCavo()
      });
  }

  onModifyFeatureAttribute(feature, modifyResponse) {
    this.spinner = false
    this.reloadEditLayers(true, true, true)

    if (feature) {
      feature.set('icon', modifyResponse.viwProgettoSchedaLay.icon)
      feature.set('iconText', modifyResponse.viwProgettoSchedaLay.iconText)
      feature.setStyle(null)
    }

    if (modifyResponse.procedureReturnOUT.outReturn == 'KO') {
      this.messageBox('error', modifyResponse.procedureReturnOUT.outMessage)
    } else {
      this.messageBox('success', modifyResponse.procedureReturnOUT.outMessage)
    }
  }

  onDeleteFeature(res) {
    this.spinner = false
    this.reloadEditLayers(true, true, true)

    if (res.outReturn == 'KO')
      this.messageBox('error', res.outMessage)
    else
      this.messageBox('success', res.outMessage)
  }

  setListAttributiDettaglio(event, index) {
    if (event != null && event.valore == 'SI') {
      var attributo = this.listViwAttributi[index].listAttributiValori
      for (let i = 0; i < attributo.length; i++) {
        if (attributo[i] == event) {
          this.listViwAttributi[index].idValue = i
        }
      }
    } else {
      var attributo = this.listViwAttributi[index].listAttributiValori
      if (attributo.length) {
        for (let i = 0; i < attributo.length; i++) {
          if (attributo[i] == event) {
            this.listViwAttributi[index].idValue = null
          }
        }
        for (let i = 0; i < attributo.length; i++) {
          if (attributo[i].listAttributiDettaglio) {
            for (let item of attributo[i].listAttributiDettaglio) {
              item.valoreDiametro = null
              item.valoreNumeroTubi = null
              item.valoreOccupatiProp = null
              item.valoreOccupatiTerzi = null
            }
          }
        }
      }
    }
  }

  defaultValue(attributo, index) {
    if (attributo.flgDett) {
      var att = this.listViwAttributi[index].listAttributiValori

      for (let i = 0; i < att.length; i++) {
        if (att[i].idTabValori == attributo.idTabValori) {
          this.listViwAttributi[index].idValue = i
        }
      }
    }
    return null
  }

  openLegendUsingMapModal(content) {
    this.modalService.open(content, {}).result.then((result) => {
      this.mapModalCloseResult = "Modal closed" + result
    }).catch((res) => { });
  }

  //// #### Refactoring
  drawGeneric(layer: ViwLayerModel, i: number) {
    if (layer.idLayer == this.idLayerCavi) {
      this.drawCavo(layer)
    } else if (layer.idLayer == this.idLayerMuffola || layer.idLayer == this.idLayerRicchezza) {
      this.drawFeatureFiglio(layer, i)
    } else {
      this.drawFeature(layer, i)
    }
  }

  drawFeature(layer: ViwLayerModel, i: number) {
    this.layerAttivo = this.layerTecnici[layer.idLayer] ? this.layerTecnici[layer.idLayer] : this.layerServizi[layer.idLayer]
    this.removeAllInteractions();
    this.resetCardCavo()
    this.resetCardPozzetto()

    this.draw = this.mapService.addDrawInteractions(this.layerAttivo, this.layerAttivo.get('name') == 'INFRASTRUTTURE' ? 'LineString' : 'Point');
    this.snap = this.mapService.addSnapInteractionsToLayers(this.layerTecnici)

    this.draw.set('idLayer', layer.idLayer)
    this.draw.once('drawend', (event) => {
      this.draw = this.mapService.removeInteractions(this.draw);
      this.snap = this.mapService.removeInteractions(this.snap);
      this.onAddFeatureToLayerTecnico(event)
    });
  }

  editFeatures(layer: ViwLayerModel, i: number, features?: Collection<Feature<SimpleGeometry>>) {
    this.layerAttivo = this.layerTecnici[layer.idLayer] ? this.layerTecnici[layer.idLayer] : this.layerServizi[layer.idLayer]
    this.removeAllInteractions();
    this.resetCardCavo()
    this.resetCardPozzetto()

    this.modify = this.mapService.addModifyInteractions(this.layerAttivo, features);
    this.snap = this.mapService.addSnapInteractionsToLayers(this.layerTecnici)
    this.modify.once('modifyend', (event) => {
      this.modify = this.mapService.removeInteractions(this.modify);
      this.snap = this.mapService.removeInteractions(this.snap);
      this.onChangeFeatureToLayerTecnico(event)
    });

    this.modify.once('modifystart', (event) => {
      this.onChangeFeatureToLayerTecnicoStart(event)
    });

  }

  selectFeatures(layer: ViwLayerModel, i: number) {
    this.layerAttivo = this.layerTecnici[layer.idLayer] ? this.layerTecnici[layer.idLayer] : this.layerServizi[layer.idLayer]
    this.removeAllInteractions();
    this.resetCardCavo()
    this.resetCardPozzetto()

    this.selectfeat = this.mapService.addClickSelectInteractions(this.layerAttivo);
    this.snap = this.mapService.addSnapInteractionsToLayers([this.layerAttivo])
    this.mapService.addHighlightOnHover(this.layerAttivo, this.idLayerInfrastrutture)

    this.selectfeat.once('select', (event) => {
      this.selectfeat = this.mapService.removeInteractions(this.selectfeat);
      this.snap = this.mapService.removeInteractions(this.snap);
      this.mapService.removeHighlightOnHover(this.layerAttivo)
      this.onSelectFeatureToLayerTecnico(event)
    });

  }

  showHideLayer(layer: any[], key) {
    let showHideLayer = layer[key]
    showHideLayer?.setVisible(!showHideLayer.getVisible())
  }

  showHideAllLayer(layers, action) {
    let visible = !this.isButtonActive(action, null)
    if (action == 'SHOW-HIDE-ALL-GEOSERVER') {
      Object.keys(layers).map(dataStorage => {
        this.layerGeoServer[dataStorage].setVisible(visible)
      })
    } else {
      layers.map(lay => {
        lay.setVisible(visible)
      })
    }
  }

  onAddRow() {
    this.count++;
    this.arrayObj.push(this.count);
  }

  onRemoveRow() {
    this.count--;
    this.arrayObj.splice(this.count);
  }

  showHideGeoServerLayer(layer: RelTabAclrrGeoServer) {
    let showHideLayer = this.layerGeoServer[layer.dataStorage]
    showHideLayer?.setVisible(!showHideLayer.getVisible())
  }

  onAddFeatureToLayerTecnico(event) {
    let feature = event.feature;
    if (this.featureIsInAreaArmadio(feature)) {
      if (!this.validateFeatureDraw(feature)) {
        this.messageBox("error", "Posizione della feature non valida")
        this.reloadLayerAttivo()
        return
      }
      this.setDefaultPropertyToFeature(feature, this.layerAttivo);

      let params = undefined
      let insertType = undefined

      if (this.isInfrastruttura(feature)) {
        this.mapService.zoomToFeature(feature)
        this.map.once('rendercomplete', () => {
          params = this.getGeomParams(feature, "INSERT_INFRASTRUTTURA")
          insertType = "INSERT_INFRASTRUTTURA"
          this.editFeatureGeometry(feature, params, insertType);
        })
      } else if (this.isSplitLayer(feature) || this.isConnectionLayer(feature)) {
        params = this.getGeomParams(feature, "INSERT_SPLIT_POINT")
        insertType = "INSERT_SPLIT_POINT"
        this.editFeatureGeometry(feature, params, insertType);
      } else {
        params = this.getGeomParams(feature, "INSERT")
        insertType = "INSERT"
        this.editFeatureGeometry(feature, params, insertType);
      }
    } else {
      this.messageBox('error', 'Non è possibile disegnare fuori Area Armadio')
      this.reloadEditLayers(false, false)
    }
  }

  checkFeatureOverLap(event: any) {
    let coordinate: Coordinate = event.mapBrowserEvent.coordinate
    let ret = false;
    event.features?.getArray()?.map((featMod: Feature<any>) => {
      let linestring = featMod.get("OLD_GEOMETRY")
      if (linestring && this.mapService.intersectLine(linestring, coordinate)) {
        ret = true;
      }
    })
    return ret
  }

  onChangeFeatureToLayerTecnicoStart(event) {
    event.features?.getArray()?.map((featMod: Feature<any>) => {
      featMod.set("OLD_GEOMETRY", featMod.getGeometry().clone())
    })
  }

  onChangeFeatureToLayerTecnico(event) {

    let params = undefined
    let insertType = undefined

    if (this.layerAttivo.get('idLayer') == this.idLayerInfrastrutture && this.checkFeatureOverLap(event)) {
      this.messageBox('error', 'Non è possibile sovrapporre punti della stessa feature.')
      this.reloadEditLayers(false, false)
      return;
    } else if ((event.features && event.features.getLength() > 1) && !this.isInfrastruttura(event.features.item(0))) {
      this.messageBox('error', 'Non è possibile modificare più di una feature.')
      this.reloadEditLayers(false, false)
      return;
    } else if (event.features && event.features.getLength() > 1) {
      params = []
      event.features.forEach(feature => {
        params.push(this.getGeomParams(feature, "EDITMULTILINE_INFRASTRUTTURE"))
      })
      insertType = "EDITMULTILINE_INFRASTRUTTURE"
      this.editMultiFeatureGeometry(event.features, params, insertType)
      return
    }

    let feature = event.features.pop();

    if (!this.hasFeatureUid(feature)) return

    if (this.featureIsInAreaArmadio(feature)) {

      if (this.isInfrastruttura(feature)) {
        this.mapService.zoomToFeature(feature)
        this.map.once('rendercomplete', () => {
          params = this.getGeomParams(feature, "EDIT_INFRASTRUTTURA")
          insertType = "EDIT_INFRASTRUTTURA"
          this.editFeatureGeometry(feature, params, insertType);
        })
      } else if (this.isSplitLayer(feature) || this.isConnectionLayer(feature)) {
        params = this.getGeomParams(feature, "EDIT_SPLIT_POINT")
        insertType = "EDIT_SPLIT_POINT"
        this.editFeatureGeometry(feature, params, insertType);
      } else {
        params = this.getGeomParams(feature, "EDIT")
        insertType = "EDIT"
        this.editFeatureGeometry(feature, params, insertType);
      }

    } else {
      this.messageBox('error', 'Non è possibile disegnare fuori Area Armadio')
      this.reloadEditLayers(false, false)
    }
  }

  mustShowModalEditFeatureGeometry(feature: Feature<SimpleGeometry>, params: GeometryParams, operationType: string) {
    if (operationType == "INSERT_INFRASTRUTTURA" || operationType == "EDIT_INFRASTRUTTURA") {
      let lineParams: EditLineParams = params as EditLineParams
      if (lineParams?.source?.links?.length || lineParams?.target?.links?.length) {
        return true
      }
    } else if (operationType == "INSERT_SPLIT_POINT" || operationType == "EDIT_SPLIT_POINT") {
      let pointParams: EditPointParams = params as EditPointParams
      if (pointParams?.links?.length) {
        return true
      }
    }
    return false
  }

  editMultiFeatureGeometry(feature: Feature<SimpleGeometry>, params: GeometryParams[], operationType: string) {
    let errConnect = false
    this.spinner = true
    params.map(param => {
      let lineParams: EditLineParams = param as EditLineParams
      if (lineParams?.source?.links?.length || lineParams?.target?.links?.length) {
        errConnect = true
      }
    })
    if (errConnect) {
      this.messageBox("error", "Non è possobile spezzare con una multifeature")
      this.reloadEditLayers(true, true)
      this.spinner = false
      return
    }
    this.mapService.editFeatureGeometryService(operationType, null, params).subscribe(
      res => {
        this.spinner = false
        this.editFeatureGeometrySuccess(res, feature, operationType)
      },
      err => {
        this.spinner = false
        this.editFeatureGeometryError(err)
      }
    )
  }

  editFeatureGeometry(feature: Feature<SimpleGeometry>, params: GeometryParams, operationType: string) {
    if (this.otherSplitPointIsPresent(feature, operationType)) {
      this.messageBox('error', "Non è possibile sovrapporre due feature puntuali")
      this.reloadEditLayers(true, false)
      return;
    }
    const showmodalWarn = this.mustShowModalEditFeatureGeometry(feature, params, operationType)

    if (showmodalWarn) {
      if (operationType == "INSERT_INFRASTRUTTURA" || operationType == "EDIT_INFRASTRUTTURA") {
        let lineParams: EditLineParams = params as EditLineParams
        if (lineParams?.source?.links?.length || lineParams?.target?.links?.length) {
          this.openModalEditFeature(this.contentInfo, feature, params, operationType)
        }
      } else if (operationType == "INSERT_SPLIT_POINT" || operationType == "EDIT_SPLIT_POINT") {
        let pointParams: EditPointParams = params as EditPointParams
        if (pointParams?.links?.length) {
          this.openModalEditFeature(this.contentInfo, feature, params, operationType)
        }
      }
    } else {
      this.spinner = true
      this.mapService.editFeatureGeometryService(operationType, params).subscribe(
        res => {
          this.spinner = false
          this.editFeatureGeometrySuccess(res, feature, operationType)
        },
        err => {
          this.spinner = false
          this.editFeatureGeometryError(err)
        }
      )
    }
  }


  getModalMessageParams(params) {
    let messageParams = {
      links: undefined,
      source: undefined,
      target: undefined
    }

    let dictionaryLinks = [];

    params?.links?.map(link => {
      if (link.oldFeatureUid) dictionaryLinks[link.oldFeatureUid] = link
      else dictionaryLinks[link.feature.properties.uid] = link
    })

    params?.source?.links?.map(link => {
      if (link.oldFeatureUid) dictionaryLinks[link.oldFeatureUid] = link
      else dictionaryLinks[link.feature.properties.uid] = link
    })

    params?.target?.links?.map(link => {
      if (link.oldFeatureUid) dictionaryLinks[link.oldFeatureUid] = link
      else dictionaryLinks[link.feature.properties.uid] = link
    })

    dictionaryLinks.length ? messageParams.links = dictionaryLinks.filter(val => val != undefined) : undefined

    return messageParams
  }

  openModalEditFeature(content, feature: Feature<SimpleGeometry>, params: GeometryParams, operationType: string) {
    this.modalTextLineLayer = this.getModalMessageParams(params)
    this.dialog
      .open(content, { size: 'sm', enableClose: false, draggable: true })
      .afterClosed$.subscribe((confirmed) => {
        if (confirmed == 'by: modify button') {
          this.spinner = true
          this.mapService.editFeatureGeometryService(operationType, params).subscribe(
            res => {
              this.spinner = false
              this.editFeatureGeometrySuccess(res, feature, operationType)
            },
            err => {
              this.spinner = false
              this.editFeatureGeometryError(err)
            }
          )
        } else {
          this.reloadEditLayers(true, true, true)
          this.messageBox('warning', 'Sei uscito senza salvare - modifiche perse')
        }
      });
  }

  editFeatureGeometrySuccess(response, feature: Feature<SimpleGeometry>, operationType: string) {
    switch (operationType) {

      case "INSERT_INFRASTRUTTURA":
      case "INSERT_SPLIT_POINT":
      case "INSERT":
        feature.set('uid', response.uid);
        this.openModalEditAttribute(feature, null, response.listAttributiLayers, null, null, response.attributeLabelStyle);
        break;

      case "EDIT_SPLIT_POINT":
      case "EDIT_INFRASTRUTTURA":
      case "EDIT":
      case "EDITMULTILINE_INFRASTRUTTURE":
        this.messageBox('success', "Geometria aggiornata correttamente")
        this.reloadEditLayers(true, true)
        break
    }
  }

  editFeatureGeometryError(err) {
    this.reloadEditLayers(true, false)
    this.onErrorResponse(err)
  }


  onSelectFeatureToLayerTecnico(event) {
    this.spinner = true
    // TODO gestire geometri multiple(edit con due feature diverse che si congiungono in un punto)
    let feature = event.selected.pop();

    if (!this.hasFeatureUid(feature)) return

    this.setDefaultPropertyToFeature(feature, this.layerAttivo);
    let params = this.getGeomParams(feature, "SELECT")
    this.mapService.getAttributiFeature(params).subscribe((res) => {
      this.spinner = false
      this.openModalEditAttribute(feature, null, res.listAttributiLayers, res.listRelLayerNota, res.notaPoi, res.attributeLabelStyle);
    }, err => this.onErrorResponse(err)
    )
  }

  getFeatureParams(feature: Feature<SimpleGeometry>): RelModifyGeometryParams {
    let params: RelModifyGeometryParams = new RelModifyGeometryParams();
    let geometry = new GeometryGeoJson()
    geometry.type = feature.getGeometry()?.getType()
    geometry.coordinates = feature.getGeometry()?.getCoordinates()
    params.geometry = geometry
    params.idProgetto = this.project.idProgetto
    params.idLayer = this.layerAttivo.get('idLayer')
    params.idSchedaProgetto = feature.get('uid') ? feature.get('uid') : undefined
    params.length = geometry.type == 'LineString' ? this.mapService.formatLength(feature) : null
    params.latitude = geometry.type == 'Point' ? this.mapService.formatCoodinate(feature)[1] : null
    params.longitude = geometry.type == 'Point' ? this.mapService.formatCoodinate(feature)[0] : null
    return params;
  }

  getConnectParams(feature: Feature<SimpleGeometry>, coordinate: Coordinate): LineLinks {
    let params: LineLinks = new LineLinks()
    params.uid = null
    params.links = null

    // Coordiante del punto
    params.coordinates = coordinate

    // Interseca punto ottico o punto di connessione
    let pointFeatures = this.mapService.getFeatureAtPoint(coordinate, 'Point')
    pointFeatures.map(feat => {
      if ((this.isSplitLayer(feat) || this.isConnectionLayer(feat)) && feat.get('uid') != feature.get('uid')) {
        params.uid = feat.get('uid');
        return params;
      }
    })

    // Interseca Linea (se non c'è punto ottico)
    if (!params.uid)
      params.links = this.getSplitParams(feature, coordinate)

    return params;
  }

  getSplitParams(feature: Feature<SimpleGeometry>, coordinate?: Coordinate): Links[] {
    let featCoord: Coordinate = coordinate ? coordinate : feature.getGeometry().getCoordinates()
    let features: Feature<SimpleGeometry>[] = this.mapService.getFeatureAtPoint(featCoord, 'LineString')

    let links: Links[] = []

    // Non utilizzare le nuove feature disegnate
    features = features.filter(feat => feat.get('uid') != null && feature.get('uid') != feat.get('uid'))

    features.forEach(
      (feat: Feature<SimpleGeometry>) => {

        feat.set('toSplit', true)

        let isSource = this.mapService.intersectCoordinate(featCoord, feat.getGeometry().getFirstCoordinate())
        let isTarget = this.mapService.intersectCoordinate(featCoord, feat.getGeometry().getLastCoordinate())

        if (isSource) {
          console.log("is Source")
          links.push({ type: "SOURCE", feature: this.mapOLFeatureToGeoJsonFeature(feat, true, ['uid']), oldFeatureUid: undefined, length: undefined })
        } else if (isTarget) {
          console.log("is target")
          links.push({ type: "TARGET", feature: this.mapOLFeatureToGeoJsonFeature(feat, true, ['uid']), oldFeatureUid: undefined, length: undefined })
        } else {
          console.log("is split")
          let spezzata = this.mapService.splitGeomAtPoint(feat.getGeometry(), featCoord, 3)

          if (spezzata[0]) {
            let spezzataTarget: Feature<SimpleGeometry> = new Feature({ geometry: spezzata[0] })
            links.push({ type: "TARGET", feature: this.mapOLFeatureToGeoJsonFeature(spezzataTarget, false), oldFeatureUid: feat.get('uid'), length: this.mapService.formatLength(spezzataTarget) })
          }

          if (spezzata[1]) {
            let spezzataSource: Feature<SimpleGeometry> = new Feature({ geometry: spezzata[1] })
            links.push({ type: "SOURCE", feature: this.mapOLFeatureToGeoJsonFeature(spezzataSource, false), oldFeatureUid: feat.get('uid'), length: this.mapService.formatLength(spezzataSource) })
          }
        }
      })
    return links
  }


  getGeomParams(feature: Feature<SimpleGeometry>, operation: string, idLayer?: number): GeometryParams {

    switch (operation) {

      case "INSERT_INFRASTRUTTURA": {
        let params: EditLineParams = new EditLineParams()
        params.feature = this.getFeatureParams(feature)
        params.source = this.getConnectParams(feature, feature.getGeometry().getFirstCoordinate())
        params.target = this.getConnectParams(feature, feature.getGeometry().getLastCoordinate())
        return params;
      }

      case "INSERT_SPLIT_POINT": {
        let params: EditPointParams = new EditPointParams()
        params.feature = this.getFeatureParams(feature)
        params.links = this.getSplitParams(feature)
        return params;
      }

      case "INSERT": {
        let params: InsertGeometryParams = new InsertGeometryParams()
        params.feature = this.getFeatureParams(feature)
        params.parentUid = feature.get('parentUid') ? feature.get('parentUid') : undefined
        return params;
      }

      case "EDIT_INFRASTRUTTURA": {
        let params: EditLineParams = new EditLineParams()
        params.feature = this.getFeatureParams(feature)
        let s = this.getConnectParams(feature, feature.getGeometry().getFirstCoordinate())
        params.source = (s && s.uid && s.uid == feature.get('source')) ? null : s
        let t = this.getConnectParams(feature, feature.getGeometry().getLastCoordinate())
        params.target = (t && t.uid && t.uid == feature.get('target')) ? null : t
        return params;
      }

      case "EDITMULTILINE_INFRASTRUTTURE": {
        let params: EditLineParams = new EditLineParams()
        params.feature = this.getFeatureParams(feature)
        let s = this.getConnectParams(feature, feature.getGeometry().getFirstCoordinate())
        params.source = (s && s.uid && s.uid == feature.get('source')) ? null : s
        let t = this.getConnectParams(feature, feature.getGeometry().getLastCoordinate())
        params.target = (t && t.uid && t.uid == feature.get('target')) ? null : t
        return params;
      }

      case "EDIT_SPLIT_POINT": {
        let params: EditPointParams = new EditPointParams()
        params.feature = this.getFeatureParams(feature)
        params.links = this.getSplitParams(feature)
        return params;
      }

      case "EDIT": {
        let params: ModifyGeometryParams = new ModifyGeometryParams()
        params.feature = this.getFeatureParams(feature)
        return params;
      }

      case "LOAD": {
        let params: GeometryParams = new GeometryParams();
        params.idProgetto = this.project.idProgetto;
        params.idLayer = idLayer
        return params;
      }

      case "SELECT": {
        let params: SelectGeometryParams = new SelectGeometryParams();
        params.idSchedaProgetto = feature.get('uid');
        params.idProgetto = this.project.idProgetto;
        params.parentUid = feature.get('parentUid') ? feature.get('parentUid') : undefined
        return params;
      }

      case "LOAD_CAVI": {
        let params = new LoadCavi()
        params.uid = feature.get('uid')
        params.idProgetto = this.project.idProgetto
        return params;
      }

    }

    return null
  }

  featureIsInAreaArmadio(feature) {
    if (feature.getGeometry().getType() == 'Point') {
      return this.areaArmadioLayer.getSource().getFeaturesAtCoordinate(feature.getGeometry().getCoordinates()).length > 0
    } else if (feature.getGeometry().getType() == 'LineString') {
      let isInArea = true
      let coordinates = feature.getGeometry().getCoordinates()
      coordinates.map(cord => {
        if (this.areaArmadioLayer.getSource()?.getFeaturesAtCoordinate(cord)?.length < 1) {
          isInArea = false
        }
      })
      return isInArea
    }
    return false
  }

  validateFeatureDraw(feature) {
    // Se il nodo virtuale viene disegnato fuori da una infreastruttura ritorna false
    if (this.connectionLayerIds.indexOf(this.layerAttivo.get('idLayer')) > -1) {
      return this.mapService.getFeatureAtPoint(feature.getGeometry().getCoordinates(), 'LineString', [this.infrastruttureLayerName]).length > 0
    }
    return true
  }

  openModalEditAttribute(feature, cavo, attributeList, listRelLayerNota?, notaPoi?, attributeLabelStyle?) {
    let uid: number
    if (cavo) {
      this.modalTitleLayer = 'CAVO OTTICO'
      this.idLayerForStep = this.idLayerCavi.toString()
      uid = cavo.uid
    } else if (feature) {
      this.modalTitleLayer = feature.get('title')
      this.idLayerForStep = feature.get('idLayer')
      uid = feature.get('uid')
    }

    for (let item of attributeList) {
      // TODO Verificare utilità codice projectForm (cosa fa?)
      this.projectForm.addControl('' + item.idRelSchedaProgettoLay, new FormControl(null))
      if (item.flgDett) {
        this.projectForm.addControl('' + item.idRelSchedaProgettoLay + '1', new FormControl(null))
        this.projectForm.addControl('' + item.idRelSchedaProgettoLay + '2', new FormControl(null))
        this.projectForm.addControl('' + item.idRelSchedaProgettoLay + '3', new FormControl(null))
        this.projectForm.addControl('' + item.idRelSchedaProgettoLay + '4', new FormControl(null))

        var attributo = item.listAttributiValori
        for (let i = 0; i < attributo.length; i++) {
          if (attributo[i].valore == item.valoreAttributo) {
            item.idValue = i
          }
        }
      }
      if (item.desAttributo == 'DATA_INS' || item.desAttributo == 'DATA_STATO') {
        if (item.valoreAttributo != undefined) {
          var splitted = item.valoreAttributo.split("-", 3);
          this.dateModel = {
            year: splitted[0],
            month: splitted[1],
            day: splitted[2]
          }
          let ngbDate = this.validateInput(null, item.valoreAttributo)
          item.valoreAttributo = ngbDate
        }
      }
      if (item.desAttributo == 'LUNGH_M' && feature.getGeometry().getType() == 'LineString') {
        item.valoreAttributo = this.mapService.formatLength(feature)
      } else if ((item.desAttributo == 'LAT_Y' || item.desAttributo == 'LONG_X') && feature.getGeometry().getType() == 'Point') {
        var coordinate = this.mapService.formatCoodinate(feature)
        item.valoreAttributo = item.desAttributo == 'LAT_Y' ? coordinate[1] : coordinate[0]
      } else if (item.desAttributo == 'LUNGH_CAVO') {
        item.valoreAttributo = cavo.weight != null ? cavo.weight.toString() : item.valoreAttributo
      }
    }
    this.listViwAttributi = attributeList

    if (attributeLabelStyle) {
      for (let item of attributeLabelStyle) {
        this.projectForm.addControl('' + item.idRelSchedaProgettoLay, new FormControl(null))

        if (feature) {
          if (item.desAttributo == "CONFROTATION" && this.isInfrastruttura(feature) && item.valoreAttributo == null) {
            item.valoreAttributo = this.labelRotationInfrastructure(feature)
          }
        }
      }
      this.attributeLabelStyle = attributeLabelStyle
    }

    this.listRelLayerNota = listRelLayerNota
    this.notaPoi = notaPoi
    this.loadListFile(uid)
    this.openEditAttributeFeatureModal(this.contentRef, feature, cavo)
  }

  labelRotationInfrastructure(feature) {
    let middlePoint: Coordinate = feature.getGeometry().getCoordinateAt(0.5)
    var segment: LineString = null;

    feature.getGeometry().forEachSegment((coordStart, coordLast) => {
      var lineString = new LineString([coordStart, coordLast])

      if (this.mapService.intersectLine(lineString, middlePoint)) {
        segment = lineString
        return true
      }
    })
    let last = segment.getLastCoordinate()
    let first = segment.getFirstCoordinate()
    let rotationAtan = -Math.atan((last[1] - first[1]) / (last[0] - first[0]));
    return Math.trunc(rotationAtan * (180 / Math.PI));
  }

  reloadRotationInfrastructure(layer) {
    let features = this.layerAttivo.getSource().getFeatures()
    let feature = features.find(feat => feat.get('uid') == layer.idSchedaProgetto)
    layer.valoreAttributo = this.labelRotationInfrastructure(feature)
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  setDefaultPropertyToLayer(layer, config) {
    layer.set('idLayer', config.idLayer)
    layer.set('icon', config.icon)
    layer.set('title', config.desLayer)
  }

  setDefaultPropertyToFeature(feature, layer) {
    feature.set('idLayer', layer.get('idLayer'))
    feature.set('icon', layer.get('icon'))
    feature.set('title', layer.get('title'))
    feature.set('flgConvalidato', layer.get('flgConvalidato'))
  }

  onErrorResponse(err: HttpErrorResponse) {
    this.reloadEditLayers(true, true, true)
    this.spinner = false
    this.messageBox('error', JSON.stringify(err))
  }

  isButtonActive(action: string, layer: ViwLayerModel, tileLayer?: RelTabAclrrGeoServer) {
    switch (action) {
      case "DRAW": return this.draw && this.draw?.getActive() && layer.idLayer === this.layerAttivo?.get('idLayer')
      case "EDIT": return this.modify && this.modify?.getActive() && layer.idLayer === this.layerAttivo?.get('idLayer')
      case "SELECT": return this.selectfeat && this.selectfeat?.getActive() && layer.idLayer === this.layerAttivo?.get('idLayer')
      case "SHOW-HIDE-TECNICI": return this.layerTecnici[layer.idLayer]?.getVisible()
      case "SHOW-HIDE-GEOSERVER": return this.layerGeoServer[tileLayer.dataStorage]?.getVisible()
      case "SHOW-HIDE-SERVIZI": return this.layerServizi[layer.idLayer]?.getVisible()
      case "SHOW-HIDE-ALL-TECNICI": return this.layerTecnici.length && this.layerTecnici.find(lay => lay?.getVisible() == true) != undefined
      case "SHOW-HIDE-ALL-GEOSERVER": return Object.keys(this.layerGeoServer).length && Object.keys(this.layerGeoServer).find(dataStorage => this.layerGeoServer[dataStorage]?.getVisible() == true) != undefined
      case "SHOW-HIDE-ALL-SERVIZI": return this.layerServizi.length && this.layerServizi.find(lay => lay?.getVisible() == true) != undefined
      case "DRAW-CAVO": return this.cavoOperation == "DRAW"
      case "EDIT-CAVO": return this.cavoOperation == "MODIFY"
      case "SELECT-CAVO": return this.cavoOperation == "SELECT"
    }
  }

  removeAllInteractions() {
    this.modify = this.mapService.removeInteractions(this.modify);
    this.draw = this.mapService.removeInteractions(this.draw);
    this.snap = this.mapService.removeInteractions(this.snap);
    this.selectfeat = this.mapService.removeInteractions(this.selectfeat);
    this.selectfeatCavo = this.mapService.removeInteractions(this.selectfeatCavo);
    this.modifyCavo = this.mapService.removeInteractions(this.modifyCavo);
    this.selectfeatContenitore = this.mapService.removeInteractions(this.selectfeatContenitore);

    this.mapService.removeHighlightOnHover(this.layerAttivo);
  }

  mapOLFeatureToGeoJsonFeature(olFeature: Feature<SimpleGeometry>, mapProperties: boolean = true, propertiesToCopy?: string[]): FeatureGeoJson {
    let featGeo = new FeatureGeoJson()

    let geometry = new GeometryGeoJson()
    geometry.type = olFeature.getGeometry()?.getType()
    geometry.coordinates = olFeature.getGeometry()?.getCoordinates()

    featGeo.geometry = geometry
    featGeo.type = olFeature.getGeometry()?.getType()

    if (mapProperties) {
      if (propertiesToCopy && propertiesToCopy.length) {
        featGeo.properties = {}
        propertiesToCopy.map(prop => featGeo.properties[prop] = olFeature.get(prop))
      } else {
        featGeo.properties = olFeature.getProperties()
      }
    }
    return featGeo
  }

  otherSplitPointIsPresent(feature: Feature<SimpleGeometry>, operationType: string) {
    if (operationType == "INSERT_SPLIT_POINT" || operationType == "EDIT_SPLIT_POINT") {
      let features = this.mapService.getFeatureAtPoint(feature?.getGeometry()?.getCoordinates(), 'Point', this.splitLayerNames)
      features = features.filter(feat => feat.get('uid') != feature.get('uid'))
      return features.length > 0
    }
    return false
  }

  reloadEditLayers(reloadInfrastrutture: boolean, reloadConnections: boolean, reloadLayerMuffolaRoe?: boolean) {
    this.reloadLayerAttivo()

    if (reloadInfrastrutture && this.layerAttivo.get('idLayer') != this.idLayerInfrastrutture) {
      this.reloadLayerById(this.idLayerInfrastrutture)
    }

    if (reloadConnections) {
      this.connectionLayerIds.map(idLayer => this.reloadLayerById(idLayer))
    }

    if (reloadLayerMuffolaRoe && this.layerAttivo.get('idLayer') != this.idLayerMuffola) {
      this.reloadLayerById(this.idLayerMuffola)
    }

    if (reloadLayerMuffolaRoe && this.layerAttivo.get('idLayer') != this.idLayerRoe) {
      this.reloadLayerById(this.idLayerRoe)
    }
  }

  reloadLayerAttivo() {
    const layerConf = this.configListLayer.find(layerAtt => layerAtt.idLayer == this.layerAttivo.get('idLayer'))
    let params = this.getGeomParams(undefined, "LOAD", layerConf.idLayer)
    this.mapService.getLayerById(params).subscribe((data) => { this.onLoadLayerTecnico(data, layerConf) })
  }

  // Ricarica layer da server con chiamata asincrona
  reloadLayerById(idLayer: number) {
    const layerConf = this.configListLayer.find(layerAtt => layerAtt.idLayer == idLayer)
    let params = this.getGeomParams(undefined, "LOAD", idLayer)
    this.mapService.getLayerById(params).subscribe((data) => { this.onLoadLayerTecnico(data, layerConf) })
  }

  isInfrastruttura(feature: Feature<SimpleGeometry>) {
    return this.idLayerInfrastrutture == feature.get('idLayer')
  }

  isSplitLayer(feature: Feature<SimpleGeometry>) {
    return this.splitLayerIds.indexOf(feature.get('idLayer')) > -1
  }

  isVirtualLayer(feature: Feature<SimpleGeometry>) {
    return this.virtualLayerIds.indexOf(feature.get('idLayer')) > -1
  }

  isServiceLayer(feature: Feature<SimpleGeometry>) {
    return this.serviceLayerIds.indexOf(feature.get('idLayer')) > -1
  }

  isConnectionLayer(feature: Feature<SimpleGeometry>) {
    return this.connectionLayerIds.indexOf(feature.get('idLayer')) > -1
  }

  loadListFile(uid: number) {
    this.spinner = true

    let loadListFotoIN = {
      idPogetto: this.project.idProgetto,
      idSchedaProgetto: uid
    }
    console.log(loadListFotoIN)
    this.dmsService.loadListFoto(loadListFotoIN).subscribe((res) => {
      this.spinner = false
      this.listFolderFile = res.listFolderFile
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  downloadFile(downloadFileModal: RelProjectFolder) {
    let load: LoadFileINModel = new LoadFileINModel();
    load.filePath = downloadFileModal.descPath;

    this.dmsService.loadFile(load).subscribe(data => {
      let contentType = ''
      if (downloadFileModal.descMimeType == '.png') {
        contentType = "image/png";
      }
      else if (downloadFileModal.descMimeType == '.jpg') {
        contentType = "image/jpeg";
      }
      const b64Data = data.resource;
      const byteCharacters = atob(b64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      var a = document.createElement("a")
      a.href = URL.createObjectURL(new Blob([byteArray], { type: contentType }));
      a.download = downloadFileModal.descName; // + ' - ' + downloadFileModal.descSize + ' KB';
      // start download
      a.click();
    })
  }

  stampaMappa() {

    var divMap = document.getElementById("design-map")
    var divHeight = divMap.getAttribute("height")
    var divWidth = divMap.getAttribute("width")
    console.log("divHeight:" + divHeight)
    console.log("divWidth:" + divWidth)

    this.layerServizi.map(layer => layer.setVisible(false))
    this.layerGeoServer.map(layer => layer.setVisible(false))
    // this.areaArmadioLayer.setVisible(false)

    this.map.getLayers().forEach(layer => {
      if (layer instanceof TileLayer) {
        layer.setOpacity(0.4)
      }
    })

    const format = 'a4'
    const resolution = 100;
    const dim = [297, 210];
    const size = this.map.getSize();
    const viewResolution = this.map.getView().getResolution();

    divMap.setAttribute("style", "width:" + (dim[0] - 30) + "mm;height:" + (dim[1] - 30) + "mm")

    let _this = this
    this.map.getControls().forEach(control => {
      this.map.removeControl(control)
    })


    this.map.once("rendercomplete", function () {
      html2canvas(divMap).then(function (canvas) {
        var imgData = canvas.toDataURL("image/png", 1.0);
        var pdf = new jsPDF('landscape', undefined, format);
        pdf.addImage(
          imgData,
          'PNG',
          15,
          15,
          dim[0] - 30,
          dim[1] - 30
        );
        pdf.save("map.pdf");
      });

      // Reset original map size
      // _this.map.setSize(size);
      // _this.map.getView().setResolution(viewResolution);

      // _this.layerServizi.map(layer => layer.setVisible(true))
      // _this.areaArmadioLayer.setVisible(true)

      _this.map.getLayers().forEach(layer => {
        if (layer instanceof TileLayer) {
          layer.setOpacity(1)
        }
      })

    })
  }


  // Controllare se UID feature esiste
  hasFeatureUid(feature: Feature<SimpleGeometry>) {
    if (feature.get('uid') == null) {
      this.messageBox("error", "Layert tecnico non inserito correttamente")
      this.reloadLayerById(this.layerAttivo.get('idLayer'))
      return false
    }
    return true
  }

  // GESTIONE CAVI

  drawCavo(layer: ViwLayerModel) {
    this.layerAttivo = this.layerTecnici[layer.idLayer] ? this.layerTecnici[layer.idLayer] : this.layerServizi[layer.idLayer]
    this.removeAllInteractions();
    this.resetCardCavo(!this.open_card_cavo)
    this.resetCardPozzetto()
    this.cavoOperation = "DRAW"
  }

  editCavo(layer: ViwLayerModel) {
    this.layerAttivo = this.layerTecnici[layer.idLayer] ? this.layerTecnici[layer.idLayer] : this.layerServizi[layer.idLayer]
    this.removeAllInteractions();
    this.resetCardCavo();
    this.resetCardPozzetto()

    this.modifyCavo = this.mapService.addClickSelectInteractions(this.layerTecnici[this.idLayerInfrastrutture])
    this.selectInfrastrutturaForCavo(layer, this.modifyCavo, 'MODIFY')
    this.cavoOperation = "MODIFY"
  }

  selectCavo(layer: ViwLayerModel, interaction?: Select) {
    this.layerAttivo = this.layerTecnici[layer.idLayer] ? this.layerTecnici[layer.idLayer] : this.layerServizi[layer.idLayer]
    this.removeAllInteractions();
    this.resetCardCavo();
    this.resetCardPozzetto()

    this.selectfeatCavo = this.mapService.addClickSelectInteractions(this.layerTecnici[this.idLayerInfrastrutture])
    this.selectInfrastrutturaForCavo(layer, this.selectfeatCavo, 'SELECT')
    this.cavoOperation = "SELECT"
  }

  selectInfrastrutturaForCavo(layer: ViwLayerModel, interaction?: Select, operationType?: string) {
    let layerInfrastrutture = this.layerTecnici[this.idLayerInfrastrutture]
    this.snap = this.mapService.addSnapInteractionsToLayers([layerInfrastrutture])
    this.mapService.addHighlightOnHover(layerInfrastrutture, this.idLayerInfrastrutture)

    interaction.once('select', (event) => {
      this.mapService.removeInteractions(interaction)
      this.snap = this.mapService.removeInteractions(this.snap);
      this.mapService.removeHighlightOnHover(layerInfrastrutture)
      this.showSelectModalForCavo(event, operationType);
    });
  }

  showSelectModalForCavo(event, operationType) {
    this.spinner = true
    let feature = event.selected.pop();
    console.log("Get cavi from infrastruttura:", feature.get('uid'))

    let params = this.getGeomParams(feature, "LOAD_CAVI")
    this.mapService.loadCavi(params).subscribe(
      res => {
        this.spinner = false
        this.listCavi = res.cavi
        this.open_card_cavo = true
        if (operationType == 'SELECT') {
          this.selectChooseCavo = true
        }
      },
      err => this.onErrorResponse(err)
    );
  }

  changeSelectCavo(event) {
    if (event) {
      if (this.selectChooseCavo) {
        this.spinner = true
        let feat = new Feature<SimpleGeometry>();
        feat.set('uid', event.uid);
        let params = this.getGeomParams(feat, "SELECT")
        this.mapService.getAttributiFeature(params).subscribe((res) => {
          this.spinner = false
          this.openModalEditAttribute(null, event, res.listAttributiLayers, res.listRelLayerNota, res.notaPoi, res.attributeLabelStyle);
        }, err => this.onErrorResponse(err))
      } else if (!this.selectChooseCavo) {
        this.modifyChooseCavo = true
      }
      this.onRouteResponse(event)
    }
  }

  resetCardCavo(openCard = false) {
    this.array = []
    this.open_card_cavo = openCard
    if (this.layerTecnici[this.idLayerInfrastrutture].get('drawRoute')) {
      let features: Feature<any>[] = this.layerTecnici[this.idLayerInfrastrutture]?.getSource()?.getFeatures();
      features.map((feature: Feature<any>) => feature.set("inRoute", false))
    }
    this.listCavi = []
    this.open_modal_cavo = false
    this.modifyChooseCavo = false
    this.selectChooseCavo = false
    this.ngSelectValue = -1
    this.cavoOperation = undefined
    this.arrayObj = []
  }

  addPointCavo(type: string, i: number, box) {
    let filterLayers = type == 'punto' ? this.puntiIntermediLayerNames : this.puntiOtticiLayerNames

    if (type == 'punto') {
      type = type + i
    }

    this.removeAllInteractions();
    this.selectfeat = this.mapService.addClickSelectInteractionMultilayer(filterLayers);
    this.mapService.addCursorOnHoverLayers(filterLayers)

    this.selectfeat.once('select', (event) => {
      this.selectfeat = this.mapService.removeInteractions(this.selectfeat);
      this.onAddLayerPoint(event, type, box)
      this.mapService.removeCursorOnHoverLayers()
    });
  }

  onAddLayerPoint(layer, type: string, box) {
    let feature = layer.selected.pop();
    let puntoOttico = {
      type: type,
      uid: feature.get('uid'),
      parentUid: feature.get('parentUid')
    }

    box.value = type + ': ' + feature.get('uid')
    this.array.push(puntoOttico)
    console.log("ARRAY::", this.array)

    this.getRoute();
  }

  getRoute() {
    if (this.array.length && (this.array.find((el) => el.type == 'source')) && (this.array.find((el) => el.type == 'target'))) {
      this.spinner = true
      let params: ShortestPath = new ShortestPath
      const source = this.array.find(el => el.type == 'source')
      const target = this.array.find(el => el.type == 'target')

      params.idProgetto = this.project.idProgetto;
      params.source = source.parentUid ? source.parentUid : source.uid;
      params.target = target.parentUid ? target.parentUid : target.uid;
      params.listPoint = []
      this.array?.filter(el => el.type != 'source' && el.type != 'target')?.map(el => params.listPoint.push(el.parentUid ? el.parentUid : el.uid))

      this.mapService.shortestPath(params).subscribe(
        res => {
          this.spinner = false
          this.onRouteResponse(res)
        },
        err => this.onErrorResponse(err)
      );
    }
  }

  onRouteResponse(res) {
    this.listPath = res.listPath;
    this.pathWeight = res.weight ? res.weight.toFixed(3) : undefined;
    let layerInfrastrutture = this.layerTecnici[this.idLayerInfrastrutture];
    layerInfrastrutture.set('drawRoute', true)
    let features: Feature<any>[] = layerInfrastrutture?.getSource()?.getFeatures();
    features.map((feature: Feature<any>) => feature.set("inRoute", this.listPath.indexOf(feature.get("uid")) > -1))
  }

  clearInput(type: string, box) {
    console.log("TYPE::", type)
    if (this.array.length) {
      this.array = this.array.filter((el) => el.type != type)
      // this.array.splice(this.array[index], 1);
      box.value = ''
    }

    this.getRoute();
    console.log("CLEAR INPUT::", this.array)
  }

  onCavo(source, target, layer, operationType) {
    let cavo: Cavo = new Cavo()
    cavo.source = parseInt(source.substr(7))
    cavo.listPath = this.listPath
    cavo.target = parseInt(target.substr(7))
    cavo.weight = this.pathWeight
    cavo.uid = this.ngSelectValue != -1 ? this.ngSelectValue : null

    let params: EditCavo = new EditCavo()
    params.idProgetto = this.project.idProgetto
    params.idLayer = layer.idLayer
    params.cavo = cavo

    this.spinner = true
    this.mapService.insertCavo(params).subscribe(
      res => {
        this.spinner = false
        if (operationType == 'INSERT') {
          this.openModalEditAttribute(null, res.cavo, res.listAttributiLayers, null, null, res.attributeLabelStyle)
        } else if (operationType == "MODIFY") {
          this.messageBox('success', "Cavo modificato correttamente")
        }
      }, err => this.onErrorResponse(err)
    );
  }

  onSelectFeatureToLayerCavo(event) {
    this.spinner = true
    // TODO gestire geometri multiple(edit con due feature diverse che si congiungono in un punto)
    let feature = event.selected.pop();

    // this.setDefaultPropertyToFeature(feature, this.layerAttivo);
    let params = this.getGeomParams(feature, "SELECT")
    this.mapService.getAttributiFeature(params).subscribe((res) => {
      this.spinner = false
      this.openModalEditAttribute(feature, null, res.listAttributiLayers, res.listRelLayerNota, res.notaPoi, res.attributeLabelStyle);
    }, err => this.onErrorResponse(err)
    )
  }

  /*CONTENITORE PADRE (POZZETTO - MUFFOLA/RICCHEZZA)*/
  drawFeatureFiglio(layer: ViwLayerModel, i: number) {
    let hasContenitori = true
    this.puntiContenitoriLayerNames.map(layerName => {
      if (!this.mapService.layerHasFeatures(layerName)) {
        this.messageBox("error", "Inserire prima pozzetto")
        hasContenitori = false
      }
    })

    if (hasContenitori) {
      this.layerAttivo = this.layerTecnici[layer.idLayer] ? this.layerTecnici[layer.idLayer] : this.layerServizi[layer.idLayer]
      this.removeAllInteractions();
      this.resetCardCavo()
      this.resetCardPozzetto()

      this.draw = this.mapService.addDrawInteractions(this.layerAttivo, this.layerAttivo.get('name') == 'INFRASTRUTTURE' ? 'LineString' : 'Point');
      this.snap = this.mapService.addSnapInteractionsToLayers(this.layerTecnici)

      this.draw.set('idLayer', layer.idLayer)
      this.draw.once('drawend', (event) => {
        this.draw = this.mapService.removeInteractions(this.draw);
        this.snap = this.mapService.removeInteractions(this.snap);
        this.onAddFeatureToLayerFiglio(event)
      });
    }
  }

  onAddFeatureToLayerFiglio(event) {
    let feature = event.feature;
    if (this.featureIsInAreaArmadio(feature)) {
      this.setDefaultPropertyToFeature(feature, this.layerAttivo);
      this.messageBox('info', "Selezionare pozzetto da collegare", feature)
      this.childFeatureToConnect = feature
    } else {
      this.messageBox('error', 'Non è possibile disegnare fuori Area Armadio')
      this.layerAttivo.getSource().removeFeature(feature)
    }
  }

  resetCardPozzetto(openCard = false, idLayer = null) {
    if (idLayer) {
      if (idLayer == this.idLayerMuffola) {
        this.open_card_muffola = openCard
        this.open_card_ricchezza = false
        if (!this.open_card_muffola) {
          this.reloadLayerById(this.layerAttivo.get('idLayer'))
        }
      }
      else if (idLayer == this.idLayerRicchezza) {
        this.open_card_ricchezza = openCard
        this.open_card_muffola = false
        if (!this.open_card_ricchezza) {
          this.reloadLayerById(this.layerAttivo.get('idLayer'))
        }
      }
    } else {
      this.open_card_muffola = false
      this.open_card_ricchezza = false
    }
  }

  addPozzetto(box: string) {
    let filterLayers = this.puntiContenitoriLayerNames

    this.removeAllInteractions();
    this.selectfeatContenitore = this.mapService.addClickSelectInteractionMultilayer(filterLayers);
    this.mapService.addCursorOnHoverLayers(filterLayers)

    this.selectfeatContenitore.once('select', (event) => {
      this.selectfeatContenitore = this.mapService.removeInteractions(this.selectfeatContenitore);
      this.onAddLayerPozzetto(event, box)
      this.mapService.removeCursorOnHoverLayers()
    });
  }

  onAddLayerPozzetto(layer, box) {
    let feature = layer.selected.pop();
    box.value = feature.get('uid')
    this.childFeatureToConnect.set('parentUid', feature.get('uid'))
  }

  connectPozzetto(pozzetto: number, layer) {
    let insertType = "INSERT"
    let params = this.getGeomParams(this.childFeatureToConnect, "INSERT")
    this.editFeatureGeometry(this.childFeatureToConnect, params, insertType)
    this.resetCardPozzetto(layer.idLayer)
  }

  // GRAPHIC VALIDATION SUBSCRIBE
  pathGraphicValidation(data: any) {
    this.spinner = data.spinner
    data.observable.subscribe((res) => {
      this.spinner = false
      let number = []
      res.listPath.map(uid => number.push(uid.uid))

      let layerInfrastrutture = this.layerTecnici[this.idLayerInfrastrutture];
      let features: Feature<any>[] = layerInfrastrutture?.getSource()?.getFeatures();
      features.map((feature: Feature<any>) => feature.set("graphicValidation", number.indexOf(feature.get("uid")) > -1))
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  // PROJECT VALIDATION SUBSCRIBE
  projectValidation(data: any) {
    this.spinner = data.spinner
    data.observable.subscribe((res) => {
      this.spinner = false
      this.listErrorConvalida = res.listErrorConvalida
      console.log("PROJECT VALIDATION::", this.listErrorConvalida)
      if (this.listErrorConvalida.length) {
        this.messageBox("error", "Impossibile convalidare il progetto", null, this.listErrorConvalida)
      } else {
        this.messageBox("success", "Progetto convalidato con successo")
        this.mapService.loadLayers().subscribe((dataResult) => this.onLayersConfigLoad(dataResult.listViwLayer))
      }
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  cleanForConvalida(data: any) {
    let cleanForConvalida = {
      idSchedaProgetto: data.objectId
    }
    this.projectService.cleanForConvalida(cleanForConvalida).subscribe((res) => {
      this.spinner = false
      this.listErrorConvalida = this.listErrorConvalida.filter(obj => {return obj.objectId != data.objectId})
      this.messageBox("success", "Layer eliminato con successo")
    }, err => {
      this.spinner = false
      this.messageBox("error", JSON.stringify(err))
    });
  }

  /***********CERACA FEATURE PER OBJECT ID*************/
  searchFeatureByObjectId(value: string) {
    this.spinner = true
    let feature = this.mapService.getFeatureByPropertyAllLayers('objectId', value)
    if (feature) {
      // feature.setStyle(this.mapStyleService.getHighlightStyleFunction())
      feature.set('highlight', true)
      this.mapService.zoomToFeature(feature)
      this.featureSearch.push(value)
      this.spinner = false
    } else {
      this.messageBox("error", "Feature con Object Id " + value + " non trovata")
      this.spinner = false
    }
  }

  clearFeature(objId, index) {
    const feature = this.mapService.getFeatureByPropertyAllLayers('objectId', objId)
    feature.set('highlight', false)
    this.featureSearch.splice(index, 1)
  }

  zoomFeatures(objId) {
    const feature = this.mapService.getFeatureByPropertyAllLayers('objectId', objId)
    if (!feature.get('highlight')) {
      feature.set('highlight', true)
    }
    this.mapService.zoomToFeature(feature)
  }

  openModalErrorProject(content) {
    this.dialog
      .open(content, { enableClose: false, draggable: true })
      .afterClosed$.subscribe((confirmed) => {
        if (confirmed != 'by: close button') {
          this.spinner = true
          const feature = this.mapService.getFeatureByPropertyAllLayers('objectId', confirmed)
          if (feature) {
            feature.set('highlight', true)
            this.mapService.zoomToFeature(feature)
            this.spinner = false
          } else {
            this.spinner = false
            this.messageBox("warning", "Layer non trovato")
          }
        }
      });
  }
  /***********FINE CERCA FEATURE PER OBJECT ID*************/

  /***********DIALOG MESSAGGI ERRORE-SUCCESS-INFO*************/
  messageBox(operation, message, feature?, listaErrori?) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == 'error' && !listaErrori) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Errore',
        text: message,
        showConfirmButton: true,
      })
    }
    if (operation == 'info') {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: message,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Annulla'
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetCardPozzetto(this.layerAttivo.get('idLayer') == this.idLayerMuffola ? !this.open_card_muffola : !this.open_card_ricchezza, this.layerAttivo.get('idLayer'))
        } else {
          this.layerAttivo.getSource().removeFeature(feature)
        }
      })
    }
    if (operation == 'error' && listaErrori) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        text: 'Premere OK per visulizzare errore',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Annulla'
      }).then((result) => {
        if (result.isConfirmed) {
          this.openModalErrorProject(this.contentErr)
        }
      })
    }
    if (operation == "warning") {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: "Attenzione",
        text: message,
        showConfirmButton: true,
      })
    }
  }
  /*********** FINE DIALOG MESSAGGI ERRORE-SUCCESS-INFO *************/

  /****************** AGGIORNA DATI PROGETTO ******************/
  loadProjectById() {
    this.spinner = true
    const loadProjectByIdINModel = {
      idProgetto: this.project.idProgetto
    }
    this.projectService.loadProjectById(loadProjectByIdINModel).subscribe((res) => {
      this.spinner = false
      localStorage.setItem('projectSelected', JSON.stringify(res.tabProgetto))
      this.project = JSON.parse(localStorage.getItem('projectSelected'))
    }, (err: HttpErrorResponse) => {
      console.log('ERRORE')
      this.spinner = false
    });

    console.log('PRJECT::', this.project)
  }
}
