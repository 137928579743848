import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './../../../../service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TabUserModel } from '../../../../models/tabUser.model';
import { UsersComponent } from '../users.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TabProfileModel } from 'src/app/models/tabProfile.model';
import { ListTabModuleModel } from '../../../../models/listTabModule.model';
import { ListTabFunctionModel } from '../../../../models/listTabFunction.model';
import { LoadViwUserProfileINModel } from 'src/app/models/loadViwUserProfileIN.model';
import { ViwUserProfileModel } from '../../../../models/viwUserProfile.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  tabCheck: number = 1
  userProfileModel: ViwUserProfileModel[] = [];
  user: TabUserModel = new TabUserModel();
  editUserIN: TabUserModel = new TabUserModel();
  userModalCloseResult: string = '';
  listIdProfile: TabProfileModel[] = [];
  userForm: FormGroup;

  listModule: ListTabModuleModel[] = [];
  listFunction: ListTabFunctionModel[] = []

  profili: TabProfileModel[] = [{ 'name': 'AMMINISTRATORE', 'id': 1 }, { 'name': 'PROJECT MANAGER', 'id': 2 }, { 'name': 'PROGETTISTA', 'id': 3 }, { 'name': 'ASSISTENTE', 'id': 4 }]

  modifyUserINModel = {
    listIdProfile: undefined,
    tabUser: {
      idUser: undefined,
      desPwd: undefined,
      desEmail: undefined,
      desName: undefined,
      desSurname: undefined,
      desRegister: undefined,
      datCreation: undefined,
      flagExternal: false,
      flagEnabled: true,
      firstAccess: false,
      listUserGrant: [],
      listUserProfileObject: []
    },
    flgPwd: false
  }
  checkEdit: boolean = false;
  spinner: boolean = false;
  msgs = false;
  message: string = null;

  constructor(private router: Router, private userService: UserService, private modalService: NgbModal) {
    if (UsersComponent.user.idUser == null) {
      this.user = JSON.parse(localStorage.getItem('userSelected'));
      this.editUserIN = JSON.parse(localStorage.getItem('userSelected'));
    } else {
      this.user = UsersComponent.user
      this.editUserIN = UsersComponent.user
    }
    console.log("--" + JSON.stringify(this.user))
    this.getUser(this.user.idUser)
  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      desEmail: new FormControl(null, [Validators.required, Validators.email,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      desName: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(20),
      Validators.pattern("^[a-zA-Z ]*$")]),
      desSurname: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(20),
      Validators.pattern("^[a-zA-Z ]*$")]),
      flagExternal: new FormControl(null),
      listIdProfile: new FormControl(null, [Validators.required])
    })

    for (var i = 0; i < this.user.listUserProfileObject.length; i++) {

      this.listIdProfile.push(this.user.listUserProfileObject[i]);
    }
    // console.log("LIST ID PROFILE--" + JSON.stringify(this.listIdProfile))
  }

  tabButton(check: number) {
    this.tabCheck = check
  }

  getUser(idUser: number) {
    let profileUserINModel: LoadViwUserProfileINModel = new LoadViwUserProfileINModel();
    profileUserINModel.idUser = idUser
    this.userService.loadUserProfileById(profileUserINModel).subscribe((res) => {
      this.userProfileModel = res.listViwUserProfile
    }, (err: HttpErrorResponse) => {
      this.messageBox('error', JSON.stringify(err))
    });
  }

  editUser() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    // console.log("LISTA PROFILI" + JSON.stringify(this.listIdProfile))
    var arrayIdTemp: number[] = []
    for (var idProfilo = 0; idProfilo < this.listIdProfile.length; idProfilo++) {
      console.log("" + this.listIdProfile[idProfilo].id)
      arrayIdTemp.push(this.listIdProfile[idProfilo].id);

    }
    this.modifyUserINModel.listIdProfile = arrayIdTemp
    this.editUserIN.listUserProfileObject = this.listIdProfile
    this.modifyUserINModel.tabUser = this.editUserIN
    if (this.modifyUserINModel.tabUser.listUserProfileObject.length == 1 && this.modifyUserINModel.tabUser.listUserProfileObject[0].id == 1) {
      localStorage.setItem('isOnlyAdmin', "true")
    } else localStorage.setItem('isOnlyAdmin', "false")

    this.userService.modifyUser(this.modifyUserINModel).subscribe((res) => {
      this.spinner = false
      this.message = "Modifica avvenuta correttamente"
      this.messageBox('success', this.message)

      UsersComponent.user = this.editUserIN
      localStorage.setItem("userSelected", JSON.stringify(this.editUserIN));
      this.user = JSON.parse(localStorage.getItem('userSelected'));
      this.editUserIN = JSON.parse(localStorage.getItem('userSelected'));
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      //this.msgs = true
      if (UsersComponent.user.idUser == null) {
        this.user = JSON.parse(localStorage.getItem('userSelected'));
        this.editUserIN = JSON.parse(localStorage.getItem('userSelected'));
      } else {
        this.user = UsersComponent.user
        this.editUserIN = UsersComponent.user
      }
      //this.message = JSON.stringify(err)
      this.messageBox('error', JSON.stringify(err))
    });
  }

  openEditUserModal(content) {
    this.modalService.open(content, {}).result.then((result) => {
      this.userModalCloseResult = "Modal closed" + result
      if (result == 'by: edit button') {
        this.editUser()
      } else {
        if (UsersComponent.user.idUser == null) {
          this.user = JSON.parse(localStorage.getItem('userSelected'));
          this.editUserIN = JSON.parse(localStorage.getItem('userSelected'));
        } else {
          this.user = UsersComponent.user
          this.editUserIN = UsersComponent.user
        }
      }
    }).catch((res) => {
      if (UsersComponent.user.idUser == null) {
        this.user = JSON.parse(localStorage.getItem('userSelected'));
        this.editUserIN = JSON.parse(localStorage.getItem('userSelected'));
      } else {
        this.user = UsersComponent.user
        this.editUserIN = UsersComponent.user
      }
    });
  }

  loadListModule() {
    this.spinner = true;
    this.msgs = false
    this.message = ''

    this.userService.loadListModule().subscribe((res) => {
      this.spinner = false
      this.listModule = res.listTabModule
      //  console.log("LIST MODULE::", this.listModule)
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  loadListFunction() {
    this.spinner = true;
    this.msgs = false
    this.message = ''

    this.userService.loadListFunction().subscribe((res) => {
      this.spinner = false
      this.listFunction = res.listTabFunction
      //  console.log("LIST FUNCTION::", this.listFunction)
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
  }

}


