import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TabUserModel } from '../models/tabUser.model';



//@Injectable()
@Injectable({
  providedIn: 'root'
})
export class ApiService {


  url: string = environment.services


  

  constructor(
    private http: HttpClient
  ) { }

  private formatErrors(error: any) {
    return throwError(error.headers.get('Message'));
  }

  getJson(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    //const headers = new HttpHeaders();
    //headers.append('Content-Type', 'application/json');
    return this.http.get(`${this.url}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${this.url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }


  postLogin(path: string, body: Object = {}): Observable<any> {
    var headers = new HttpHeaders().set('Content-Type', 'application/json').set('language', 'it').set('idUser', '0').set('idModule', '0').set('idFunction', '0')
      .set('token', '');

    return this.http.post(
      `${this.url}${path}`,
      JSON.stringify(body), { headers: headers }
    ).pipe(catchError(this.formatErrors));
  }


  post(path: string, body: Object = {}): Observable<any> {
    var token: string = '';
    if (localStorage.getItem('token') != null) {
      token = localStorage.getItem('token');
    }
    var idUser: number = 0;
    if (localStorage.getItem('tabUser') != null) {
      var tabUser: TabUserModel = JSON.parse(localStorage.getItem('tabUser'));
      idUser = tabUser.idUser;
    }
    var headers = new HttpHeaders().set('Content-Type', 'application/json').set('language', 'it').set('idUser', '' + idUser).set('idModule', '0').set('idFunction', '0')
      .set('token', token);

    return this.http.post(
      `${this.url}${path}`,
      JSON.stringify(body), { headers: headers }
    ).pipe(catchError(this.formatErrors));
  }

  postUpdateFile(path: string, body: any): Observable<any> {
    var token: string = ''; if (localStorage.getItem('token') != null) {
      token = localStorage.getItem('token');
    }
    var idUser: number = 0; if (localStorage.getItem('tabUser') != null) {
      var tabUser: TabUserModel = JSON.parse(localStorage.getItem('tabUser')); idUser = tabUser.idUser;
    }
    var headers = new HttpHeaders().set('language', 'it')
      .set('idUser', '' + idUser).set('idModule', '0').set('idFunction', '0')
      .set('token', token); return this.http.post(`${this.url}${path}`, body, { headers: headers })
        .pipe(catchError(this.formatErrors));
  }

  delete(path: string): Observable<any> {
    return this.http.delete(
      `${this.url}${path}`
    ).pipe(catchError(this.formatErrors));
  }
}
