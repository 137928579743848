import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router"
import { DataTable } from "simple-datatables";
import { ConfigService } from './../../../service/config.service';
import { TabAclrrModel } from '../../../models/tabAclrr.model';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DeleteAclrrINModel } from '../../../models/deleteAclrrIN.model';
import { InsertAclrrINModel } from '../../../models/insertAclrrIN.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ModifyAclrrINModel } from '../../../models/modifyAclrrIN.model';
import { TabAreeModel } from '../../../models/tabAree.model';
import Swal from 'sweetalert2';
import { UploadFileShpINModel } from '../../../models/upload-file-shp-in-model.model';
import { LoadLayersIN } from '../../../models/load-layers-in.model';
import { RelTabAclrrGeoServer } from '../../../models/rel-tab-aclrr-geo-server.model';
import { DeleteLayerIN } from '../../../models/delete-layer-in.model';
import { ViwAclrrModel } from '../../../models/viwAclrr.model';
import { GeoServerService } from '../../../service/geo-server.service';

export interface Aclrr {
  codAclrr: string
  desAdc: string
  idAclrr: number
  idArea: number
}

@Component({
  selector: 'app-aclrr',
  templateUrl: './aclrr.component.html',
  styleUrls: ['./aclrr.component.scss']
})
export class AclrrComponent implements OnInit {
  aclrrModel: TabAclrrModel[] = [];
  aclrrModalCloseResult: string = '';
  aclrr: Aclrr = {
    codAclrr: undefined,
    desAdc: undefined,
    idAclrr: undefined,
    idArea: undefined
  }

  listTipoShape = [{ id: 1, desc: "ATTACHMENT" }, { id: 2, desc: "RAMEOLDPOINT" }, { id: 3, desc: "RAMEOLDPOLYLINE" },
  { id: 4, desc: "SPAN" }, { id: 5, desc: "SPLICE_CLOSURE" }, { id: 6, desc: "STRUCTURE_Infrastrutture" },
  { id: 7, desc: "STRUCTURE_Rete_Ottica" }, { id: 8, desc: "TRANSMEDIA" }]

  rows = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  checkEdit: boolean = true;

  spinner = false;
  msgs = false;
  message: string = null;

  aclrrForm: FormGroup;
  aclrrFormEdit: FormGroup;
  aclrrFormUpload: FormGroup;

  insertAclrrINModel = new InsertAclrrINModel();
  deleteAclrrINModel = new DeleteAclrrINModel();
  deleteLayerINModel = new DeleteLayerIN();
  aclrrEdit: TabAclrrModel = new TabAclrrModel();
  aclrrUpload: ViwAclrrModel = new ViwAclrrModel();

  uploadFileShpIN: UploadFileShpINModel = new UploadFileShpINModel();
  listAree: TabAreeModel[] = [];
  listRelTabAclrrGeoServer: RelTabAclrrGeoServer[]
  layerSelected: RelTabAclrrGeoServer

  constructor(
    private router: Router,
    private configService: ConfigService,
    private geoServerService: GeoServerService,
    private modalService: NgbModal) {
    this.fetch(data => {
      this.rows = data;
    });
  }

  ngOnInit(): void {
    this.aclrrForm = new FormGroup({
      codAclrr: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6),
      Validators.pattern("^([0-9]{5})([A-Z]{1})$")]),
      desAdc: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      idArea: new FormControl(null, [Validators.required])
    })
    this.aclrrFormEdit = new FormGroup({
      codAclrr: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6),
      Validators.pattern("^([0-9]{5})([A-Z]{1})$")]),
      desAdc: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      idArea: new FormControl(null, [Validators.required])
    })
    this.aclrrFormUpload = new FormGroup({
      storage: new FormControl(null, [Validators.required]),
      idShpe: new FormControl(null, [Validators.required])
    })
  }

  fetch(cb) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.configService.loadListAclrr().subscribe((res) => {
      this.spinner = false
      this.aclrrModel = res.listTabAclrr;
      this.rows = res.listTabAclrr;
      //  console.log("res:: " + JSON.stringify(this.aclrrModel))
      this.loadingIndicator = false;
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  callLoadListAree() {
    this.configService.loadListAree().subscribe((res) => {
      this.spinner = false
      this.listAree = res.listTabAree
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  addAclrr() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.insertAclrrINModel.tabAclrr = this.aclrr
    //  console.log("INSERT::", JSON.stringify(this.insertAclrrINModel))

    this.configService.insertAclrr(this.insertAclrrINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Centrale Aclrr creata correttamente"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err.headers)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  deleteAclrr(modelDelete: DeleteAclrrINModel, index: number) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.deleteAclrrINModel.idAclrr = modelDelete.idAclrr

    this.configService.deleteAclrr(this.deleteAclrrINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Centrale Aclrr elimanata con successo"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  resetUpload(): void {
    this.aclrrFormUpload.reset()
  }

  deleteLayer(row: RelTabAclrrGeoServer, index: number) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    let modelDeleteLayer: DeleteLayerIN = {
      idCentrale: row.idAclrr,
      storage: row.dataStorage,
      workspace: row.workspace
    };

    this.geoServerService.deleteLayer(modelDeleteLayer).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Operazione avvenuta con successo"
      this.messageBox('success', this.message)
      this.loadLayers()


    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.aclrrModel.filter(function (d) {
      return (d.codAclrr.toLowerCase().indexOf(val) !== -1 ||
        d.desAdc.toLowerCase().indexOf(val) !== -1) || !val;
    });
    this.rows = temp;
  }

  editAclrr(indexRow, rowSelected) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    let modifyAclrrINModel: ModifyAclrrINModel = {
      tabAclrr: rowSelected
    };
    this.configService.modifyAclrr(modifyAclrrINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Modifica avvenuta correttamente"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  uploadFileShp() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.uploadFileShpIN.idCentrale = this.aclrrUpload.idAclrr
    this.uploadFileShpIN.area = this.aclrrUpload.desArea
    this.uploadFileShpIN.codice = this.aclrrUpload.codAclrr
    this.uploadFileShpIN.storage = this.aclrrFormUpload.controls['storage'].value

    this.geoServerService.uploadFileShp(this.uploadFileShpIN).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Salvataggio avvenuto correttamente"
      this.messageBox('success', this.message)
      this.loadLayers()

    }, (err: HttpErrorResponse) => {
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  loadLayers() {
    this.spinner = true
    this.loadingIndicator = true
    this.msgs = false
    this.message = ''

    let loadLayersIN: LoadLayersIN = {
      idCentrale: this.aclrrUpload.idAclrr
    };

    this.geoServerService.loadLayers(loadLayersIN).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;

      this.listRelTabAclrrGeoServer = res.listRelTabAclrrGeoServer

    }, (err: HttpErrorResponse) => {
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  openAddAclrrModal(content) {
    this.callLoadListAree()

    this.modalService.open(content, {}).result.then((result) => {
      this.aclrrModalCloseResult = "Modal closed" + result
      if (result == "by: save button") {
        this.addAclrr()
        this.aclrrForm.reset();
      } else {
        this.aclrrForm.reset();
      }
    }).catch((res) => { });
  }

  openDeleteAclrrModal(content, indexRow, rowSelected) {
    this.modalService.open(content, {}).result.then((result) => {
      this.aclrrModalCloseResult = "Modal closed" + result
      if (result == "by: delete button") {
        this.deleteAclrr(rowSelected, indexRow);
      }
    }).catch((res) => { });
  }

  openDeleteLayerModal(content, indexRow, rowSelected) {
    this.layerSelected = rowSelected
    this.modalService.open(content, {}).result.then((result) => {
      this.aclrrModalCloseResult = "Modal closed" + result
      if (result == "by: delete button") {
        this.deleteLayer(rowSelected, indexRow);
      }
    }).catch((res) => { });
  }

  openEditAclrrModal(content, rowSelected, indexRow) {
    this.callBackground();
    this.callLoadListAree()
    this.aclrrEdit = rowSelected

    this.modalService.open(content, {}).result.then((result) => {
      this.aclrrModalCloseResult = "Modal closed" + result
      if (result == "by: edit button") {
        this.checkEdit = false
        this.editAclrr(indexRow, this.aclrrEdit)
      }
    }).catch((res) => { });
  }

  openUploadAclrrModal(content, rowSelected, indexRow) {
    this.aclrrUpload = rowSelected
    console.log(rowSelected)
    this.loadLayers()
    this.resetUpload()
    this.modalService.open(content, {}).result.then((result) => {
      // c
      //this.checkEdit = false
      // this.uploadFileShp()
      // }
    }).catch((res) => { });
  }

  saveShape() {
    this.uploadFileShp()

    this.resetUpload()
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadFileShpIN.file = file;
      console.log(file)

    }
  }

  callBackground() {
    this.checkEdit = true
    this.configService.loadListAclrr().subscribe((res) => {
      if (this.checkEdit) {
        this.aclrrModel = res.listTabAclrr;
        this.rows = res.listTabAclrr;
      }
    }, (err: HttpErrorResponse) => {
      this.messageBox('error', JSON.stringify(err))
    });
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
  }
}
