import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgbModal, NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DeleteProjectINModel } from '../../../models/deleteProjectIN.model';
import { ProjectService } from '../../../service/project.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterListUserProjectINModel } from 'src/app/models/filterListUserProjectIN.model';
import { FilterListProjectINModel } from '../../../models/filterListProjectIN.model';
import { InsertProgettoINModel } from '../../../models/insertProgettoIN.model';
import { LoadListUserProjectINModel } from '../../../models/loadListUserProjectIN.model';
import { ViwProjectModel } from '../../../models/viwProject.model';
import { TabUserModel } from '../../../models/tabUser.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from './../../../service/config.service';
import { ListViwClusterModel } from '../../../models/listViwCluster.model';
import Swal from 'sweetalert2';
import { Filter } from '../../../models/filter.model';
import { ExportExcelIN } from '../../../models/export-excel-in.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  rows = [];
  rowsCluster = []
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;
  projectModalCloseResult: string = ''

  hoveredDate: NgbDate | null = null;
  projectForm: FormGroup;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  spinner = false;
  msgs = false;
  message: string = null;
  userLogin = JSON.parse(localStorage.getItem('tabUser'))
  loginUserProfile: number = 0
  date: Date


  listProject: any[] = []
  listCodiceArmadioFilter: Filter[] = []
  listCodiceCentraleFilter: Filter[] = []
  listComuneFilter: Filter[] = []
  listStatoLavorazioneFilter: Filter[] = []

  loadListUserProjectIN = new LoadListUserProjectINModel()
  filterListUserProjectIN = new FilterListUserProjectINModel()
  filterListProjectIN = new FilterListProjectINModel()
  insertProgettoINModel = new InsertProgettoINModel()
  deleteProjectINModel = new DeleteProjectINModel()

  listViwComuni: ListViwClusterModel[] = []
  listViwCentrali: ListViwClusterModel[] = []
  listIdProgetto: number[] = []
  resource: string

  codiceCentrale: string
  comune: string
  codiceArmadio: string
  statoLavorazione: string
  isCollapsed: boolean = true;
  isOnlyAdmin: boolean = JSON.parse(localStorage.getItem('isOnlyAdmin'))

  centraleAclrr: boolean = false
  cluster: boolean = false

  insertProgettoIN = new InsertProgettoINModel()
  addProject = {
    tabProgetto: {
      codiceArmadio: undefined,
      codiceCentrale: undefined,
      codiceIdProgetto: undefined,
      codiceNetwork: undefined,
      dataCreazione: undefined,
      dataFine: undefined,
      dataInizio: undefined,
      descrizione: undefined,
      flagConvalidato: false,
      idCluster: undefined,
      idProgetto: undefined,
      idUser: undefined,
      idUserLock: undefined,
      istatComune: undefined
    }
  }

  constructor(private router: Router,
    public datepipe: DatePipe,
    private projectService: ProjectService,
    private modalService: NgbModal, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,
    private configService: ConfigService) {
    this.profileLoginUser()

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    this.fetch(data => {
      this.rows = data;
    });
  }

  ngOnInit(): void {
    this.projectForm = new FormGroup({
      codiceArmadio: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      descrizione: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      codiceIdProgetto: new FormControl(null, [Validators.minLength(1), Validators.maxLength(100)]),
      codiceNetwork: new FormControl(null, [Validators.minLength(1), Validators.maxLength(100)]),
      dataFine: new FormControl(null),
      dataInizio: new FormControl(null),
      idAclrr: new FormControl(null, [Validators.required]),
      idComune: new FormControl(null, [Validators.required])
    })
    this.getComuni()
  }

  getComuni() {
    this.configService.loadComuniFromCluster().subscribe((res) => {
      this.spinner = false
      this.listViwComuni = res.listViwCluster
      console.log("LISTA COMUNI::", this.listViwComuni)
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      //this.msgs = true
      //this.message = JSON.stringify(err)
      this.messageBox('error', JSON.stringify(err))
    });
  }

  onSelectComune(event) {
    this.rowsCluster = []
    this.listViwCentrali = []
    this.addProject.tabProgetto.codiceCentrale = null

    let filterClusterIN = {
      idComune: event.idComune
    }
    this.configService.loadAclrrClusterById(filterClusterIN).subscribe((res) => {
      this.spinner = false
      this.listViwCentrali = res.viwClustersList
      console.log("LISTA CENTRALI::", this.listViwCentrali)
      this.centraleAclrr = true
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      //this.msgs = true
      //this.message = JSON.stringify(err)
      this.messageBox('error', JSON.stringify(err))
    });
  }

  onSelectCluster(event) {
    this.cluster = true
    this.rowsCluster = []
    this.rowsCluster.push(event)
    console.log("CLUSTER::", JSON.stringify(this.rowsCluster))

    this.addProject.tabProgetto.idCluster = event.idCluster
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  profileLoginUser() {
    let profili: any[] = []
    for (let item of this.userLogin.listUserGrant) {
      profili = item.listUserProfileObject
    }
    for (let i of profili) {
      if (i.id == 1) {
        this.loginUserProfile = i.id
        break
      } else if (i.id == 2) {
        this.loginUserProfile = i.id
        break
      }
    }
  }

  fetch(cb) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    if (this.loginUserProfile == 1 || this.loginUserProfile == 2) {
      let loadListProjectIN = new LoadListUserProjectINModel()

      this.projectService.loadListUserProject(loadListProjectIN).subscribe((res) => {
        this.spinner = false

        this.listProject = res.listViwProject
        console.log("LISTA PROGETTI::", JSON.stringify(this.listProject))
        this.rows = this.listProject
        this.listCodiceArmadioFilter = res.listCodiceArmadioFilter
        this.listCodiceCentraleFilter = res.listCodiceCentraleFilter
        this.listComuneFilter = res.listComuneFilter
        this.listStatoLavorazioneFilter = res.listStatoLavorazioneFilter

        this.loadingIndicator = false;
      }, (err: HttpErrorResponse) => {
        this.spinner = false
        /*this.msgs = true
        this.message = JSON.stringify(err)*/
        this.messageBox('error', JSON.stringify(err))
      });
    } else {

      this.projectService.loadListUserProject(this.loadListUserProjectIN).subscribe((res) => {
        this.spinner = false

        this.listProject = res.listViwProject
        console.log("LISTA PROGETTI USER::", JSON.stringify(this.listProject))
        this.rows = this.listProject
        this.listCodiceArmadioFilter = res.listCodiceArmadioFilter
        this.listCodiceCentraleFilter = res.listCodiceCentraleFilter
        this.listComuneFilter = res.listComuneFilter
        this.listStatoLavorazioneFilter = res.listStatoLavorazioneFilter

        this.loadingIndicator = false;
      }, (err: HttpErrorResponse) => {
        this.spinner = false
        /*this.msgs = true
        this.message = JSON.stringify(err)*/
        this.messageBox('error', JSON.stringify(err))
      });
    }
  }

  insertProject() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    var idUser: number = 0;
    if (localStorage.getItem('tabUser') != null) {
      var tabUser: TabUserModel = JSON.parse(localStorage.getItem('tabUser'));
      idUser = tabUser.idUser;
    }
    this.addProject.tabProgetto.dataInizio = this.formatter.format(this.fromDate);
    this.addProject.tabProgetto.dataFine = this.formatter.format(this.toDate);
    this.addProject.tabProgetto.idUser = idUser;

    this.insertProgettoIN.tabProgetto = this.addProject.tabProgetto
    console.log("INSERT PROJECT MODEL", JSON.stringify(this.insertProgettoIN.tabProgetto))

    this.projectService.insertProgetto(this.insertProgettoIN).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;

      this.fetch(data => {
        this.rows = data;
      });

      this.message = "Progetto creato correttamente"
      this.messageBox('success', this.message)
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      //this.msgs = true
      //this.message = JSON.stringify(err)
      this.messageBox('error', JSON.stringify(err))
    });
  }

  deleteProject(modelDelete: ViwProjectModel, index: number) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.deleteProjectINModel.idProgetto = modelDelete.idProgetto

    this.projectService.deleteProject(this.deleteProjectINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;

      this.fetch(data => {
        this.rows = data;
      });

      this.message = "Progetto eliminato con successo"
      this.messageBox('success', this.message)
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      //this.msgs = true
      //this.message = JSON.stringify(err)
      this.messageBox('error', JSON.stringify(err))
    });
  }

  openDeleteProjectModal(content, indexRow, rowSelected) {
    this.modalService.open(content, {}).result.then((result) => {
      this.projectModalCloseResult = "Modal closed" + result
      if (result == 'by: delete button') {
        this.deleteProject(rowSelected, indexRow);
      }
    }).catch((res) => { });
  }

  openAddProjectModal(content) {
    this.centraleAclrr = false;

    this.modalService.open(content, { size: 'xl' }).result.then((result) => {
      this.projectModalCloseResult = "Modal closed" + result
      if (result == 'by: save button') {
        this.insertProject();
        this.projectForm.reset();
        this.rowsCluster = [];
      } else {
        this.projectForm.reset();
        this.rowsCluster = [];
      }
    }).catch((res) => { });
  }

  redirectProjectDetail(project: ViwProjectModel) {
    console.log("----" + JSON.stringify(project));
    localStorage.setItem("projectSelected", JSON.stringify(project));
    this.router.navigate(['/project/project-detail'])
  }


  clearFilterListProject() {
    this.fetch('')
    this.clearFilter()
  }

  filterListProject() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    if (this.loginUserProfile == 1 || this.loginUserProfile == 2) {
      this.filterListProjectIN.comune = this.comune
      this.filterListProjectIN.codiceArmadio = this.codiceArmadio
      this.filterListProjectIN.codiceCentrale = this.codiceCentrale
      this.filterListProjectIN.statoLavorazione = this.statoLavorazione
      this.projectService.filterListProject(this.filterListProjectIN).subscribe((res) => {
        this.spinner = false

        this.listProject = res.listViwProject
        console.log("LISTA PROGETTI::", JSON.stringify(this.listProject))
        this.rows = this.listProject
        this.listCodiceArmadioFilter = res.listCodiceArmadioFilter
        this.listCodiceCentraleFilter = res.listCodiceCentraleFilter
        this.listComuneFilter = res.listComuneFilter
        this.listStatoLavorazioneFilter = res.listStatoLavorazioneFilter

        this.loadingIndicator = false;
        // this.clearFilter()
      }, (err: HttpErrorResponse) => {
        this.spinner = false
        //this.msgs = true
        //this.message = JSON.stringify(err)
        this.messageBox('error', JSON.stringify(err))
      });
    } else {
      this.filterListUserProjectIN.emailUtente = this.userLogin.desEmail
      this.filterListUserProjectIN.comune = this.comune
      this.filterListUserProjectIN.codiceArmadio = this.codiceArmadio
      this.filterListUserProjectIN.codiceCentrale = this.codiceCentrale
      this.filterListUserProjectIN.statoLavorazione = this.statoLavorazione
      this.projectService.filterListUserProject(this.filterListUserProjectIN).subscribe((res) => {
        this.spinner = false

        this.listProject = res.listViwProject
        console.log("LISTA PROGETTI USER::", JSON.stringify(this.listProject))
        this.rows = this.listProject
        this.listCodiceArmadioFilter = res.listCodiceArmadioFilter
        this.listCodiceCentraleFilter = res.listCodiceCentraleFilter
        this.listComuneFilter = res.listComuneFilter
        this.listStatoLavorazioneFilter = res.listStatoLavorazioneFilter

        this.loadingIndicator = false;
        this.clearFilter()
      }, (err: HttpErrorResponse) => {
        this.spinner = false
        //this.msgs = true
        //this.message = JSON.stringify(err)
        this.messageBox('error', JSON.stringify(err))
      });
    }
  }

  downloadFile() {
    this.listIdProgetto = []
    let model: ExportExcelIN = new ExportExcelIN();
    for (let i = 0; i < this.rows.length; i++) {
      this.listIdProgetto.push(this.rows[i].idProgetto)
    }
    model.listIdProgetto = this.listIdProgetto

    this.projectService.exportPrjExcel(model).subscribe(data => {
      const contentType = "application/octet-stream";
      const b64Data = data.resource;
      const byteCharacters = atob(b64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      var a = document.createElement("a")
      a.href = URL.createObjectURL(new Blob([byteArray], { type: contentType }));
      this.date = new Date()
      let latest_date = this.datepipe.transform(this.date, 'dd-MM-yyyy');
      a.download = "PROGETTI " + latest_date + '.xlsx';
      // start download
      a.click();
    })
  }

  clearFilter() {
    this.codiceCentrale = null
    this.comune = null
    this.codiceArmadio = null
    this.statoLavorazione = null
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
  }
}
