import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Feature, Map } from 'ol'
import { HttpClient } from '@angular/common/http';
import { GeoJSON } from 'ol/format'
import { MapService } from '../../../service/map.service';
import { ViwProjectModel } from 'src/app/models/viwProject.model';
import { MapStyleService } from 'src/app/service/mapStyle.service';
import VectorLayer from 'ol/layer/Vector';
import { Draw, Modify, Select, Snap } from 'ol/interaction';
import { GeoJson, Feature as GeomFeature, Geometry as GeometryGeoJson } from 'src/app/models/webgis.model';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { environment } from 'src/environments/environment';
import { RelTabAclrrGeoServer } from 'src/app/models/rel-tab-aclrr-geo-server.model';
import TileLayer from 'ol/layer/Tile';
import { LoadLayersIN } from 'src/app/models/load-layers-in.model';
import { GeoServerService } from 'src/app/service/geo-server.service';
import { ViwLayerModel } from 'src/app/models/viwLayer.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  @Input() project: ViwProjectModel;
  userLogin = JSON.parse(localStorage.getItem('tabUser'))

  dataLayers: any
  geojsonArea: string = null
  spinner: boolean = false

  map: Map;
  areaArmadioLayer: VectorLayer<any>;
  draw: Draw;
  modify: Modify;
  snap: Snap;

  //Subscription
  //areaArmadioLoadSubscription: Subscription;

  configGeoServerListLayer: RelTabAclrrGeoServer[] = []
  layerGeoServer: TileLayer<any>[] = new Array();
  layerTecnici: VectorLayer<any>[] = new Array();
  layerAttivo: VectorLayer<any>;
  selectfeat: Select;

  open_map: boolean = false
  open_map_text: string = 'col-xl-9'

  layerRicerceArea = {
    layerName: '',
    workSpace: ''
  }

  constructor(private mapService: MapService,
    private httpClient: HttpClient,
    private mapStyleService: MapStyleService,
    private geoServerService: GeoServerService) { }


  ngOnInit() {
    //this.areaArmadioLoadSubscription = this.mapService.areaArmadioLoadEvent.subscribe((data:string) => {this.onLoadAreaArmadio(data) })

    this.spinner = true
    this.map = this.mapService.initMap('map')

    setTimeout(() => {
      this.map.updateSize()

      let loadAreaArmadioIN = {
        idProgetto: this.project.idProgetto
      }
      this.mapService.loadAreaArmadio(loadAreaArmadioIN).subscribe((data) => this.onLoadAreaArmadio(data))

      let loadLayersIN: LoadLayersIN = {
        idCentrale: this.project.idAclrr
      };
      this.geoServerService.loadLayers(loadLayersIN).subscribe((data) => this.onLayersGeoServerConfigLoad(data.listRelTabAclrrGeoServer))
      this.spinner = false
    }, 300)
  }

  ngOnDestroy(): void {
    //this.areaArmadioLoadSubscription.unsubscribe()
  }

  onLoadAreaArmadio(area: GeoJson) {
    this.dataLayers = area

    if (this.dataLayers && this.dataLayers.features) {
      this.areaArmadioLayer = this.mapService.addVectorLayerToMap(this.dataLayers, 'areaArmadio', this.mapStyleService.getStyleById('areaArmadio'))
      this.mapService.zoomToLayer(this.areaArmadioLayer)
      this.spinner = false
    } else {
      let cql_filter = 'PRO_COM=' + Number(this.project.istatComune)
      let comuneLayer = this.mapService.addWFSGeoJsonFromGeoserverToMap('COMUNE', environment.geoserver, environment.geoserver_wfs_workspace_limiti_comunali, environment.geoserver_wfs_layer_limiti_comunali, cql_filter)
      comuneLayer.getSource().once("featuresloadend", event => {
        if (event.features?.length) {
          this.mapService.zoomToLayer(comuneLayer);
        } else {
          this.messageBox("info", "Comune non trovato")
        }
        this.spinner = false
      })
    }
  }

  onLayersGeoServerConfigLoad(data: RelTabAclrrGeoServer[]) {
    this.configGeoServerListLayer = data
    console.log("GEOSERVER::", this.configGeoServerListLayer)
    // carica layer tecnici
    //TODO da verificare se utilizzare chiamata unica per tutti i layer 
    this.configGeoServerListLayer.map(layer => {
      //TODO gestire casi error
      //TODO da richiamare a seguitodel recupero lista layers tile
      let lay = this.mapService.addTileLayerToMap(layer.dataStorage, environment.geoserver, layer.nomeLayer)
      this.mapService.setOpacityToLayer(lay, 0.5)
      this.layerGeoServer[layer.dataStorage] = lay
      //TODO da sostituire con visible da config 
      lay.setVisible(false) //layer.setVisible(layerConfig.visible)
      if (layer.dataStorage == 'STRUCTURE_Rete_Ottica') {
        this.layerRicerceArea.layerName = layer.dataStorage
        this.layerRicerceArea.workSpace = layer.workspace
      }
    }
    )
  }

  showHideGeoServerLayer(layer: RelTabAclrrGeoServer) {
    let showHideLayer = this.layerGeoServer[layer.dataStorage]
    showHideLayer?.setVisible(!showHideLayer.getVisible())
  }

  showHideGeoServerAllLayer(layers, action) {
    let visible = !this.isButtonActive(action, null)
    Object.keys(layers).map(dataStorage => {
      this.layerGeoServer[dataStorage].setVisible(visible)
    })
  }

  isButtonActive(action: string, layer: ViwLayerModel, tileLayer?: RelTabAclrrGeoServer) {
    switch (action) {
      case "DRAW": return this.draw && this.draw.getActive() && layer.idLayer === this.layerAttivo.get('idLayer')
      case "EDIT": return this.modify && this.modify.getActive() && layer.idLayer === this.layerAttivo.get('idLayer')
      case "SELECT": return this.selectfeat && this.selectfeat.getActive() && layer.idLayer === this.layerAttivo.get('idLayer')
      case "SHOW-HIDE": return this.layerTecnici[layer.idLayer]?.getVisible()
      case "SHOW-HIDE-TILE": return this.layerGeoServer[tileLayer.dataStorage]?.getVisible()
      case "SHOW-HIDE-ALL-GEOSERVER": return Object.keys(this.layerGeoServer).length && Object.keys(this.layerGeoServer).find(dataStorage => this.layerGeoServer[dataStorage]?.getVisible() == true) != undefined
    }
  }

  drawArea(event: any) {
    this.areaArmadioLayer = this.mapService.addVectorLayerToMap(null, 'areaArmadio', this.mapStyleService.getStyleById('areaArmadio'))

    this.mapService.removeInteractions(this.modify);
    this.mapService.removeInteractions(this.snap);
    this.draw = this.mapService.addDrawInteractions(this.areaArmadioLayer, 'Polygon');
    this.snap = this.mapService.addSnapInteractions(this.areaArmadioLayer);

    this.draw.on('drawend', (event) => {
      this.mapService.removeLayerByName('COMUNE')
      this.mapService.removeInteractions(this.draw);
      this.mapService.removeInteractions(this.snap);

      let params = this.getGeomParams(event.feature);

      localStorage.setItem("geojson", JSON.stringify(params));
      console.log("GEOJSON DRAW::", params)
      //TODO //this.mapService.insertAreaArmadio()
      this.modifyArea()
    });
  }

  getGeomParams(feature: Feature<SimpleGeometry>) {
    let geometry = new GeometryGeoJson()
    geometry.type = feature.getGeometry()?.getType()
    geometry.coordinates = feature.getGeometry()?.getCoordinates()

    return {
      idProgetto: this.project.idProgetto,
      geometry: geometry
    }
  }

  modifyArea() {

    this.mapService.removeInteractions(this.draw);
    this.mapService.removeInteractions(this.snap);
    this.modify = this.mapService.addModifyInteractions(this.areaArmadioLayer);
    this.snap = this.mapService.addSnapInteractions(this.areaArmadioLayer);

    this.modify.on('modifyend', (event) => {
      this.mapService.removeLayerByName('COMUNE')
      var feature = this.areaArmadioLayer.getSource().getFeatures()[0];
      let params = this.getGeomParams(feature);
      localStorage.setItem("geojson", JSON.stringify(params));
      console.log("GEOJSON MODIFY::", params)
    });
  }

  deleteAreaArmadio() {
    //TODO cancellazione layer a BE
    this.mapService.removeLayer(this.areaArmadioLayer)
    this.dataLayers = null
  }

  searchAreaArmadio(value: string) {
    this.spinner = true
    this.mapService.removeLayerByName('AREAARMADIO')
    let cql_filter = 'NOME_RA like \'%25' + value + '%25\''
    let armadio = this.mapService.addWFSGeoJsonFromGeoserverToMap('AREAARMADIO', environment.geoserver, this.layerRicerceArea.workSpace, this.layerRicerceArea.layerName, cql_filter)
    armadio.setStyle(this.mapStyleService.getSearchArmadioStyle())

    armadio.getSource().once("featuresloadend", event => {
      if (event.features?.length) {
        this.mapService.zoomToLayer(armadio, 20);
      } else {
        this.messageBox("info", "Feature " + value + " non trovata")
      }
      this.spinner = false
    })
  }

  clearSearchAreaArmadio() {
    this.mapService.removeLayerByName('AREAARMADIO')
  }

  expand(open) {
    if (open == 'open_map') {
      this.open_map = true;
      this.open_map_text = 'col-xl-12'
      this.spinner = true
    }

    setTimeout(() => {
      this.map.updateSize()
      this.spinner = false
    }, 300)
  }

  collapse(open) {
    if (open == 'open_map') {
      this.open_map = false;
      this.open_map_text = 'col-xl-8'
    }

    setTimeout(() => {
      this.map.updateSize()
      this.spinner = false
    }, 300)
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == 'error') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
    if (operation == 'info') {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: message,
        showConfirmButton: true,
      })
    }
  }
}
