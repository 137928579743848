import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { LoginAuthINModel } from '../models/loginAuthIN.model';
import { LoginAuthOUTModel } from '../models/loginAuthOUT.model';
import { ChangePasswordINModel } from '../models/changePasswordIN.model';
import { ChangePasswordUtenteIN } from '../models/change-password-utente-in.model';
import { ChangePasswordUtenteOUT } from '../models/change-password-utente-out.model';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private apiService: ApiService
  ) { }


  login(model: LoginAuthINModel): Observable<LoginAuthOUTModel> {
    return this.apiService.postLogin('authenticate/api/v1/login', model.loginIN);
  }

  changePassword(model: ChangePasswordINModel): Observable<any> {
    return this.apiService.post('authenticate/api/v1/changePassword', model);
  }

  changePasswordUtente(model: ChangePasswordUtenteIN): Observable<ChangePasswordUtenteOUT> {
    return this.apiService.post('authenticate/api/v1/changePasswordUtente', model);
  }

  tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  changePasswordExpired(model: ChangePasswordUtenteIN): Observable<any> {
    return this.apiService.post('authenticate/api/v1/changePasswordExpired', model);
  }
}
