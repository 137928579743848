import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { InsertPlanimetriaINModel } from '../models/insertPlanimetriaIN.model';
import { InsertPlanimetriaOUTModel } from '../models/insertPlanimetriaOUT.model';
import { ReportStatusINModel } from '../models/reportStatusIN.model';
import { ReportStatusOUTModel } from '../models/reportStatusOUT.model';
import { DowloadPrintINModel } from '../models/dowloadPrintIN.model';
import { DowloadPrintOUTModel } from '../models/dowloadPrintOUT.model';
import { PrintSinotticoINModel } from '../models/printSinotticoIN.model';
import { PrintSinotticoOUTModel } from '../models/printSinotticoOUT.model';
import { CreateShapeFileINModel } from '../models/createShapeFileIN.model';
import { DownloadReportSistemaINModel } from '../models/downloadReportSistema.model';
import { DownloadMisureINModel } from '../models/downloadMisureIN.model';
import { GetStatusMisureINModel } from '../models/getStatusMisure.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private apiService: ApiService
  ) { }

  insertPlanimetria(model: InsertPlanimetriaINModel): Observable<InsertPlanimetriaOUTModel> {
    return this.apiService.post('report/api/mapFish/v1/insertPlanimetria', model);
  }

  getStatus(model: ReportStatusINModel): Observable<ReportStatusOUTModel> {
    return this.apiService.post('report/api/mapFish/v1/getStatus', model);
  }

  dowloadReport(model: DowloadPrintINModel): Observable<DowloadPrintOUTModel> {
    return this.apiService.post('report/api/mapFish/v1/dowloadReport', model);
  }

  printSinottico(model: PrintSinotticoINModel): Observable<PrintSinotticoOUTModel> {
    return this.apiService.post('report/api/mapFish/v1/printSinottico', model);
  }

  insertPlanimetriaSHP(model: InsertPlanimetriaINModel): Observable<InsertPlanimetriaOUTModel> {
    return this.apiService.post('report/api/mapFish/v1/insertPlanimetriaSHP', model);
  }

  insertPlanimetriaDIS(model: InsertPlanimetriaINModel): Observable<InsertPlanimetriaOUTModel> {
    return this.apiService.post('report/api/mapFish/v1/insertPlanimetriaDIS', model);
  }

  createShapeFileZip(model: CreateShapeFileINModel): Observable<any> {
    return this.apiService.post('report/api/mapFish/v1/createShapeFileZip', model);
  }

  downloadReportSistema(model: DownloadReportSistemaINModel): Observable<any> {
    return this.apiService.post('report/api/mapFish/v1/downloadReportSistema', model);
  }

  downloadMisure(model: DownloadMisureINModel): Observable<any> {
    return this.apiService.post('report/api/mapFish/v1/downloadMisure', model);
  }

  getStatusMisure(model:GetStatusMisureINModel): Observable<any> {
    return this.apiService.post('report/api/mapFish/v1/getStatusMisure', model);
  }
}