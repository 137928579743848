import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { DeleteRelNotificheIN } from '../models/delete-rel-notifiche-in.model';
import { DeleteRelNotificheOUT } from '../models/delete-rel-notifiche-out.model';
import { InsertNotificheIn } from '../models/insert-notifiche-in.model';
import { InsertNotificheOut } from '../models/insert-notifiche-out.model';
import { LoadRelNotificheOUT } from '../models/load-rel-notifiche-out.model';
import { LoadRelNotificheIN } from '../models/load-rel-notifiche-in.model';
import { TabUsersEnableIN } from '../models/tab-users-enable-in.model';
import { TabUsersEnableOUT } from '../models/tab-users-enable-out.model';
import { LoadNotificheIN } from '../models/load-notifiche-in.model';
import { LoadNotificheOUT } from '../models/load-notifiche-out.model';
import { DeleteNotificaIN } from '../models/delete-notifica-in.model';
import { DeleteNotificaOUT } from '../models/delete-notifica-out.model';

@Injectable({
  providedIn: 'root'
})
export class SisInvioNotificheService {

  constructor(
    private apiService: ApiService
  ) { }

  deleteRelNotifiche(model: DeleteRelNotificheIN): Observable<DeleteRelNotificheOUT> {
    return this.apiService.post('project/api/v1/deleteRelNotifiche', model);
  }

  insertNotifica(model: InsertNotificheIn): Observable<InsertNotificheOut> {
    return this.apiService.post('project/api/v1/insertNotifica', model);
  }

  loadRelNotifiche(model: LoadRelNotificheIN): Observable<LoadRelNotificheOUT> {
    return this.apiService.post('project/api/v1/loadRelNotifiche', model);
  }

  loadUsersEnable(model: TabUsersEnableIN): Observable<TabUsersEnableOUT> {
    return this.apiService.post('project/api/v1/loadUsersEnable', model);
  }

  //dashboard
  loadTabNotifiche(model: LoadNotificheIN): Observable<LoadNotificheOUT> {
    return this.apiService.post('project/api/v1/loadTabNotifiche', model);
  }
  
  deleteTabNotifica(model: DeleteNotificaIN): Observable<DeleteNotificaOUT> {
    return this.apiService.post('project/api/v1/deleteTabNotifica', model);
  }

}
