import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

import { UsersComponent } from './views/pages/users/users.component';
import { Subtitle2Component } from './views/pages/subtitle2/subtitle2.component';
import { Subtitle3Component } from './views/pages/subtitle3/subtitle3.component';
import { Subtitle4Component } from './views/pages/subtitle4/subtitle4.component';

import { UserDetailComponent } from './views/pages/users/user-detail/user-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LottiGaraComponent } from './views/pages/lotti-gara/lotti-gara.component';
import { AreeComponent } from './views/pages/aree/aree.component';
import { AclrrComponent } from './views/pages/aclrr/aclrr.component';
import { ClusterComponent } from './views/pages/cluster/cluster.component';
import { ClusterComuniComponent } from './views/pages/cluster-comuni/cluster-comuni.component';
import { ProjectComponent } from './views/pages/project/project.component';
import { ProjectAddComponent } from './views/pages/project/project-add/project-add.component';
import { ProjectDetailComponent } from './views/pages/project/project-detail/project-detail.component';
import { NotificationsComponent } from './views/pages/notifications/notifications.component';
import { MapComponent } from './views/pages/map/map.component';
import { DesignMapComponent } from './views/pages/design-map/design-map.component';
import { ReportComponent } from './views/pages/report/report.component';
import { ChangePasswordComponent } from './views/pages/change-password/change-password.component';
import { PasswordExpiredComponent } from './views/pages/password-expired/password-expired.component';

const routes: Routes = [
  { path:'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'lotti-gara',
        component: LottiGaraComponent
      },
      {
        path: 'aree',
        component: AreeComponent
      },
      {
        path: 'aclrr',
        component: AclrrComponent
      },
      {
        path: 'cluster',
        component: ClusterComponent
      },
      {
        path: 'sistema-invio-notifiche',
        component: NotificationsComponent
      },
      {
        path: 'cluster-comuni',
        component: ClusterComuniComponent
      },
      {
        path: 'users/user-detail',
        component: UserDetailComponent
      },
      {
        path: 'subtitle2',
        component: Subtitle2Component
      },
      {
        path: 'subtitle3',
        component: Subtitle3Component
      },
      {
        path: 'subtitle4',
        component: Subtitle4Component
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'project',
        component: ProjectComponent
      },
      {
        path: 'project/project-detail',
        component: ProjectDetailComponent
      },
      {
        path: 'project/project-add',
        component: ProjectAddComponent
      },
      {
        path: 'map',
        component: MapComponent
      },
      {
        path: 'design-map',
        component: DesignMapComponent
      },
      {
        path: 'report',
        component: ReportComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  { 
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  {
    path: 'password-expired',
    component: PasswordExpiredComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
