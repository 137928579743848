import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

import { DeleteFileINModel } from '../models/delete-file-inmodel.model';
import { DeleteFileOUTModel } from '../models/delete-fileout-model.model';
import { DeleteFolderINModel } from '../models/delete-folder-inmodel.model';
import { DeleteFolderOUTModel } from '../models/delete-folder-outmodel.model';
import { LoadFileINModel } from '../models/load-file-inmodel.model';
import { LoadFileOUTModel } from '../models/load-file-outmodel.model';
import { LoadFolderINModel } from '../models/load-folder-inmodel.model';
import { LoadFolderOUTModel } from '../models/load-folder-outmodel.model';
import { LoadViwFolderTreeOUTModel } from '../models/load-viw-folder-tree-outmodel.model';
import { UploadFileINModel } from '../models/upload-file-inmodel.model';
import { UploadFileOUTModel } from '../models/upload-file-outmodel.model';
import { LoadListFileIN } from '../models/load-list-file-in.model';
import { LoadListFileOUT } from '../models/load-list-file-out.model';
import { LoadFotoINModel } from '../models/loadFotoIN.model';
import { LoadFotoOUTModel } from '../models/loadFotoOUT.model';




@Injectable({
  providedIn: 'root'
})
export class DmsService {

  constructor(
    private apiService: ApiService
  ) { }


  loadFolder(model: LoadFolderINModel): Observable<LoadFolderOUTModel> {
    return this.apiService.post('dms/api/v1/loadFolders', model);
  }

  loadListViwFolderTree(): Observable<LoadViwFolderTreeOUTModel> {
    return this.apiService.post('dms/api/v1/loadListViwFolderTree');
  }

  deleteFolder(model: DeleteFolderINModel): Observable<DeleteFolderOUTModel> {
    return this.apiService.post('dms/api/v1/deleteFolder', model);
  }

  loadFile(model: LoadFileINModel): Observable<LoadFileOUTModel> {
    return this.apiService.post('dms/api/v1/loadFile', model);
  }

  loadListFile(model: LoadListFileIN): Observable<LoadListFileOUT> {
    return this.apiService.post('dms/api/v1/loadListFile', model);
  }


  uploadFile(model: UploadFileINModel): Observable<UploadFileOUTModel> {
    const formData = new FormData();
    formData.append('note', model.note);
    formData.append('idProgetto', model.idProgetto + '');
    formData.append('idFolderTree', model.idFolderTree + '');
    formData.append('file', model.file);
    return this.apiService.postUpdateFile('dms/api/v1/uploadFile', formData);
  }

  loadListFoto(model: LoadFotoINModel): Observable<LoadFotoOUTModel> {
    return this.apiService.post('dms/api/v1/loadListFoto', model);
  }

  deleteFile(model: DeleteFileINModel): Observable<DeleteFileOUTModel> {
    return this.apiService.post('dms/api/v1/deleteFile', model);
  }

  downloadApk(model: LoadFileINModel): Observable<LoadFileOUTModel> {
    return this.apiService.post('dms/api/v1/downloadAPK',model);
  }


}