import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { DeleteAclrrINModel } from '../models/deleteAclrrIN.model';
import { DeleteAreeINModel } from '../models/deleteAreeIN.model';
import { DeleteClusterINModel } from '../models/deleteClusterIN.model';
import { DeleteClusterComuniINModel } from '../models/deleteClusterComuniIN.model';
import { DeleteLottiGaraINModel } from '../models/deleteLottiGaraIN.model';
import { InsertAclrrINModel } from '../models/insertAclrrIN.model';
import { InsertAclrrOUTModel } from '../models/insertAclrrOUT.model';
import { InsertAreeINModel } from '../models/insertAreeIN.model';
import { InsertAreeOUTModel } from '../models/insertAreeOUT.model';
import { InsertClusterINModel } from '../models/insertClusterIN.model';
import { InsertClusterOUTModel } from '../models/insertClusterOUT.model';
import { InsertClusterComuniINModel } from '../models/insertClusterComuniIN.model';
import { InsertClusterComuniOUTModel } from '../models/insertClusterComuniOUT.model';
import { InsertLottiGaraINModel } from '../models/insertLottiGaraIN.model';
import { InsertLottiGaraOUTModel } from '../models/insertLottiGaraOUT.model';
import { LoadAclrrByIdINModel } from '../models/loadAclrrByIdIN.model';
import { LoadAclrrOUTModel } from '../models/loadAclrrOUT.model';
import { LoadAreeByIdINModel } from '../models/loadAreeByIdIN.model';
import { LoadAreeOUTModel } from '../models/loadAreeOUT.model';
import { LoadClusterByIdINModel } from '../models/loadClusterByIdIN.model';
import { LoadClusterByIdOUTModel } from '../models/loadClusterByIdOUT.model';
import { LoadClusterComuniByIdINModel } from '../models/loadClusterComuniByIdIN.model';
import { LoadClusterComuniByIdOUTModel } from '../models/loadClusterComuniByIdOUT.model';
import { LoadListAclrrINModel } from '../models/loadListAclrrIN.model';
import { LoadListAclrrOUTModel } from '../models/loadListAclrrOUT.model';
import { LoadListAreeINModel } from '../models/loadListAreeIN.model';
import { LoadListAreeOUTModel } from '../models/loadListAreeOUT.model';
import { LoadListClusterINModel } from '../models/loadListClusterIN.model';
import { LoadListClusterOUTModel } from '../models/loadListClusterOUT.model';
import { LoadListClusterComuniINModel } from '../models/loadListClusterComuniIN.model';
import { LoadListClusterComuniOUTModel } from '../models/loadListClusterComuniOUT.model';
import { LoadListLottiGaraINModel } from '../models/loadListLottiGaraIN.model';
import { LoadListLottiGaraOUTModel } from '../models/loadListLottiGaraOUT.model';
import { LoadLottiGaraByIdINModel } from '../models/loadLottiGaraByIdIN.model';
import { LoadLottiGaraOUTModel } from '../models/loadLottiGaraOUT.model';
import { ModifyAclrrINModel } from '../models/modifyAclrrIN.model';
import { ModifyAreeINModel } from '../models/modifyAreeIN.model';
import { ModifyClusterINModel } from '../models/modifyClusterIN.model';
import { ModifyClusterComuniINModel } from '../models/modifyClusterComuniIN.model';
import { ModifyLottiGaraINModel } from '../models/modifyLottiGaraIN.model';
import { ModifyAreeOUTModel } from '../models/modifyAreeOUT.model';
import { ModifyAclrrOUTModel } from '../models/modifyAclrrOUT.model';
import { ModifyClusterOUTModel } from '../models/modifyClusterOUT.model';
import { ModifyClusterComuniOUTModel } from '../models/modifyClusterComuniOUT.model';
import { ModifyLottiGaraOUTModel } from '../models/modifyLottiGaraOUT.model';
import { LoadListComuniOUTModel } from '../models/loadListComuniOUT.model';
import { LoadArllDisponibiliINModel } from '../models/loadArllDisponibiliIN.model';
import { LoadArllDisponibiliOUTModel } from '../models/loadArllDisponibiliOUT.model';
import { LoadRegioniINModel } from '../models/loadRegioniIN.model';
import { LoadRegioniOUTModel } from '../models/loadRegioniOUT.model';
import { LoadProvincieINModel } from '../models/loadProvincieIN.model';
import { LoadProvincieOUTModel } from '../models/loadProvincieOUT.model';
import { LoadComuniINModel } from '../models/loadComuniIN.model';
import { LoadComuniOUTModel } from '../models/loadComuniOUT.model';
import { LoadAcrllLottoINModel } from '../models/loadAcrllLottoIN.model';
import { LoadAcrllLottoOUTModel } from '../models/loadAcrllLottoOUT.model';
import { LoadComuniLottoINModel } from '../models/loadComuniLottoIN.model';
import { LoadComuniLottoOUTModel } from '../models/loadComuniLottoOUT.model';
import { LoadCodLottoGaraINModel } from '../models/loadCodLottoGaraIN.model';
import { LoadCodLottoGaraOUTModel } from '../models/loadCodLottoGaraOUT.model';
import { ViwClustersListModel } from '../models/viwClustersList.model';
import { FilterClusterINModel } from '../models/filterClusterIN.model';
import { FilterClusterOUTModel } from '../models/filterClusterOUT.model';
import { LoadComuniFromClusterOUTModel } from '../models/loadComuniFromClusterOUT.model';
import { LoadComuniFromClusterINModel } from '../models/loadComuniFromClusterIN.model';
import { FilterLottiByClusterINModel } from '../models/filterLottiByClusterIN.model';
import { FilterCentraleByClusterAndLottoModel } from '../models/filterCentraleByClusterAndLotto.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private apiService: ApiService
  ) { }


  deleteAclrr(model: DeleteAclrrINModel): Observable<any> {
    return this.apiService.post('config/api/v1/deleteAclrr', model);
  }

  deleteAree(model: DeleteAreeINModel): Observable<any> {
    return this.apiService.post('config/api/v1/deleteAree', model);
  }

  deleteCluster(model: DeleteClusterINModel): Observable<any> {
    return this.apiService.post('config/api/v1/deleteCluster', model);
  }

  deleteClusterComuni(model: DeleteClusterComuniINModel): Observable<any> {
    return this.apiService.post('config/api/v1/deleteClusterComuni', model);
  }

  deleteLottiGara(model: DeleteLottiGaraINModel): Observable<any> {
    return this.apiService.post('config/api/v1/deleteLottiGara', model);
  }

  insertAclrr(model: InsertAclrrINModel): Observable<InsertAclrrOUTModel> {
    return this.apiService.post('config/api/v1/insertAclrr', model);
  }

  insertAree(model: InsertAreeINModel): Observable<InsertAreeOUTModel> {
    return this.apiService.post('config/api/v1/insertAree', model);
  }

  insertCluster(model: InsertClusterINModel): Observable<InsertClusterOUTModel> {
    return this.apiService.post('config/api/v1/insertCluster', model);
  }

  insertClusterComuni(model: InsertClusterComuniINModel): Observable<InsertClusterComuniOUTModel> {
    return this.apiService.post('config/api/v1/insertClusterComuni', model);
  }

  insertLottiGara(model: InsertLottiGaraINModel): Observable<InsertLottiGaraOUTModel> {
    return this.apiService.post('config/api/v1/insertLottiGara', model);
  }

  loadAclrrById(model: LoadAclrrByIdINModel): Observable<LoadAclrrOUTModel> {
    return this.apiService.post('config/api/v1/loadAclrrById', model);
  }

  loadAreeById(model: LoadAreeByIdINModel): Observable<LoadAreeOUTModel> {
    return this.apiService.post('config/api/v1/loadAreeById', model);
  }

  loadClusterById(model: LoadClusterByIdINModel): Observable<LoadClusterByIdOUTModel> {
    return this.apiService.post('config/api/v1/loadClusterById', model);
  }

  loadClusterComuniById(model: LoadClusterComuniByIdINModel): Observable<LoadClusterComuniByIdOUTModel> {
    return this.apiService.post('config/api/v1/loadClusterComuniById', model);
  }

  loadListAclrr(): Observable<LoadListAclrrOUTModel> {
    let model: LoadListAclrrINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListAclrr', model);
  }

  loadListAree(): Observable<LoadListAreeOUTModel> {
    let model: LoadListAreeINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListAree', model);
  }

  loadListCluster(): Observable<LoadListClusterOUTModel> {
    let model: LoadListClusterINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListCluster', model);
  }

  loadListClusterComuni(): Observable<LoadListClusterComuniOUTModel> {
    let model: LoadListClusterComuniINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListClusterComuni', model);
  }

  loadListComuni(): Observable<LoadListComuniOUTModel> {
    let model: LoadListComuniOUTModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListComuni', model);
  }

  loadListLottiGara(): Observable<LoadListLottiGaraOUTModel> {
    let model: LoadListLottiGaraINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadListLottiGara', model);
  }

  loadLottiGaraById(model: LoadLottiGaraByIdINModel): Observable<LoadLottiGaraOUTModel> {
    return this.apiService.post('config/api/v1/loadLottiGaraById', model);
  }

  modifyAclrr(model: ModifyAclrrINModel): Observable<ModifyAclrrOUTModel> {
    return this.apiService.post('config/api/v1/modifyAclrr', model);
  }

  modifyAree(model: ModifyAreeINModel): Observable<ModifyAreeOUTModel> {
    return this.apiService.post('config/api/v1/modifyAree', model);
  }

  modifyCluster(model: ModifyClusterINModel): Observable<ModifyClusterOUTModel> {
    return this.apiService.post('config/api/v1/modifyCluster', model);
  }

  modifyClusterComuni(model: ModifyClusterComuniINModel): Observable<ModifyClusterComuniOUTModel> {
    return this.apiService.post('config/api/v1/modifyClusterComuni', model);
  }

  modifyLottiGara(model: ModifyLottiGaraINModel): Observable<ModifyLottiGaraOUTModel> {
    return this.apiService.post('config/api/v1/modifyLottiGara', model);
  }

  loadClusterByIdComune(idComune: string): Observable<ViwClustersListModel> {
    var model = new FilterClusterINModel();
    model.idComune = idComune
    return this.apiService.post('config/api/v1/loadClusterByIdComune', model);
  }

  /*loadAclrrClusterById(idCluster: number): Observable<ViwClustersListModel> {
   var model = new loadAclrrClusterINModel();
   model.idCluster = idCluster
   return this.apiService.post('config/api/v1/loadAclrrClusterById', model);
 }*/

  loadViwAclrrDisponibili(): Observable<LoadArllDisponibiliOUTModel> {
    let model: LoadArllDisponibiliINModel;
    let body: Object = {};
    return this.apiService.post('config/api/v1/loadViwAclrrDisponibili', model);
  }

  loadViwRegioni(): Observable<LoadRegioniOUTModel> {
    let model: LoadRegioniINModel
    return this.apiService.post('config/api/v1/loadViwRegioni', model);
  }

  loadViwProvincie(model: LoadProvincieINModel): Observable<LoadProvincieOUTModel> {
    return this.apiService.post('config/api/v1/loadViwProvincie', model);
  }

  loadViwComuni(model: LoadComuniINModel): Observable<LoadComuniOUTModel> {
    return this.apiService.post('config/api/v1/loadViwComuni', model);
  }

  loadAclrrByIdLottoGara(model: LoadAcrllLottoINModel): Observable<LoadAcrllLottoOUTModel> {
    return this.apiService.post('config/api/v1/loadAclrrByIdLottoGara', model);
  }

  loadComuniByIdLottoGara(model: LoadComuniLottoINModel): Observable<LoadComuniLottoOUTModel> {
    return this.apiService.post('config/api/v1/loadComuniByIdLottoGara', model);
  }

  loadLottoGaraByCode(model: LoadCodLottoGaraINModel): Observable<LoadCodLottoGaraOUTModel> {
    return this.apiService.post('config/api/v1/loadLottoGaraByCode', model);
  }

  loadAclrrClusterById(model: FilterClusterINModel): Observable<FilterClusterOUTModel> {
    return this.apiService.post('config/api/v1/loadAclrrClusterById', model);
  }

  loadComuniFromCluster(): Observable<LoadComuniFromClusterOUTModel> {
    let model: LoadComuniFromClusterINModel
    return this.apiService.post('config/api/v1/loadComuniFromCluster', model);
  }

  filterLottiByCluster(model:FilterLottiByClusterINModel): Observable<any> {
    return this.apiService.post('config/api/filter/v1/filterLottiByCluster', model);
  }

  filterCentraleByClusterAndLotto(model:FilterCentraleByClusterAndLottoModel): Observable<any> {
    return this.apiService.post('config/api/filter/v1/filterCentraleByClusterAndLotto', model);
  }
}