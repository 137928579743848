import SimpleGeometry from "ol/geom/SimpleGeometry"

export class Geometry {
    coordinates: any[]
    type: string
}

export class Feature {
    type: string
    properties: any
    geometry: Geometry
}

export class GeoJson {
    type: 'FeatureCollection'
    features: Feature[]
    parentUid: number
}

export class GeometryParams {
    idLayer: number
    idProgetto: number
    parentUid: number
}



export class SelectGeometryParams extends GeometryParams {
    idSchedaProgetto: number
}

//RelSchedaProgettoLayerModel to BE
export class RelModifyGeometryParams {
    flagConvalidato: boolean
    flagEsistente: boolean
    idLayer: number
    idProgetto: number
    idSchedaProgetto: number
    geometry: Geometry
    length: number
    latitude: number
    longitude: number
}



export class Links { //  // passato in caso di point  con dettaglio delle feature linestring che hanno come source/target il punto disegnato
    feature: Feature // nuova feature spezzata
    oldFeatureUid: number // uid della feature GeoJson che verrà spezzata (eliminata)
    type: string // definisce se source o target nel punto disegnato (SOURCE || TARGET)
    length: number // definisce lunghezza della nuova feature quando spezziamo 
}

export class SplitParams extends GeometryParams {
    links: Links[]
    uid: number //id della nuova feature disegnata (uid del punto in caso di spezza oppure uid della linea in caso di unisci)
    idLayer: number
    idProgetto: number
}

export class ConnectParams extends GeometryParams {
    source: string // passato in caso di linestring con dettaglio della feature puntuale a cui la linestring è collegata come source
    target: string  // passato in caso di linestring con dettaglio della feature puntuale a cui la linestring è collegata come target
    uid: number//id della nuova feature disegnata (uid del punto in caso di spezza oppure uid della linea in caso di unisci)
    idLayer: number
    idProgetto: number
}

export class LineLinks {
    uid: number
    links: Links[]
    coordinates: number[]
}

export class EditPointParams extends GeometryParams {
    feature: RelModifyGeometryParams;
    links: Links[]
}

export class EditLineParams extends GeometryParams {
    feature: RelModifyGeometryParams;
    source: LineLinks
    target: LineLinks
}

export class InsertGeometryParams extends GeometryParams {
    feature: RelModifyGeometryParams;
    parentUid: number;
}

export class ModifyGeometryParams extends GeometryParams {
    feature: RelModifyGeometryParams;
}

export class ShortestPath {
    idProgetto: number
    source: number
    target: number
    listPoint: number[]
}

export class EditCavo {
    idProgetto: number
    idLayer: number
    cavo: Cavo
}

export class Cavo {
    uid: number
    name: string
    source: number
    target: number
    listPath: number[]
    weight: number
}

export class LoadCavi extends GeometryParams{
    uid: number
}


// export class LabelProperty{
//  labels:[ { text:string, color:string }] // 1 o N (n elementi quando l'entità presenta più label (es infrastruttura))
//  labelStyle : {
//     rotation : null || number
//     offsetX : null || string
//     offsetY : null || string
//     size : null || string
//  } 
// }


// labels: [ 
//     {text:"87/48/485" , color:"#432424" },
//     {text:"87/48/485" , color:"#432424" }, 
//     {text:"87/48/485" , color:"#432424" }, 
//     {text:"87/48/485" , color:"#432424" }, 
//     ] 

// labelStyle : {
//     rotation: 45,
//     offsetX: 30,
//     offsetY: -30,
//     size: 10px
// }