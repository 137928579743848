import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from '../../../service/project.service';
import { ConfigService } from '../../../service/config.service';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { TabLottiGaraModel } from 'src/app/models/tabLottiGara.model';
import { TabClusterComuniModel } from 'src/app/models/tabClusterComuni.model';
import { ViwStati } from 'src/app/models/viw-stati.model';
import { HttpErrorResponse } from '@angular/common/http';
import { RelProgettoStatiIN } from 'src/app/models/rel-progetto-stati-in.model';
import { ViwAclrrDisponibiliListModel } from 'src/app/models/viwAclrrDisponibiliList.model';
import { ViwProjectModel } from 'src/app/models/viwProject.model';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { ReportService } from 'src/app/service/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  spinner = false;
  message: string = null;
  loadingIndicator = true;

  clusterForm: FormGroup;

  listLottiGara: any[] = []
  listClusterComuni: TabClusterComuniModel[] = [];
  viwStati: ViwStati[] = []
  updateStato = new RelProgettoStatiIN()
  viwAclrrDisponibiliList: ViwAclrrDisponibiliListModel[] = []

  activeLotto: boolean = false
  activeAclrr: boolean = false

  listIdClusterComuni: number[] = []
  listIdLottoGara: number[] = []
  listIdStatoProgetto: number[] = []
  listIdAclrr: number[] = []

  constructor(private router: Router,
    private projectService: ProjectService,
    private modalService: NgbModal,
    private configService: ConfigService,
    private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.clusterForm = new FormGroup({
      idClusterComuni: new FormControl(null),
      listStati: new FormControl(null),
      idLottoGara: new FormControl(null),
      idAclrr: new FormControl(null)
    })

    this.loadClusterComuni()
    this.loadStatiProgetto()
  }

  loadClusterComuni() {
    this.spinner = true
    this.message = ''

    this.configService.loadListClusterComuni().subscribe((res) => {
      this.spinner = false
      this.listClusterComuni = res.listTabClusterComuni;
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  loadStatiProgetto() {
    this.spinner = true
    this.message = ''

    this.projectService.loadViwStati().subscribe((res) => {
      this.spinner = false
      this.viwStati = res.listViwStati
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  selectClusterComuni(clusterComuni) {
    if (clusterComuni.length) {
      this.activeLotto = true
      var arrayIdTemp: number[] = []
      for (var idCluster = 0; idCluster < clusterComuni.length; idCluster++) {
        arrayIdTemp.push(clusterComuni[idCluster].idClusterComuni);
      }
      this.listIdClusterComuni = arrayIdTemp
      this.filterLottiByCluster(this.listIdClusterComuni)
    }
    if (!clusterComuni.length) {
      this.activeLotto = false
      this.activeAclrr = false
      this.listIdClusterComuni = []
      this.listIdLottoGara = []
      this.listIdAclrr = []
    }
  }

  selectLottoGara(lotto) {
    if (lotto.length) {
      this.activeAclrr = true
      var arrayIdTemp: number[] = []
      for (var idLotto = 0; idLotto < lotto.length; idLotto++) {
        arrayIdTemp.push(lotto[idLotto].idLottoGara);
      }
      this.listIdLottoGara = arrayIdTemp
      this.filterCentraleByClusterAndLotto(this.listIdClusterComuni, this.listIdLottoGara)
    }
    if (!lotto.length) {
      this.activeAclrr = false
      this.listIdLottoGara = []
      this.listIdAclrr = []
    }
  }

  selectStatoProgetto(statoProgetto) {
    if (statoProgetto.length) {
      var arrayIdTemp: number[] = []
      for (var idStato = 0; idStato < statoProgetto.length; idStato++) {
        arrayIdTemp.push(statoProgetto[idStato].idTabStatiProject);
      }
      this.listIdStatoProgetto = arrayIdTemp
    }
    if (!statoProgetto.length) {
      this.listIdStatoProgetto = []
    }
    console.log("STATO PROGETTO::", this.listIdStatoProgetto)
  }

  selectAclrr(aclrr) {
    if (aclrr.length) {
      var arrayIdTemp: number[] = []
      for (var idAclrr = 0; idAclrr < aclrr.length; idAclrr++) {
        arrayIdTemp.push(aclrr[idAclrr].idAclrr);
      }
      this.listIdAclrr = arrayIdTemp
    }
    if (!aclrr.length) {
      this.listIdAclrr = []
    }
    console.log("CENTRALE ACLRR::", this.listIdAclrr)
  }

  filterLottiByCluster(listIdClusterComuni) {
    console.log("LISTA ID CLUSTER COMUNI::", listIdClusterComuni)
    let FilterLottiByClusterINModel = {
      listIdClusterComuni: listIdClusterComuni
    }
    this.configService.filterLottiByCluster(FilterLottiByClusterINModel).subscribe((res) => {
      this.spinner = false
      this.listLottiGara = res
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  filterCentraleByClusterAndLotto(listIdClusterComuni, listIdLottoGara) {
    console.log("LISTA LOTTO GARA::", this.listIdLottoGara)
    let FilterCentraleByClusterAndLottoModel = {
      listIdClusterComuni: listIdClusterComuni,
      listIdLottiGara: listIdLottoGara
    }
    this.configService.filterCentraleByClusterAndLotto(FilterCentraleByClusterAndLottoModel).subscribe((res) => {
      this.spinner = false
      this.viwAclrrDisponibiliList = res
    }, (err: HttpErrorResponse) => {
      this.spinner = false
      this.messageBox('error', JSON.stringify(err))
    });
  }

  downloadReport() {
    this.spinner = true
    let downloadReportSistemaINModel = {
      centraleList: this.listIdAclrr,
      lottiGaraList: this.listIdLottoGara,
      idClusterComuniList: this.listIdClusterComuni,
      statoLavorazioneList: this.listIdStatoProgetto
    }
    this.reportService.downloadReportSistema(downloadReportSistemaINModel).subscribe((data) => {
      const contentType = "application/octet-stream";
      const b64Data = data.resource;
      const byteCharacters = atob(b64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      var a = document.createElement("a")
      a.href = URL.createObjectURL(new Blob([byteArray], { type: contentType }));
      a.download = "Reportistica" + '.xlsx';
      // start download
      a.click();
      this.spinner = false
    }, (err: HttpErrorResponse) => {
      this.messageBox('error', err)
      this.spinner = false
    });
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
  }
}
