import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../service/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.scss']
})
export class ProjectAddComponent implements OnInit {
  spinner: boolean = false;
  msgs = false;
  message: string = null;
  projectModalCloseResult: string = '';

  constructor(private router: Router, private userService: UserService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
   
  }
 
  addProject() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    
  }

  openEditProjectModal(content) {
    this.modalService.open(content, {}).result.then((result) => {
      this.projectModalCloseResult = "Modal closed" + result
      if (result == 'by: save button') {
        this.addProject()
      } 
    }).catch((res) => { });      
  } 
}


