import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router"
import { ConfigService } from './../../../service/config.service';
import { TabClusterModel } from '../../../models/tabCluster.model';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteClusterINModel } from '../../../models/deleteClusterIN.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ListViwClusterModel } from '../../../models/listViwCluster.model';
import { TabAclrrModel } from '../../../models/tabAclrr.model';
import { TabAreeModel } from '../../../models/tabAree.model';
import { TabClusterComuniModel } from '../../../models/tabClusterComuni.model';
import { TabLottiGaraModel } from 'src/app/models/tabLottiGara.model';
import { ListTabComuniModel } from '../../../models/listTabComuni.model';
import { forkJoin } from 'rxjs';
import { ViwLottiAclrrModel } from 'src/app/models/viwAclrrList.model';
import { ViwLottiComuniModel } from 'src/app/models/viwLottiComuni.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss']
})
export class ClusterComponent implements OnInit {
  listViwClusterModel: ListViwClusterModel[] = [];
  clusterModel: TabClusterModel[] = [];
  clusterModalCloseResult: string = ''
  clusterEdit: TabClusterModel = new TabClusterModel();

  checkEdit: boolean = true;
  selectLotto: boolean = false;

  rows = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  spinner = false;
  msgs = false;
  message: string = null;

  clusterForm: FormGroup;
  clusterFormEdit: FormGroup;

  insertClusterINModel = {
    tabCluster: {
      idAclrr: undefined,
      idArea: undefined,
      idCluster: undefined,
      idClusterComuni: undefined,
      idComune: undefined,
      idLottoGara: undefined
    }
  }
  deleteClusterINModel = new DeleteClusterINModel()

  modifyClusterINModel = {
    tabCluster: {
      idAclrr: undefined,
      idArea: undefined,
      idCluster: undefined,
      idClusterComuni: undefined,
      idComune: undefined,
      idLottoGara: undefined
    }
  }

  listAclrr: TabAclrrModel[] = []
  listAree: TabAreeModel[] = []
  listClusterComuni: TabClusterComuniModel[] = []
  listLottiGara: TabLottiGaraModel[] = []
  listTabComuniModel: ListTabComuniModel[] = []

  selectIdAclrr: number
  selectIdAree: number
  selectDesAree: string
  selectIdClusterComuni: number
  selectIdLottiGara: number
  selectIdTabComuniModel: string = ''

  viwAclrrListLotto: ViwLottiAclrrModel[] = []
  viwComuniListLotto: ViwLottiComuniModel[] = []

  constructor(private router: Router, private configService: ConfigService, private modalService: NgbModal) {
    /*  this.fetch(data => {
        this.rows = data;
      });*/
  }

  ngOnInit(): void {
    this.clusterForm = new FormGroup({
      idAclrr: new FormControl(null, [Validators.required]),
      idArea: new FormControl(null),
      idClusterComuni: new FormControl(null, [Validators.required]),
      idComune: new FormControl(null, [Validators.required]),
      idLottoGara: new FormControl(null, [Validators.required]),
    })
    this.clusterFormEdit = new FormGroup({
      idAclrr: new FormControl(null, [Validators.required]),
      idArea: new FormControl(null),
      idClusterComuni: new FormControl(null, [Validators.required]),
      idComune: new FormControl(null, [Validators.required]),
      idLottoGara: new FormControl(null, [Validators.required]),
    })
    this.getAllData()
  }

  getAllData() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    let listLottiGara = this.configService.loadListLottiGara();
    let clusterList = this.configService.loadListCluster();
    let listClusterComuni = this.configService.loadListClusterComuni();
    //let areeList = this.configService.loadListAree();

    forkJoin(clusterList).subscribe(response => {
      this.spinner = false
      this.loadingIndicator = false;
      if (response[0] != null) {
        let res = response[0];
        this.listViwClusterModel = res.listViwCluster;
        this.rows = res.listViwCluster;
        console.log("res:: " + JSON.stringify(this.listViwClusterModel))
      }
    });

    forkJoin(listClusterComuni, listLottiGara).subscribe(response => {
      this.spinner = false
      this.loadingIndicator = false;

      /*if (response[0] != null) {
        let res = response[0];
        this.listAree = res.listTabAree;
      }*/
      if (response[0] != null) {
        let res = response[0];
        this.listClusterComuni = res.listTabClusterComuni;
      }
      if (response[1] != null) {
        let res = response[1];
        this.listLottiGara = res.listTabLottiGara;
      }

    }, error => {
      this.msgs = true
      this.message = "Si e verificato un errore, nessun cluster puo essere aggiunto o modificato"
    });
  }

  fetch(cb) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.configService.loadListCluster().subscribe((res) => {
      this.spinner = false
      this.listViwClusterModel = res.listViwCluster;
      this.rows = res.listViwCluster;
      this.loadingIndicator = false;

    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  selectLottoGara(idLottoGara) {
    this.spinner = true
    this.msgs = false
    this.message = ''
    this.selectIdAclrr = null
    this.selectIdTabComuniModel = null

    let loadAcrllLottoIN = {
      idLottoGara: idLottoGara
    }
    this.configService.loadAclrrByIdLottoGara(loadAcrllLottoIN).subscribe((res) => {
      this.spinner = false
      this.selectLotto = true;
      this.viwAclrrListLotto = res.viwAcrllList
    //  console.log("LISTA ACLRR::", this.viwAclrrListLotto)
    }, (err: HttpErrorResponse) => {
      this.selectLotto = false;
    });

    let loadComuniLottoIN = {
      idLottoGara: idLottoGara
    }
    this.configService.loadComuniByIdLottoGara(loadComuniLottoIN).subscribe((res) => {
      this.spinner = false
      this.selectLotto = true;
      this.viwComuniListLotto = res.viwComuniList
    }, (err: HttpErrorResponse) => {
      this.selectLotto = false;
      this.messageBox('error', JSON.stringify(err))
    });
  }

  selectAclrr(select) {
    for (let i of this.viwAclrrListLotto) {
      if (i.idAclrr == select) {
        this.selectIdAree = i.idArea
        this.selectDesAree = i.desArea
    //    console.log("SELECT::", this.selectDesAree)
      }
    }
  }

  addCluster() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.insertClusterINModel.tabCluster.idAclrr = this.selectIdAclrr
    this.insertClusterINModel.tabCluster.idArea = this.selectIdAree
    this.insertClusterINModel.tabCluster.idClusterComuni = this.selectIdClusterComuni
    this.insertClusterINModel.tabCluster.idComune = this.selectIdTabComuniModel
    this.insertClusterINModel.tabCluster.idLottoGara = this.selectIdLottiGara
    console.log("INSERT", JSON.stringify(this.insertClusterINModel))

    this.configService.insertCluster(this.insertClusterINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Cluster creato correttamente"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });

    this.selectIdAclrr = null
    this.selectIdAree = null
    this.selectIdTabComuniModel = null
    this.selectIdLottiGara = null
    this.selectIdClusterComuni = null
    this.selectLotto = false;
  }

  deleteCluster(modelDelete: TabClusterModel, index: number) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.deleteClusterINModel.idCluster = modelDelete.idCluster

    this.configService.deleteCluster(this.deleteClusterINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Cluster eliminato con successo"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  editCluster() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.modifyClusterINModel.tabCluster.idAclrr = this.clusterEdit.idAclrr
    this.modifyClusterINModel.tabCluster.idArea = this.clusterEdit.idArea
    this.modifyClusterINModel.tabCluster.idClusterComuni = this.clusterEdit.idClusterComuni
    this.modifyClusterINModel.tabCluster.idCluster = this.clusterEdit.idCluster
    this.modifyClusterINModel.tabCluster.idLottoGara = this.clusterEdit.idLottoGara
    this.modifyClusterINModel.tabCluster.idComune = this.clusterEdit.idComune
    console.log("EDIT", this.modifyClusterINModel)

    this.configService.modifyCluster(this.modifyClusterINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Modifica avvenuta correttamente"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
    
    this.selectDesAree = null
    this.selectIdAree = null
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    console.log("CERCA", JSON.stringify(this.listViwClusterModel))
    const temp = this.listViwClusterModel.filter(function (d) {
      return (d.desClusterComuni.toLowerCase().indexOf(val) !== -1 ||
        d.nomeComune.toLowerCase().indexOf(val) !== -1 ||
        d.desLottoGara.toLowerCase().indexOf(val) !== -1 ||
        d.desAdc.toLowerCase().indexOf(val) !== -1) || !val;
    });
    this.rows = temp;
  }

  openAddClusterModal(content) {
    this.modalService.open(content, {}).result.then((result) => {
      this.clusterModalCloseResult = "Modal closed" + result

      if (result == "by: save button") {
        this.addCluster()
        this.clusterForm.reset();
      } else {
        this.clusterForm.reset();
        this.selectIdAclrr = null
        this.selectIdAree = null
        this.selectIdTabComuniModel = null
        this.selectIdLottiGara = null
        this.selectIdClusterComuni = null
        this.selectLotto = false;
      }
    }).catch((res) => { });
  }

  openDeleteClusterModal(content, indexRow, rowSelected) {
    this.modalService.open(content, {}).result.then((result) => {
      this.clusterModalCloseResult = "Modal closed" + result
      if (result == "by: delete button") {
        this.deleteCluster(rowSelected, indexRow);
      }
    }).catch((res) => { });
  }

  openEditClusterModal(content, rowSelected, indexRow) {
    this.callBackground();
    this.selectLottoGara(rowSelected.idLottoGara);
    this.clusterEdit = rowSelected
    this.selectDesAree = rowSelected.desArea
    this.selectIdAree = rowSelected.idArea
    console.log("ROW SELECTED EDIT::", this.clusterEdit)

    this.modalService.open(content, {}).result.then((result) => {
      this.clusterModalCloseResult = "Modal closed" + result
      if (result == "by: edit button") {
        this.checkEdit = false
        this.editCluster()
      } else {
        this.selectDesAree = null
        this.selectIdAree = null
      }
    }).catch((res) => { });
  }

  callBackground() {
    this.checkEdit = true
    this.configService.loadListCluster().subscribe((res) => {
      if (this.checkEdit) {
        this.listViwClusterModel = res.listViwCluster;
        this.rows = res.listViwCluster;
      }
    }, (err: HttpErrorResponse) => { 
      this.messageBox('error', JSON.stringify(err))
    });
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
  }
}
