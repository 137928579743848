import { Injectable, Input } from '@angular/core';
import { Text, Fill, Stroke, Circle, Style, Icon, RegularShape } from 'ol/style';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import MultiPoint from 'ol/geom/MultiPoint';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import Geometry from 'ol/geom/Geometry';

@Injectable({
  providedIn: 'root'
})
export class MapStyleService {

  styleMap: Map<String, any>;
  pathIcon: string = 'assets/imgMap/'
  white = [255, 255, 255, 1];
  yellowSelectFill = [255, 255, 0, 0.4];
  yellowSelectStroke = [255, 255, 0, 1];
  greenRouteStroke = [0, 255, 0, 1];

  highlightStyle = new Style({
    fill: new Fill({
      color: this.yellowSelectFill,
    }),
    stroke: new Stroke({
      color: this.yellowSelectStroke,
      width: 3,
    }),
  });

  constructor(private httpClient: HttpClient, private apiService: ApiService) {

    this.styleMap = new Map<String, any>();

    this.styleMap.set('areaArmadio',
      [
        new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: '#000066',
            width: 3,
            lineDash: [5, 5, 5, 5]
          })
        }),
        new Style({
          image: new Circle({
            radius: 5,
            fill: new Fill({
              color: 'orange',
            }),
          }),
          geometry: function (feature) {
            var coordinates: any
            coordinates = feature.getGeometry()
            return new MultiPoint(coordinates.getCoordinates()[0]);
          },
        }),
      ]);
  }

  getStyleById(id: String) {
    return this.styleMap.get(id)
  }

  getStyleIconLayer(iconPath: string, scale: number, labelObj: any, highlight?: boolean) {
    const confFontSize = labelObj.confFontSize ? labelObj.confFontSize : 11
    const confOffsetX = labelObj.confOffsetX ? labelObj.confOffsetX : 0
    const confOffsetY = labelObj.confOffsetY ? labelObj.confOffsetY : -10
    const confRotation = labelObj.confRotation ? labelObj.confRotation : 0

    const labelFont = 'bold ' + confFontSize + 'px Arial'
    const labelTextBaseline = 'bottom'

    const labels = {} as any
    labels.color = labelObj?.labels?.labels?.color ? labelObj.labels.labels.color : ''
    labels.text = labelObj?.labels?.labels?.text ? labelObj.labels.labels.text : ''

    let styleHighlight = null
    if (highlight) {
      var img = new Image();
      img.src = iconPath
      styleHighlight = this.canvasBorderStyle(img)
    }

    let styles = [new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: scale,
        //size: [12, 12],
        //imgSize: [12, 12],
        src: iconPath
      })
    }),
    // Label con configurazioni opzionali opzionali da feature
    new Style({
      text: new Text({
        rotation: (confRotation * Math.PI) / 180.0,
        textBaseline: labelTextBaseline,
        offsetX: confOffsetX,
        offsetY: confOffsetY,
        font: labelFont,
        text: labels.text.replaceAll(",", "\n"),
        fill: new Fill({
          color: labels.color
        })
      })
    })
    ];

    if (highlight) {
      styles = [styleHighlight].concat(styles)
    }

    return styles
  }

  getStyleIconServiceLayer(iconPath: string) {
    return new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        //size: [12, 12],
        //imgSize: [12, 12],
        src: iconPath
      })
    });
  }

  getStyleConnectionLayer() {
    return new Style({
      image: new Circle({
        radius: 7,
        stroke: new Stroke({
          color: 'red',
          width: 2,
          //lineDash: [2, 2]
        }),
        fill: new Fill({
          color: 'rgba(255, 0, 0, 0.1)',
        }),
      }),
    })
  }


  getStyleLineLayer(styleObj: any, labelObj?: any) {

    const confFontSize = labelObj.confFontSize ? labelObj.confFontSize : 9
    const confOffsetX = labelObj.confOffsetX ? labelObj.confOffsetX : 0
    const confOffsetY = labelObj.confOffsetY ? labelObj.confOffsetY : 0
    const confRotation = labelObj.confRotation ? labelObj.confRotation : 0

    //formula per piazzare la label al di sopra di quelle default
    const offsetYUpper = labelObj?.labels?.labels?.text ? labelObj?.labels?.labels?.text.split(",").length * (confFontSize + 2) * -1 + confOffsetY : confOffsetY

    const labelFont = 'bold ' + confFontSize + 'px Arial'
    const labelTextBaseline = 'bottom'

    const labels = {} as any
    labels.color = labelObj?.labels?.labels?.color ? labelObj.labels.labels.color : ''
    labels.text = labelObj?.labels?.labels?.text ? labelObj.labels.labels.text : ''

    const upperLabels = {} as any
    upperLabels.color = labelObj?.labels?.upperLabels?.color ? labelObj.labels.upperLabels.color : ''
    upperLabels.text = labelObj?.labels?.upperLabels?.text ? labelObj.labels.upperLabels.text : ''

    return [
      // Stroke da proprietà icon della feature  
      new Style({
        stroke: new Stroke(styleObj),
      }),

      //Stile per evidenziare i punti di una linea
      new Style({
        image: new Circle({
          radius: 4,
          fill: new Fill({
            color: styleObj.color,
          }),
        }),
        geometry: function (feature) {
          let geometry: any
          geometry = feature.getGeometry()
          let coordinates = geometry.getCoordinates()
          return new MultiPoint(coordinates);
        },
      }),

      //Label "non connessa" se l'infrastruttura ha source o target non connessi a punti
      new Style({
        text: new Text({
          text: 'Non connessa',
          fill: new Fill({
            color: 'red'
          })
        }),
        geometry: function (feature) {
          //console.log('style -->',feature.get('uid'),feature.get('source'),feature.get('target'))
          if (feature.get('source') == null || feature.get('target') == null)
            return feature.getGeometry()
        },
      }),

      // Style di evidenza per lo split
      new Style({
        //stroke: new Stroke({...styleObj, width:5}),
        stroke: new Stroke({
          width: 6,
          color: this.yellowSelectStroke
        }),
        geometry: function (feature) {
          if (feature.get('toSplit') || feature.get('toEdit') || feature.get('highlight'))
            return feature.getGeometry()
        },
      }),

      // Style verde  di evidenza per il routing
      new Style({
        stroke: new Stroke({
          width: 6,
          color: this.greenRouteStroke
        }),
        geometry: function (feature) {
          if (feature.get('inRoute'))
            return feature.getGeometry()
        },
      }),

      // Style verde  di evidenza per il graphic validation
      new Style({
        stroke: new Stroke({
          width: 6,
          color: this.greenRouteStroke
        }),
        geometry: function (feature) {
          if (feature.get('graphicValidation'))
            return feature.getGeometry()
        },
      }),

      // Label con configurazioni opzionali opzionali da feature
      new Style({
        text: new Text({
          rotation: (confRotation * Math.PI) / 180.0,
          textBaseline: labelTextBaseline,
          offsetX: confOffsetX,
          offsetY: confOffsetY,
          font: labelFont,
          text: labels.text.replaceAll(",", "\n"),
          fill: new Fill({
            color: labels.color
          })
        })
      }),

      // Label upper con offset verticale e configurazioni opzionali da feature
      new Style({
        text: new Text({
          rotation: (confRotation * Math.PI) / 180.0,
          textBaseline: labelTextBaseline,
          offsetX: confOffsetX,
          offsetY: offsetYUpper,
          font: labelFont,
          text: upperLabels.text.replaceAll(",", "\n"),
          fill: new Fill({
            color: upperLabels.color
          })
        }),
        geometry: function (feature) {
          if (feature.get('labels'))
            return feature.getGeometry()
        },
      })
    ]
  }

  ///#### Refactoring
  getStyleFunction(idLayerInfr: number, scale: number) {
    var _this = this
    return function (feature: any, resolution: any) {
      let labelObj = {
        confFontSize: feature.get('confFontSize'),
        confOffsetX: feature.get('confOffsetX'),
        confOffsetY: feature.get('confOffsetY'),
        confRotation: feature.get('confRotation'),
        labels: feature.get('labels'),
        //upperLabels: feature.get('upperLabels')
      }
      if (feature.get('idLayer') == idLayerInfr) {
        return _this.getStyleLineLayer(JSON.parse(feature.get('icon')), labelObj)
      }
      return _this.getStyleIconLayer(_this.pathIcon + feature.get('icon'), scale, labelObj, feature.get("highlight"))
    }
  }

  getSelectHighlightStyle(feature: Feature<any>, idLayerInfr: number) {
    let highlightStyle: Style[] = null;
    let labelObj = {
      confFontSize: feature.get('confFontSize'),
      confOffsetX: feature.get('confOffsetX'),
      confOffsetY: feature.get('confOffsetY'),
      confRotation: feature.get('confRotation'),
      labels: feature.get('labels'),
      //upperLabels: feature.get('upperLabels')
    }
    if (feature.get('idLayer') == idLayerInfr) {
      highlightStyle = this.getStyleLineLayer(JSON.parse(feature.get('icon')), labelObj)
      highlightStyle.map(lightStyle => {
        lightStyle.getStroke()?.setColor(this.yellowSelectStroke);
      })
    } else {
      return feature.getStyle();
    }

    return highlightStyle;
  }

  getSearchArmadioStyle() {
    return new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 0.8,
        //size: [12, 12],
        //imgSize: [12, 12],
        src: "assets/images/red.png",
      })
    });
  }

  getLayersStyle(layer, idLayerInfrastrutture) {
    if (layer.flagVirtual) {
      return this.getStyleIconServiceLayer('assets/images/blue.png')
    } else if (layer.flagDisallineamenti) {
      return this.getStyleFunction(idLayerInfrastrutture, 0.6)
    } else if (layer.flagConnessione) {
      return this.getStyleConnectionLayer()
    } else {
      return this.getStyleFunction(idLayerInfrastrutture, 0.35)
    }
  }

  private canvasBorderStyle(image) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var activeColor = 'yellow'; //set border color
    var dArr = [-1, -1, 0, -1, 1, -1, -1, 0, 1, 0, -1, 1, 0, 1, 1, 1], // offset array
      s = 10, // thickness scale
      i = 0,  // iterator
      x = 10,  // final x position
      y = 10;  // final y position

    //set new canvas dimentions adjusted for border
    canvas.width = image.width + s + s;
    canvas.height = image.height + s + s;

    // draw images at offsets from the array scaled by s
    for (; i < dArr.length; i += 2)
      ctx.drawImage(image, x + dArr[i] * s, y + dArr[i + 1] * s);

    // fill with color
    ctx.globalCompositeOperation = "source-in";
    ctx.fillStyle = activeColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // draw original image in normal mode
    ctx.globalCompositeOperation = "source-over";
    ctx.drawImage(image, x, y, image.width, image.height);

    console.log(canvas)
    //create new openlayers icon style from canvas
    return new Style({
      image: new Icon({
        src: undefined,
        scale: 0.35,
        img: canvas,
        imgSize: canvas ? [canvas.width, canvas.height] : undefined
      })
    });
  }

}
