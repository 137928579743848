import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router"
import { ConfigService } from './../../../service/config.service';

import { ColumnMode } from '@swimlane/ngx-datatable';
import { TabAreeModel } from '../../../models/tabAree.model';
import { HttpBackend, HttpErrorResponse, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { InsertAreeINModel } from '../../../models/insertAreeIN.model';
import { DeleteAreeINModel } from '../../../models/deleteAreeIN.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModifyAreeINModel } from '../../../models/modifyAreeIN.model';
import { ViwAreeModel } from '../../../models/ViwAree.model';
import Swal from 'sweetalert2';

export interface Aree {
  desArea: string
  idArea: number
  numFdv: number
}

@Component({
  selector: 'app-aree',
  templateUrl: './aree.component.html',
  styleUrls: ['./aree.component.scss']
})
export class AreeComponent implements OnInit {

  areeModel: TabAreeModel[] = [];
  areeModalCloseResult: string = '';
  aree: Aree = {
    desArea: undefined,
    idArea: undefined,
    numFdv: undefined
  }

  rows = [];
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  areeEdit: ViwAreeModel = new ViwAreeModel();

  checkEdit: boolean = true;

  spinner = false;
  msgs = false;
  message: string = null;
  valueSearch: string = '';

  areeForm: FormGroup;
  areeFormEdit: FormGroup;

  insertAreeModel = new InsertAreeINModel();
  deleteAreeINModel = new DeleteAreeINModel();

  constructor(private router: Router, private configService: ConfigService, private modalService: NgbModal) {
    this.fetch(data => {
      this.rows = data;
    });
  }

  ngOnInit(): void {
    this.areeForm = new FormGroup({
      desArea: new FormControl(null, [Validators.required, Validators.maxLength(1),
      Validators.pattern("^[A-Z]*$")]),
      numFdv: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(10),
      Validators.pattern(/^[0-9]\d*(\.\d+)?$/)]),
    })
    this.areeFormEdit = new FormGroup({
      desArea: new FormControl(null, [Validators.required, Validators.maxLength(1),
      Validators.pattern("^[A-Z]*$")]),
      numFdv: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(10),
      Validators.pattern(/^[0-9]\d*(\.\d+)?$/)]),
    })
  }

  fetch(cb) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.configService.loadListAree().subscribe((res) => {
      this.spinner = false
      this.areeModel = res.listTabAree;
      this.rows = this.areeModel
      //  console.log("res:: " + JSON.stringify(this.areeModel))
      this.loadingIndicator = false;
    }, (err: HttpErrorResponse) => {
      console.log("error: " + err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  addAree() {
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.insertAreeModel.tabAree = this.aree
    // console.log("INSERT", this.insertAreeModel)

    this.configService.insertAree(this.insertAreeModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Area creata correttamente"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log("error: " + JSON.stringify(err))
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  deleteAree(modelDelete: TabAreeModel, index: number) {//DeleteAreeINModel
    this.spinner = true
    this.msgs = false
    this.message = ''

    this.deleteAreeINModel.idAree = modelDelete.idArea
  //  console.log("DELETE", this.deleteAreeINModel)

    this.configService.deleteAree(this.deleteAreeINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Area eliminata con successo"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }

  editAree(indexRow, rowSelected) {
    this.spinner = true
    this.msgs = false
    this.message = ''

    let modifyAreeINModel: ModifyAreeINModel = {
      tabAree: rowSelected
    };
    this.configService.modifyAree(modifyAreeINModel).subscribe((res) => {
      this.spinner = false
      this.loadingIndicator = false;
      this.message = "Modifica avvenuta correttamente"
      this.messageBox('success', this.message)

      this.fetch(data => {
        this.rows = data;
      });
    }, (err: HttpErrorResponse) => {
      console.log(err)
      this.spinner = false
      /*this.msgs = true
      this.message = JSON.stringify(err)*/
      this.messageBox('error', JSON.stringify(err))
    });
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.areeModel.filter(function (d) {
      return d.desArea.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
  }

  openAddAreeModal(content) {
    this.modalService.open(content, {}).result.then((result) => {
      this.areeModalCloseResult = "Modal closed" + result
      if (result == "by: save button") {
        this.addAree()
        this.areeForm.reset();
      } else {
        this.areeForm.reset();
      }
    }).catch((res) => { });
  }

  openDeleteAreeModal(content, indexRow, rowSelected) {
    this.modalService.open(content, {}).result.then((result) => {
      this.areeModalCloseResult = "Modal closed" + result
      if (result == "by: delete button") {
        this.deleteAree(rowSelected, indexRow);
      }
    }).catch((res) => { });
  }

  openEditAreeModal(content, rowSelected, indexRow) {
    this.callBackground();
    this.areeEdit = rowSelected
    this.modalService.open(content, {}).result.then((result) => {
      this.areeModalCloseResult = "Modal closed" + result
      if (result == "by: edit button") {
        this.checkEdit = false
        this.editAree(indexRow, this.areeEdit)
      }
    }).catch((res) => { });
  }

  callBackground() {
    this.checkEdit = true
    this.configService.loadListAree().subscribe((res) => {
      if (this.checkEdit) {
        this.areeModel = res.listTabAree;
        this.rows = res.listTabAree;
      }
    }, (err: HttpErrorResponse) => {
      this.messageBox('error', JSON.stringify(err))
    });
  }

  messageBox(operation, message) {
    if (operation == "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    if (operation == "error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }
  }
}
