export class ViwProgettoSchedaLayModel {
  desLayer: string
  flgConvalidato: boolean
  flgEsistente: boolean
  icon: string
  iconText: string
  idLayer: number
  idProgetto: number
  idSchedaProgetto: number
  uuid: string
  valGeoLay: string

}