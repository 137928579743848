import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeleteLayerIN } from '../models/delete-layer-in.model';
import { DeleteLayerOUT } from '../models/delete-layer-out.model';
import { LoadLayersIN } from '../models/load-layers-in.model';
import { LoadLayersOUT } from '../models/load-layers-out.model';
import { UploadFileShpINModel } from '../models/upload-file-shp-in-model.model';
import { UploadFileShpOUTModel } from '../models/upload-file-shp-outmodel.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GeoServerService {

  constructor(
    private apiService: ApiService
  ) { }


  // geoserver-api
  uploadFileShp(model: UploadFileShpINModel): Observable<UploadFileShpOUTModel> {
    const formData = new FormData();
    formData.append('codice', model.codice);
    formData.append('area', model.area + "");
    formData.append('idCentrale', model.idCentrale + "");
    formData.append('storage', model.storage + "");
    formData.append('file', model.file);
    return this.apiService.postUpdateFile('geoserver-api/api/v1/uploadFileShp', formData);
  }

  loadLayers(model: LoadLayersIN): Observable<LoadLayersOUT> {
    return this.apiService.post('geoserver-api/api/v1/loadLayers', model);
  }

  deleteLayer(model: DeleteLayerIN): Observable<DeleteLayerOUT> {
    return this.apiService.post('geoserver-api/api/v1/deleteLayer', model);
  }
}
