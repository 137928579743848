import { Injectable } from '@angular/core';
import { Text, Fill, Stroke, Circle, Style, Icon } from 'ol/style';
import { OSM, TileWMS, Vector } from 'ol/source';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import { HttpClient } from '@angular/common/http';
import { Snap, Draw, Modify, Select, Interaction, DoubleClickZoom, Extent } from 'ol/interaction';
import { Observable, Subject, of } from 'rxjs';
import { InsertAreaArmadioINModel } from '../models/insertAreaArmadioIN.model';
import { InsertAreaArmadioOUTModel } from '../models/insertAreaArmadioOUT.model ';
import { ApiService } from './api.service';
import { LoadAreaArmadioINModel } from '../models/loadAreaArmadioIN.model';
import { LoadAreaArmadioOUTModel } from '../models/loadAreaArmadioOUT.model';
import { LoadLayerINModel } from '../models/loadLayerIN.model';
import { LoadLayerOUTModel } from '../models/loadLayerOUT.model';
import { Collection, Feature, View, Map } from 'ol';
import { LoadAttributiLayerINModel } from '../models/loadAttributiLayerIN.model';
import { LoadAttributiLayerOUTModel } from '../models/loadAttributiLayerOUT.model';
import { InsertLayerINModel } from '../models/insertLayerIN.model';
import { InsertLayerOUTModel } from '../models/insertLayerOUT.model';
import { click } from 'ol/events/condition';
import { DeleteLayerINModel } from '../models/deleteLayerIN.model';
import { DeleteLayerOUTModel } from '../models/deleteLayerOUT.model';
import { LoadSchedaProgettoLayerINModel } from '../models/loadSchedaProgettoLayerIN.model';
import { LoadSchedaProgettoLayerOUTModel } from '../models/loadSchedaProgettoLayerOUT.model';
import { ModifyAttributeLayerINModel } from '../models/modifyAttributeLayerIN.model';
import { ModifyAttributeLayerOUTModel } from '../models/modifyAttributeLayerOUT.model';
import { ModifyFeatureSchedaPrjLayINModel } from '../models/modifyFeatureSchedaPrjLayIN.model';
import { ModifyFeatureSchedaPrjLayOUTModel } from '../models/modifyFeatureSchedaPrjLayOUT.model';
import MultiPoint from 'ol/geom/MultiPoint';
import { MapStyleService } from './mapStyle.service';
import { getDistance, getLength } from 'ol/sphere';
import { toLonLat, transform } from 'ol/proj';
import { Layer, Tile } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { ViwLayerModel } from '../models/viwLayer.model';
import Geometry from 'ol/geom/Geometry';
import BaseLayer from 'ol/layer/Base';
import { unByKey } from 'ol/Observable';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { Coordinate } from 'ol/coordinate';
import LineString from 'ol/geom/LineString';
import { equals } from 'ol/coordinate';
import { EditLineParams, EditPointParams, GeoJson, GeometryParams, EditCavo, InsertGeometryParams, ModifyGeometryParams, ShortestPath, Cavo } from '../models/webgis.model';
import TileLayer from 'ol/layer/Tile';
import Point from 'ol/geom/Point';
import { buffer, getBottomLeft, getHeight, getTopLeft, getTopRight, getWidth } from 'ol/extent';

@Injectable({
  providedIn: 'root'
})
export class MapService {


  map: Map;
  draw: any;
  modify: any;
  select: any;
  dialog: any;
  selectSingleClick: any;


  //Currently drawn feature.
  sketch: any
  //The measure tooltip element.
  measureTooltipElement: any
  //Overlay to show the measurement
  measureTooltip: any

  snap: any;

  graphicValidationEvent = new Subject()
  convalidaEvent = new Subject()

  constructor(private httpClient: HttpClient, private apiService: ApiService, private mapStyleService: MapStyleService) { }

  /*************************** SERVIZI BE ***************************/
  insertAreaArmadio(model: InsertAreaArmadioINModel): Observable<GeoJson> {
    return this.apiService.post('project/api/v1/insertAreaArmadio', model);
  }

  loadAreaArmadio(model: LoadAreaArmadioINModel): Observable<GeoJson> {
    return this.apiService.post('project/api/v1/loadAreaArmadio', model);
  }

  loadLayers(): Observable<LoadLayerOUTModel> {
    let model: LoadLayerINModel;
    let body: Object = {};
    return this.apiService.post('project/api/layer/v1/loadLayers', body);
  }

  // loadAttributiLayer(model: LoadAttributiLayerINModel): Observable<LoadAttributiLayerOUTModel> {
  //   return this.apiService.post('project/api/layer/v1/loadAttributiLayer', model);
  // }

  // insertSchedaProgettoLayer(model: InsertLayerINModel): Observable<InsertLayerOUTModel> {
  //   return this.apiService.post('project/api/layer/v1/insertSchedaProgettoLayer', model);
  // }

  // deleteLayer(model: DeleteLayerINModel): Observable<DeleteLayerOUTModel> {
  //   return this.apiService.post('project/api/layer/v1/deleteLayer', model);
  // }

  // loadSchedaProgettoLayer(model: LoadSchedaProgettoLayerINModel): Observable<LoadSchedaProgettoLayerOUTModel> {
  //   return this.apiService.post('project/api/layer/v1/loadSchedaProgettoLayer', model);
  // }

  modifyFeatureAttribute(model: ModifyAttributeLayerINModel): Observable<ModifyAttributeLayerOUTModel> {
    return this.apiService.post('project/api/layer/v1/modifyAttributeLayer', model);
  }

  getAttributiFeature(model: GeometryParams): Observable<LoadAttributiLayerOUTModel> {
    return this.apiService.post('project/api/layer/v1/loadAttributiLayer', model);
  }


  // splitFeature(model: GeometryParams): Observable<any> {
  //   return this.apiService.post('project/api/layer/v1/splitFeature', model);
  // }

  // connectFeature(model: GeometryParams): Observable<any> {
  //   return this.apiService.post('project/api/layer/v1/connectFeature', model);
  // }



  // Nuovo Insert-Edit

  editFeatureGeometryService(operationType: string, model: GeometryParams, modelList?: GeometryParams[]): Observable<any> {
    switch (operationType) {
      case "INSERT_INFRASTRUTTURA":
        return this.insertLine(model as EditLineParams)
      case "EDIT_INFRASTRUTTURA":
        return this.editLine(model as EditLineParams)
      case "INSERT_SPLIT_POINT":
        return this.insertPoint(model as EditPointParams)
      case "EDIT_SPLIT_POINT":
        return this.editPoint(model as EditPointParams)
      case "EDIT":
        return this.updateGeometryToFeature(model as ModifyGeometryParams)
      case "INSERT":
        return this.insertGeometryFeature(model as InsertGeometryParams)
      case "EDITMULTILINE_INFRASTRUTTURE":
        return this.editMultiLine(modelList as EditLineParams[])
    }
  }

  insertGeometryFeature(model: InsertGeometryParams): Observable<any> {
    return this.apiService.post('project/api/layer/v1/insertGeometryToLayer', model);
  }

  updateGeometryToFeature(model: ModifyGeometryParams): Observable<any> {
    return this.apiService.post('project/api/layer/v1/modifyGeometryToLayer', model);
  }

  getLayerById(model: GeometryParams): Observable<GeoJson> {
    return this.apiService.post('project/api/layer/v1/getLayerById', model);
  }

  deleteFeature(model: DeleteLayerINModel): Observable<DeleteLayerOUTModel> {
    return this.apiService.post('project/api/layer/v1/deleteFeature', model);
  }

  insertPoint(params: EditPointParams): Observable<any> {
    return this.apiService.post('project/api/layer/v1/insertPoint', params);
  }

  editPoint(params: EditPointParams): Observable<any> {
    return this.apiService.post('project/api/layer/v1/editPoint', params);
  }

  insertLine(params: EditLineParams): Observable<any> {
    return this.apiService.post('project/api/layer/v1/insertLine', params);
  }

  editLine(params: EditLineParams): Observable<any> {
    return this.apiService.post('project/api/layer/v1/editLine', params);
  }

  editMultiLine(params: EditLineParams[]): Observable<any> {
    return this.apiService.post('project/api/layer/v1/editMultiLine', params);
  }

  shortestPath(params: ShortestPath): Observable<any> {
    return this.apiService.post('routing/api/routing/v1/shortestPath', params);
  }

  insertCavo(params: EditCavo): Observable<any> {
    return this.apiService.post('project/api/cavi/v1/insertCavo', params);
  }

  loadCavi(params): Observable<any> {
    return this.apiService.post('project/api/cavi/v1/loadCavi', params);
  }

  graphicValidation(params: any): Observable<any> {
    return this.apiService.post('routing/api/routing/v1/graphicValidation', params);
  }

  projectValidation(params: any): Observable<any> {
    return this.apiService.post('project/api/layer/v1/projectValidation', params);
  }

  convalidaVisiva(params: any) {
    let ret = {
      spinner: true,
      observable: this.graphicValidation(params)
    }
    return this.graphicValidationEvent.next(ret)
  }

  convalidaProgetto(params: any) {
    let ret = {
      spinner: true,
      observable: this.projectValidation(params)
    }
    return this.convalidaEvent.next(ret)
  }

  /*************************** SERVIZI MAP ***************************/
  createVectorSource(geoJSON: GeoJson, dataProjection: string = 'EPSG:3857', featureProjection: string = 'EPSG:3857') {
    if (geoJSON == null || geoJSON.features == null) return new VectorSource();
    return new VectorSource({
      features: (new GeoJSON()).readFeatures(geoJSON, {
        dataProjection: dataProjection,
        featureProjection: featureProjection
      })
    });
  }

  createVectorLayer(vectorSource: any, style: any) {
    return new VectorLayer({
      source: vectorSource,
      zIndex: 1,
      style: style
    });
  }

  updateSize() {
    this.map.updateSize();
  }

  getLayerByName(layerName: string) {
    var layerList = this.map.getLayers().getArray()
    for (let item of layerList) {
      if (item.get('name') == layerName) {
        return item
      }
    }
  }

  //Format length output.
  formatLength(feature) {
    var line = feature.getGeometry()
    length = getLength(line);
    let output;
    output = Math.round(length * 100) / 100;
    return output;
  };

  //Format lat e long output.
  formatCoodinate(feature) {
    var point = feature.getGeometry()
    let output = toLonLat(point.getCoordinates(), 'EPSG:3857');
    return output;
  };

  setOpacityToLayer(layer: Layer<any>, opacity: number) {
    layer.setOpacity(opacity)
  }



  //  ################ Refactoring

  initMap(target: string) {
    this.map = new Map({
      target: target,
      layers: [
        new Tile({
          source: new OSM()
        })
      ],
      view: new View({
        projection: "EPSG:3857",
        center: [1368116.54, 5092320.80],
        zoom: 5
      })
    });

    return this.map;
  }

  createTileSource(url: string, layers: string, tiled: boolean = true) {
    let tileSource = new TileWMS({
      url: url + '/wms',
      params: { 'LAYERS': layers, 'TILED': tiled },
      serverType: 'geoserver'
    })
    return tileSource;
  }

  createTileLayer(source: TileWMS, extent?: number[]): TileLayer<any> {
    let tileLayer = new TileLayer({
      extent: extent ? extent : undefined,
      source: source
    })
    return tileLayer;
  }

  addTileLayerToMap(name: string, url: string, layers: string, tiled: boolean = true, extent?: number[]) {
    let tileSource = this.createTileSource(url, layers, tiled)
    let tileLayer = this.createTileLayer(tileSource, extent)
    tileLayer.set('name', name)
    this.map.addLayer(tileLayer);
    return tileLayer;
  }


  addVectorLayerToMap(layer: any, name: string, style: any): VectorLayer<any> {
    let vectorSource = this.createVectorSource(layer);
    let vectorLayer = this.createVectorLayer(vectorSource, style);
    vectorLayer.set('name', name + '_new')
    this.map.addLayer(vectorLayer);
    this.removeLayerByName(name)

    vectorLayer.set('name', name)
    return vectorLayer;
  }

  addWFSGeoJsonFromGeoserverToMap(name: string, urlGeoserver: string, workspace: string, layerName: string, filter?: string) {
    let cql_filter = filter ? '&CQL_FILTER=' + filter : ''
    var vectorSource = new VectorSource({
      format: new GeoJSON({ dataProjection: 'EPSG:3857' }),
      url: function (extent) {
        return urlGeoserver + '/' + workspace + '/ows' +
          '?service=WFS' +
          '&version=2.0.0' +
          '&request=GetFeature' +
          '&typeName=' + workspace + ':' + layerName +
          '&maxFeatures=1' +
          '&outputFormat=application%2Fjson' +
          '&srsName=EPSG:3857' +
          cql_filter
          ;
      },
    });

    var vector = new VectorLayer({
      source: vectorSource,
      // style: new Style({
      //   stroke: new Stroke({
      //     color: 'rgba(215, 29, 29, 1.0)',
      //     width: 4
      //   })
      // })
    });

    vector.set('name', name)

    this.map.addLayer(vector);

    return vector;
  }

  zoomToLayer(layer: VectorLayer<any>, maxZoom: number = null) {
    if (this.map) {
      this.map.updateSize();
      if (layer && layer.getSource())
        if (maxZoom) this.map.getView().fit(layer.getSource().getExtent(), { maxZoom: maxZoom });
        else this.map.getView().fit(layer.getSource().getExtent());
    }
  }

  zoomToFeature(feature: Feature<SimpleGeometry>) {
    if (this.map) {
      this.map.updateSize();
      this.map.getView().fit(buffer(feature.getGeometry().getExtent(), 7));
    }
  }

  addDrawInteractions(layer: VectorLayer<any>, type: string): Draw {
    let source = layer.getSource();
    let draw = new Draw({
      source: source,
      type: type,
    });
    this.map.addInteraction(draw);
    return draw;
  }

  addModifyInteractions(layer: VectorLayer<any>, features?: Collection<Feature<SimpleGeometry>>): Modify {
    let source = layer.getSource();
    let modify = new Modify({
      source: source,
      features: features
    });
    this.map.addInteraction(modify);
    return modify;
  }

  addSnapInteractions(layer: VectorLayer<any>): Snap {
    let source = layer.getSource();
    let snap = new Snap({ source: source });
    this.map.addInteraction(snap);
    return snap;
  }

  addClickSelectInteractions(layer: VectorLayer<any>): Select {
    let selectSingleClick = new Select({
      condition: click,
      layers: layerSelected => { return layerSelected == layer },
    });
    this.map.addInteraction(selectSingleClick);
    return selectSingleClick
  }

  addClickSelectInteractionMultilayer(layers: string[]): Select {
    let selectSingleClick = new Select({
      condition: click,
      layers: layerSelected => { return layers.indexOf(layerSelected.get('name')) > -1 },
    });
    this.map.addInteraction(selectSingleClick);
    return selectSingleClick
  }

  addSnapInteractionsToLayers(layers: BaseLayer[]) {
    let features: Array<Feature<Geometry>> = new Array();
    layers.forEach((layer) => {
      if (layer instanceof VectorLayer && layer.getSource()) {
        features = features.concat(layer.getSource().getFeatures());
      }
    });

    let snap = new Snap({
      features: new Collection(features)
    });
    this.map.addInteraction(snap);
    return snap;
  }

  removeInteractions(interaction: Interaction): any {
    let rem_interaction = this.map.removeInteraction(interaction)
    if (rem_interaction)
      rem_interaction.setActive(false);
    return rem_interaction
  }

  removeDoubleClickInteracion() {
    this.map.getInteractions().forEach(function (interaction) {
      if (interaction instanceof DoubleClickZoom)
        interaction.setActive(false);
    });
  }

  removeLayer(layer: VectorLayer<any>) {
    return this.map.removeLayer(layer)
  }

  removeLayerByName(layerName: string) {
    let layer = this.getLayerByName(layerName)
    if (layer)
      this.map.removeLayer(layer)
  }


  pointerMoveKey = null;
  hover = null;
  addHighlightOnHover(layer: VectorLayer<any>, idLayerInfr: number) {
    let _this = this;
    this.pointerMoveKey = this.map.on('pointermove', function (e) {
      if (_this.hover !== null) {
        _this.hover.setStyle(undefined);
        _this.hover = null;
      }
      let hit = _this.map.forEachFeatureAtPixel(e.pixel, (feature: Feature<any>) => {
        _this.hover = feature;
        let highlightStyle = _this.mapStyleService.getSelectHighlightStyle(feature, idLayerInfr)
        feature.setStyle(highlightStyle);
        return true;
      }, {
        layerFilter: (layerHover) => { return layerHover == layer }
      });

      _this.map.getTargetElement().style.cursor = hit ? 'pointer' : '';

    });
  }


  removeHighlightOnHover(layer: VectorLayer<any>) {
    if (this.hover)
      this.hover.setStyle(undefined);
    if (this.pointerMoveKey)
      unByKey(this.pointerMoveKey)
    this.map.getTargetElement().style.cursor = '';
  }

  addCursorOnHoverLayers(layerNames: string[]) {
    let _this = this;
    this.pointerMoveKey = this.map.on('pointermove', function (e) {

      let hit = _this.map.forEachFeatureAtPixel(e.pixel, (feature: Feature<any>) => {
        return true;
      }, {
        layerFilter: (layerHover) => { return layerNames.indexOf(layerHover.get('name')) > -1 }
      });

      _this.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });
  }

  removeCursorOnHoverLayers() {
    if (this.pointerMoveKey)
      unByKey(this.pointerMoveKey)
    this.map.getTargetElement().style.cursor = '';
  }


  getFeatureAtPoint(coordinate: Coordinate, featureTypeFilter: string, layerFilter?: string[]): Feature<SimpleGeometry>[] {
    let features: Feature<SimpleGeometry>[] = [];
    let pixel = this.map.getPixelFromCoordinate(coordinate);

    this.map.forEachFeatureAtPixel(pixel,
      (feature: Feature<any>) => {
        if (feature.getGeometry().getType() == featureTypeFilter)
          features = features.concat([feature])
      }
      , {
        layerFilter: layerFilter ? (layer: VectorLayer<any>) => layer.get('name') && layerFilter.indexOf(layer.get('name')) > -1 : undefined,
        hitTolerance: 1
      }
    );

    return features;
  }

  intersectCoordinate(coordinate1: Coordinate, coordinate2: Coordinate) {
    let distance = this.getCoordsDistance(coordinate1, coordinate2)
    console.log("distance->", distance)
    return this.map.getView().getZoom() >= 19 ? distance < 0.3 : distance < 2
  }

  getCoordsDistance(firstPoint: Coordinate, secondPoint: Coordinate, sourceProj = 'EPSG:3857') {
    const projection = 'EPSG:4326';

    let length = 0;
    let c1 = transform(firstPoint, sourceProj, projection);
    let c2 = transform(secondPoint, sourceProj, projection);

    length += getDistance(c1, c2)

    return length;
  }

  splitGeomAtPoint = function (geom: SimpleGeometry, pt: Coordinate, tol: number) {
    var i;
    if (!pt) return [geom];
    if (!tol) tol = 1e-10;
    // Nothing to do
    if (equals(pt, geom.getFirstCoordinate()))
      return [null, geom];

    if (equals(pt, geom.getLastCoordinate()))
      return [geom, null];

    // //Se la distanza del punto piu vicino è maggiore di 1 metro non splitto
    // let coordinates = geom.getClosestPoint(pt)
    // let distance = this.getCoordsDistance(coordinates, pt)
    // console.log("split distance->", distance)
    // if(distance>1) return [];

    // Get
    var c0 = geom.getCoordinates();
    var ci = [c0[0]];
    var c = [];
    for (i = 0; i < c0.length - 1; i++) {
      // Filter equal points
      if (equals(c0[i], c0[i + 1])) continue;
      // Extremity found
      if (equals(pt, c0[i + 1])) {
        ci.push(c0[i + 1]);
        c.push(new LineString(ci));
        ci = [];
      }
      // Test alignement
      else if (!equals(pt, c0[i])) {
        var d1, d2, split = false;
        if (c0[i][0] == c0[i + 1][0]) {
          d1 = (c0[i][1] - pt[1]) / (c0[i][1] - c0[i + 1][1]);
          split = (c0[i][0] == pt[0]) && (0 < d1 && d1 <= 1)
        } else if (c0[i][1] == c0[i + 1][1]) {
          d1 = (c0[i][0] - pt[0]) / (c0[i][0] - c0[i + 1][0]);
          split = (c0[i][1] == pt[1]) && (0 < d1 && d1 <= 1)
        } else {
          d1 = (c0[i][0] - pt[0]) / (c0[i][0] - c0[i + 1][0]);
          d2 = (c0[i][1] - pt[1]) / (c0[i][1] - c0[i + 1][1]);
          split = (Math.abs(d1 - d2) <= tol && 0 < d1 && d1 <= 1)
        }
        // pt is inside the segment > split
        if (split) {
          ci.push(pt);
          c.push(new LineString(ci));
          ci = [pt];
        }
      }
      ci.push(c0[i + 1]);
    }
    if (ci.length > 1) c.push(new LineString(ci));
    if (c.length) return c;
    else return [geom];
  }

  layerHasFeatures(layerName) {
    let layer: VectorLayer<any> = this.getLayerByName(layerName) as VectorLayer<any>
    return layer?.getSource()?.getFeatures()?.length > 0
  }

  intersectLine(line: LineString, coordinate: Coordinate) {
    let point = new Point(coordinate);
    //return line.intersectsExtent(point.getExtent())

    let closestPoint = line.getClosestPoint(point.getExtent())
    let distance = this.getCoordsDistance(coordinate, closestPoint)
    console.log("distance->", distance)
    return this.map.getView().getZoom() >= 19 ? distance < 0.3 : distance < 2
  }

  //this.mapService.zoomToFeatureByProperty("objectId","130238");
  zoomToFeatureByProperty(propertyName: string, propertyValue: string, layerName?: string) {
    let feature = null;
    if (layerName) {
      const layer: VectorLayer<any> = this.getLayerByName(layerName) as VectorLayer<any>
      feature = this.getFeatureByProperty(layer, propertyName, propertyValue)
    } else {
      feature = this.getFeatureByPropertyAllLayers(propertyName, propertyValue)
    }
    feature.set("searchMarker", true)
    this.zoomToFeature(feature)
  }

  getFeatureByPropertyAllLayers(propertyName: string, propertyValue: string, layerName?: string) {
    let featureToReturn = null;
    const layers = this.map.getLayers();
    layers.getArray().map(layer => {
      if (layer instanceof VectorLayer) {
        let feat = this.getFeatureByProperty(layer, propertyName, propertyValue)
        if (feat) featureToReturn = feat
      }
    });
    return featureToReturn;
  }

  getFeatureByProperty(layer: VectorLayer<any>, propertyName: string, propertyValue: string) {
    let featureToReturn = null;
    if (layer?.getSource()) {
      const features = layer?.getSource().getFeatures();
      features.map(feature => {
        if (feature.get(propertyName) == propertyValue) {
          featureToReturn = feature
        }
      })
    }
    return featureToReturn;
  }

  getScalaPlanimetria(extent: number[]) {
    //Calcolo altezza e larghezza sezione mappa Japser in centimetri
    const pxMapHeight=804                                     // Altezza in px impostata per l'area di mappa su mapfish
    const pxMapWidth=1018                                     // Larghezza in px impostata per l'area di mappa su mapfish
    const dpi=72                                              // SPI utilizzato da Jasper (Mapfish) -> 72 px = 1 Inch
    const inchCMCoeff=2.54                                    // Fattore di conversione da inches a cm

    const mapHeight= pxMapHeight / dpi * inchCMCoeff          // Altezza in CM dell'are di mappa su pdf     
    const mapWidth= pxMapWidth / dpi * inchCMCoeff            // Larghezza in CM dell'are di mappa su pdf 

    // Calcolo altezza e largezza bbox in centimetri
    const topLeftCoord = getTopLeft(extent)                   // Cordinata in alto a sinistra del bbox
    const bottomLeftCoord = getBottomLeft(extent)             // Cordinata in basso a sinistra del bbox
    const topRightCoord = getTopRight(extent)                 // Cordinata in alto a destra del bbox

    const height = this.getCoordsDistance(topLeftCoord, bottomLeftCoord) * 100    // Distanza in cm tra le due coordinate in altezza
    const width = this.getCoordsDistance(topLeftCoord, topRightCoord) * 100       // Distanza in cm tra le due coordinate in larghezza

    // Metto in scala il rapporto
    const scaleHeight = height / mapHeight                    // Possibile Fattore scala in altezza
    const scaleWidth = width / mapWidth                       // Possibile Fattore scala in larghezza
    if(scaleHeight>scaleWidth)                                // Per la scala utilizza il fattore scala maggiore perchè mapfish adatta la mappa al bbox
      return "1:"+ Math.trunc(scaleHeight)
    else
      return "1:"+ Math.trunc(scaleWidth)
  }
}
